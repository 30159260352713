import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import axios from 'axios'
import '../CSS/checkout.css'
import { ORDER_CONFIRM_SUCCESS } from '../Constants/orderConstants'
import Navbar from '../Components/Navbar'

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const OrderSummaryScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const orderCreate = useSelector((state) => state.orderCreate)
	const { orderResult } = orderCreate

	const orderConfirm = useSelector((state) => state.orderConfirm)
	const { orderStatus } = orderConfirm

	useEffect(() => {
		if (orderStatus) {
			navigate('/thank-you')
		}
	}, [orderStatus, navigate])

	useEffect(() => {
		if (!orderResult) {
			navigate('/cart')
		}
	}, [orderResult, navigate])

	const loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement('script')
			script.src = src
			script.onload = () => {
				resolve(true)
			}
			script.onerror = () => {
				resolve(false)
			}
			document.body.appendChild(script)
		})
	}

	const paymentResultHandler = async (id, details, paymentType) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const result = await axios.post(
			'/api/payment/success',
			{ id, details, paymentType },
			config
		)

		if (result) {
			dispatch({
				type: ORDER_CONFIRM_SUCCESS,
			})
		}
	}

	const AfterpayPaymentResultHandler = async (id, details, paymentType) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			'/api/payment/afterpay-capture',
			{ id, details, paymentType },
			config
		)

		if (data.orderResponse) {
			dispatch({
				type: ORDER_CONFIRM_SUCCESS,
			})
		} else {
			toast.error('Payment Was Declined, Please Try Again', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored',
			})
			getTokken(orderResult.order)
		}
	}

	useEffect(() => {
		loadScript('https://portal.afterpay.com/afterpay.js')
	}, [])

	const getTokken = async () => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			'/api/payment/tokken',
			{ order: orderResult.order },
			config
		)
		if (data) {
			orderResult.tokken.token = data.tokken.token
		}
	}

	const Afterpaypayment = () => {
		window.AfterPay.initialize({
			countryCode: 'AU',
		})

		window.AfterPay.open()

		window.AfterPay.onComplete = function (event) {
			if (event.data.status === 'SUCCESS') {
				AfterpayPaymentResultHandler(
					orderResult.order._id,
					event.data,
					'Afterpay'
				)
			} else if (event.data.status === 'CANCELLED') {
				toast.error('Payment Was Cancelled', {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				})
				getTokken(orderResult.order)
			}
		}
		window.AfterPay.transfer({
			token: orderResult.tokken.token,
		})
	}

	const currencyFormat = (num) => {
		return '$ ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	return (
		<div className=''>
			<Navbar />
			<div className='container'>
				<ToastContainer
					position='top-center'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme='colored'
				/>
				{orderResult && (
					<div className='row   mt-lg-0 mt-0'>
						<div className='col-lg-6 pt-lg-4 summary offset-lg-3 col-12'>
							<div className=' '>
								<p className=' mb-1 ps-2'>DELIVER TO</p>
								<div className='address p-3'>
									<p
										style={{
											fontWeight: '600',
											fontSize: '19px',
										}}
										className='mb-1'
									>
										{orderResult.order.name}
									</p>
									<p>
										{orderResult.order.address.addressLine1}{' '}
										{orderResult.order.address.addressLine2} ,{' '}
										{orderResult.order.address.city},{' '}
										{orderResult.order.address.state},{' '}
										{orderResult.order.address.pincode}
									</p>
									<p className='mb-0'>{orderResult.order.phoneNumber}</p>
								</div>
								<hr />
								<p className=' mb-1 mt-2 mb-3 ps-2'>ITEMS</p>
								<div className='p-lg-4 address p-2 pb-0 pt-0 pb-1'>
									{orderResult.order.items.map((item) => (
										<>
											<div className='row align-items-center'>
												<div className='col-6'>
													<img
														src={item.cartImages[item.color]}
														alt=''
														className='img-fluid'
													/>
												</div>
												<div className='col-6'>
													<h6
														className=''
														style={{
															fontWeight: '600',
														}}
													>
														{item.name}
													</h6>
													<p
														style={{
															fontSize: '0.8em',
														}}
														className='d-flex justify-content-between mb-1'
													>
														<span> Color</span>
														<span>{item.models[item.color].colorName}</span>
													</p>

													{item.variant && (
														<p
															style={{
																fontSize: '0.8em',
															}}
															className='d-flex justify-content-between mb-1'
														>
															<span>
																{' '}
																{item.variant[item.variantType].variantType}
															</span>
															<span>
																{item.variant[item.variantType].variantName}
															</span>
														</p>
													)}

													<p
														style={{
															fontSize: '0.8em',
														}}
														className='d-flex justify-content-between mb-1'
													>
														<span>Quantity</span>
														<span>{item.qty}</span>
													</p>

													<p
														style={{
															fontSize: '0.8em',
														}}
														className='d-flex justify-content-between'
													>
														<span>Total Price</span>
														<span>
															{currencyFormat(item.qty * item.accPrice)}
														</span>
													</p>
												</div>
											</div>
											<hr />
										</>
									))}
								</div>
							</div>
							<hr />
							<div className=' mt-0'>
								<div className='mx-auto p-lg-4 pt-lg-0 p-2  '>
									<p className='fw-light mb-2 mt-1  d-flex justify-content-between '>
										{' '}
										<span className=''>TOTAL ITEM </span>{' '}
										<span className='fw-bold '>
											{orderResult.order.items.reduce(
												(acc, item) => acc + item.qty,
												0
											)}
										</span>
									</p>
									<p className='fw-light mb-2 mt-4  d-flex justify-content-between '>
										{' '}
										<span className=''>SUB TOTAL </span>{' '}
										<span className='fw-bold '>
											{currencyFormat(
												orderResult.order.items.reduce(
													(acc, item) => acc + item.qty * item.accPrice,
													0
												)
											)}
										</span>
									</p>

									{orderResult.order.promoCode && (
										<p className='fw-light mb-2 mt-4  d-flex justify-content-between '>
											{' '}
											<span className=''>PROMOCODE </span>{' '}
											<span className='fw-bold '>
												{orderResult.order.promoCode &&
													orderResult.order.promoCode.codeValue}
											</span>
										</p>
									)}

									<p className='fw-light mb-2 mt-4  d-flex justify-content-between '>
										{' '}
										<span className=''>DELIVERY </span>{' '}
										<span className='fw-bold '>{currencyFormat(49)}</span>
									</p>
									<p
										className='fw-light mb-2 mt-4 pb-4  d-flex justify-content-between '
										style={{
											borderBottom: '1px dashed grey',
										}}
									>
										{' '}
										<span className=''>TOTAL </span>{' '}
										<span className='fw-bold '>
											{currencyFormat(orderResult.order.amount)}
										</span>
									</p>
									<div className='col-12 mt-3 mb-3 '>
										{orderResult.tokken && (
											<div className='w-100 d-flex mb-3'>
												<button
													onClick={Afterpaypayment}
													style={{
														width: '100%',
														border: '0px',
														borderRadius: '3px',
														background: '#ACF4DD',
														boxShadow: '-2px 2px #d4d0cf',
														overflow: 'hidden',
														justifyItems: 'center',
													}}
												>
													<img
														src='https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/afterpay-logo-png-black-transparent.png'
														className='img-fluid w-25 p-3'
														alt='Afterpay-logo'
													/>
												</button>
											</div>
										)}

										<PayPalScriptProvider
											options={{
												'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
												currency: 'AUD',
												intent: 'capture',
											}}
										>
											<PayPalButtons
												disabled={false}
												forceReRender={[orderResult.order.amount, 'AUD']}
												fundingSource={undefined}
												createOrder={(data, actions) => {
													return actions.order
														.create({
															purchase_units: [
																{
																	description: '',
																	amount: {
																		currency_code: 'AUD',
																		value: orderResult.order.amount,
																	},
																},
															],
														})
														.then((orderId) => {
															// Your code here after create the order
															return orderId
														})
												}}
												onApprove={async (data, actions) => {
													// return actions.order.capture().then(function (details) {
													// 	paymentResultHandler(orderResult._id,details)
													// })
													const order = await actions.order.capture()
													paymentResultHandler(
														orderResult.order._id,
														order,
														'Paypal'
													)
												}}
												onError={(err) => {
													console.error(err)
												}}
											/>
										</PayPalScriptProvider>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default OrderSummaryScreen
