import React, { useState, useEffect } from "react"
import Navbar from "../Components/Navbar"
import { contactUsCreate } from "../Actions/formActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import Footer from "../Components/Footer"
import WhatsAppBot from "../Components/WhatsAppBot"
import "../CSS/contact.css"
import { useNavigate } from "react-router-dom"
import Meta from "../Components/Meta"
const ContactUsScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] =
		useState("")
	const [message, setMessage] = useState("")

	const contactUs = useSelector(
		(state) => state.contactUs,
	)
	const { contact } = contactUs

	const submitForm = (e) => {
		e.preventDefault()
		dispatch(
			contactUsCreate(
				name,
				email,
				phoneNumber,
				message,
			),
		)
	}

	useEffect(() => {
		if (contact) {
			navigate("/submitted")
		}
	}, [contact])

	return (
		<>
			{" "}
			<Meta
				title={" EMotorad | Contact Us"}
				description={
					"EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
				}
			/>
			<Navbar />
			<div
				className='container-fluid  m-0 pb-0 '
				style={{
					background: "black",
				}}>
				<div className='row p-0 align-items-center'>
					<div className='col-lg-5 p-0'>
						<img
							src='https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/AUS-Reached-Us+(1).jpg'
							className='img-fluid'
							alt=''
						/>
					</div>
					<div className='col-lg-7 contactus-main'>
						<div className=' mx-auto'>
							<h1>Get in Touch</h1>
							<p className='subline'>
								We’d love to hear from you
							</p>
							<form
								action=''
								onSubmit={submitForm}>
								<div className='mt-4'>
									<div class='mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Name
										</label>
										<input
											type='text'
											class='form-control '
											value={name}
											required
											onChange={(e) =>
												setName(e.target.value)
											}
											style={{
												textTransform:
													" capitalize",
												padding: "0.7em",
											}}
											id='exampleFormControlInput1'
											placeholder=''
										/>
									</div>
									<div class='mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Email
										</label>
										<input
											type='email'
											class='form-control'
											style={{ padding: "0.7em" }}
											id='exampleFormControlInput1'
											placeholder=''
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
										/>
									</div>
									<div class='mb-lg-5 mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Phone Number
										</label>
										<input
											type='number'
											class='form-control'
											style={{ padding: "0.7em" }}
											id='exampleFormControlInput1'
											placeholder=''
											value={phoneNumber}
											required
											onChange={(e) =>
												setPhoneNumber(
													e.target.value.slice(
														0,
														11,
													),
												)
											}
										/>
									</div>
									<div class='mb-3'>
										<label
											for='exampleFormControlTextarea1'
											class='form-label text-light '>
											Message
										</label>
										<textarea
											value={message}
											onChange={(e) =>
												setMessage(e.target.value)
											}
											required
											class='form-control'
											id='exampleFormControlTextarea1'
											rows='4'></textarea>
									</div>

									<button
										type='submit'
										className='btn btn-primary ms-auto d-block fw-bold mt-lg-4 '>
										Send Message
									</button>
								</div>{" "}
							</form>
						</div>
					</div>
				</div>
			</div>
			<iframe
				src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.4216491246866!2d153.18256911567192!3d-27.673360032021783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91416ae6729771%3A0xd4f35ad01227a1ca!2sAusstech%20Holdings%20Pty%20Ltd!5e0!3m2!1sen!2sin!4v1679673276243!5m2!1sen!2sin'
				style={{ width: "100%", height: "40vh" }}
				allowfullscreen=''
				loading='lazy'
				referrerpolicy='no-referrer-when-downgrade'></iframe>
			<div className='container-fluid contact-us-countries '>
				<h4 className='text-center fw-bold mb-4'>
					MEET US
				</h4>
				<div
					className='row justify-content-around p-lg-0 '
					style={{}}>
					{" "}
					<div className=' mt-5 country col-lg-2 col-12  c-3'>
						<p>Australia</p>
						<span>
							Ausstech Holdings Pty Ltd Suite
							246/4,16-18 Beenleigh-Redland Bay
							Road, Loganholme QLD 4129, Australia
						</span>
					</div>
					<div className=' mt-5 country col-lg-2 col-12   c-1'>
						<p>India</p>
						<span>
							At post Jambe, taluka Mulshi, 169/2
							Sangawade, Road, Pune, Maharashtra
							411033
						</span>
					</div>
					<div className='mt-5 country col-lg-2 col-12  c-2'>
						<p>Spain</p>
						<span>
							Lexidy Law Boutique, Avinguda
							Diagonal, 442, 3° 1a, Barcelona,
							08037
						</span>
					</div>
					<div className=' mt-5 country col-lg-2 col-12 c-3'>
						<p>Japan</p>
						<span>
							Navi Shibuya V 3F, 5-5 Maruyama-cho,
							Shibuya-ku, Tokyo, Japan
						</span>
					</div>
					<div className=' mt-5 country col-lg-2 col-12  c-4'>
						<p>UAE</p>
						<span>
							Business Center, Al Shmookh
							Building, UAO Free Trade Zone, UmmAI
							Quwain, UAE
						</span>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default ContactUsScreen
