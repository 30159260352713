import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { getloginUserInfo } from '../Actions/userAction'
import { useNavigate } from 'react-router-dom'

import { userOrderCreate } from '../Actions/orderActions'
import '../CSS/shipping.css'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

const ShippingScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const addressFromLocalStorage =
		localStorage.getItem('address') &&
		JSON.parse(localStorage.getItem('address'))

	const userLogin = useSelector((state) => state.userLogin)
	const { loginInfo } = userLogin

	const getUserInfo = useSelector((state) => state.getUserInfo)
	const { userInfo: userDetails } = getUserInfo

	const confirmCartItems = useSelector((state) => state.confirmCartItems)
	const { confirmedItems } = confirmCartItems

	const order = useSelector((state) => state.orderCreate)
	const { orderResult } = order

	useEffect(() => {
		if (!confirmedItems) {
			navigate('/cart')
		}
		// console.log(confirmCartItems)
	}, [])

	useEffect(() => {
		if (loginInfo) {
			dispatch(getloginUserInfo(loginInfo._id))
		}
	}, [loginInfo])

	const saveAddress = (e) => {
		e.preventDefault()

		const amount = confirmedItems.finalPrice

		dispatch(
			userOrderCreate(
				loginInfo && loginInfo._id,
				name,
				email,
				mobileNumber,
				addressLine1,
				addressLine2,
				city,
				state,
				pincode,
				amount,
				confirmedItems.cartItems,
				confirmedItems.promocode && confirmedItems.promocode
			),

			localStorage.setItem(
				'address',
				JSON.stringify({
					state,
					city,
					pincode,
					addressLine1,
				})
			)
		)

		navigate('/ordersummary')
	}
	const currencyFormat = (num) => {
		return '$' + ' ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}
	const [name, setName] = useState(userDetails ? userDetails.name : '')
	const [email, setEmail] = useState(userDetails ? userDetails.email : '')
	const [mobileNumber, setMobileNumber] = useState('')
	const [state, setState] = useState(
		addressFromLocalStorage ? addressFromLocalStorage.state : ''
	)
	const [city, setCity] = useState(
		addressFromLocalStorage ? addressFromLocalStorage.city : ''
	)
	const [pincode, setPincode] = useState(
		addressFromLocalStorage ? addressFromLocalStorage.pincode : ''
	)
	const [addressLine1, setAddressLine1] = useState(
		addressFromLocalStorage ? addressFromLocalStorage.addressLine1 : ''
	)
	const [addressLine2, setAddressLine2] = useState(
		addressFromLocalStorage ? addressFromLocalStorage.addressLine2 : ''
	)

	return (
		<>
			<Navbar />
			{confirmedItems && (
				<div className='container mt-lg-5'>
					<form onSubmit={saveAddress}>
						<div
							className='row mt-lg-5 p-lg-4 p-1 mt-4 mb-lg-5'
							style={{ minHeight: '80vh' }}
						>
							<div className='col-md-5 offset-lg-1 p-1 '>
								{' '}
								<div
									className='mt-0  d-flex p-lg-4 pt-lg-0  justify-content-between'
									style={{
										flexDirection: 'column',
									}}
								>
									<div className='col-md-12  mb-3 '>
										<div class='form-floating '>
											<input
												type='text'
												class='form-control'
												id='floatingInput'
												placeholder='name@example.com'
												required
												autoComplete='off'
												value={name}
												onChange={(e) => setName(e.target.value)}
											/>
											<label for='floatingInput'>NAME</label>
										</div>
									</div>

									<div className='col-md-12 mb-3'>
										<div class='form-floating '>
											<input
												type='email'
												class='form-control'
												id='floatingInput'
												placeholder='name@example.com'
												value={email}
												autoComplete='off'
												required
												onChange={(e) => setEmail(e.target.value)}
											/>
											<label for='floatingInput'>EMAIL</label>
										</div>
									</div>
									<div className='col-md-12 mb-3 '>
										<div class='form-floating '>
											<input
												type='number'
												class='form-control'
												id='floatingInput'
												value={mobileNumber}
												autoComplete='off'
												onChange={(e) =>
													setMobileNumber(e.target.value.slice(0, 11))
												}
												required
												placeholder='name@example.com'
											/>
											<label for='floatingInput'>PHONE NUMBER</label>
										</div>
									</div>
									<div className='col-md-12 mb-3  '>
										<div class='form-floating '>
											<input
												type='text'
												class='form-control'
												id='floatingInput'
												placeholder='name@example.com'
												autoComplete='off'
												required
												value={addressLine1}
												onChange={(e) => setAddressLine1(e.target.value)}
											/>
											<label for='floatingInput'>Appartment, suite, etc</label>
										</div>
									</div>

									<div className='col-md-12 mb-3  '>
										<div class='form-floating '>
											<input
												type='text'
												class='form-control'
												id='floatingInput'
												placeholder='name@example.com'
												autoComplete='off'
												value={addressLine2}
												required
												onChange={(e) => setAddressLine2(e.target.value)}
											/>
											<label for='floatingInput'>Address</label>
										</div>
									</div>

									<div className='row  shipping-inputs '>
										<div className='col-md-6 mb-3'>
											<div class='form-floating '>
												<input
													type='text'
													class='form-control'
													id='floatingInput'
													autoComplete='off'
													required
													placeholder='name@example.com'
													value={city}
													onChange={(e) => setCity(e.target.value)}
												/>
												<label for='floatingInput'>City</label>
											</div>
										</div>

										<div className='col-md-6 mb-3'>
											<div class='form-floating '>
												<select
													required
													value={state}
													onChange={(e) => setState(e.target.value)}
													class='form-select'
													id='floatingSelect'
													aria-label='Floating label select example'
												>
													<option
														selected
														disabled
													></option>
													<option value='Australian Capital Territory'>
														Australian Capital Territory
													</option>
													<option value='New South Wales'>
														New South Wales
													</option>
													<option value='Northern Territory'>
														Northern Territory
													</option>
													<option value='Queensland'>Queensland</option>
													<option value='South Australia'>
														South Australia
													</option>
													<option value='Tasmania'>Tasmania</option>
													<option value='Victoria'>Victoria</option>
													<option value='Western Australia'>
														Western Australia
													</option>
												</select>{' '}
												<label for='floatingSelect'>State</label>
											</div>
										</div>
									</div>
									<div className='col-md-12 mb-3'>
										<div class='form-floating '>
											<input
												type='text'
												class='form-control'
												id='floatingInput'
												required
												autoComplete='off'
												placeholder='name@example.com'
												value={pincode}
												onChange={(e) => setPincode(e.target.value)}
											/>
											<label for='floatingInput'>POSTCODE</label>
										</div>
									</div>
									<button
										style={{
											background: 'black',
											color: '#fff',
											border: 'none',
											outline: 'none',
										}}
										className='p-3 mt-2 w-100  pe-3'
									>
										CONFIRM ADDRESS
									</button>
								</div>
							</div>
							<div className='col-md-4  d-none d-md-block offset-lg-1  p-1 '>
								<div className='border rounded p-lg-4'>
									<h6 className='fw-bold'>Order Summary</h6>
									<hr />
									<div className='d-flex justify-content-between'>
										<p>Total Items</p>
										<p className='fw-bold'>
											{confirmedItems.cartItems.reduce(
												(acc, item) => acc + item.qty,
												0
											)}
										</p>
									</div>
									<div className='d-flex justify-content-between'>
										<p>Sub Total</p>
										<p className='fw-bold'>
											{currencyFormat(
												confirmedItems.cartItems.reduce(
													(acc, item) => acc + item.qty * item.price,
													0
												)
											)}
										</p>
									</div>

									<div className='d-flex justify-content-between'>
										<p>Total</p>
										<p className='fw-bold'>
											{' '}
											{currencyFormat(confirmedItems.finalPrice)}
										</p>
									</div>

									<hr />
									{confirmedItems &&
										confirmedItems.cartItems.map((item) => (
											<>
												<div className='row align-items-center'>
													<div className='col-6'>
														<img
															src={item.cartImages[item.color]}
															alt=''
															className='img-fluid'
														/>
													</div>
													<div className='col-6'>
														<h5 className='fw-bold'>{item.name}</h5>
														<p
															style={{
																fontSize: '0.8em',
															}}
															className='d-flex justify-content-between mb-1'
														>
															<span> Color</span>
															<span>{item.models[item.color].colorName}</span>
														</p>

														{item.variant && (
															<p
																style={{
																	fontSize: '0.8em',
																}}
																className='d-flex justify-content-between mb-1'
															>
																<span>
																	{item.variant[item.variantType].variantType}
																</span>
																<span>
																	{item.variant[item.variantType].variantName}
																</span>
															</p>
														)}

														<p
															style={{
																fontSize: '0.8em',
															}}
															className='d-flex justify-content-between mb-1'
														>
															<span>Quantity</span>
															<span>{item.qty}</span>
														</p>

														<p
															style={{
																fontSize: '0.8em',
															}}
															className='d-flex justify-content-between'
														>
															<span>Total Price</span>
															<span>
																{currencyFormat(item.qty * item.price)}
															</span>
														</p>
													</div>
												</div>
												<hr />
											</>
										))}
								</div>
							</div>
						</div>
					</form>
				</div>
			)}

			<Footer />
		</>
	)
}

export default ShippingScreen
