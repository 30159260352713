import React, { useState, useEffect } from "react"
import Navbar from "../Components/Navbar"
import { emiCreate } from "../Actions/formActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import Meta from "../Components/Meta"
import Footer from "../Components/Footer"
import { useNavigate } from "react-router-dom"
import "../CSS/emi.css"
const EMIScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] =
		useState("")
	const [bike, setBike] = useState("")

	const EMI = useSelector((state) => state.EMI)
	const { emiStatus } = EMI

	const submitForm = (e) => {
		e.preventDefault()
		dispatch(
			emiCreate(
				name,
				email,
				phoneNumber,
				bike,
				useNavigate,
			),
		)
	}

	useEffect(() => {
		if (emiStatus) {
			navigate("/submitted")
		}
	}, [emiStatus])

	return (
		<>
			<Meta
				title={" EMotorad | EMI"}
				description={
					"EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
				}
			/>
			<Navbar />
			<div className='container'>
				<p className=' font-mon warranty-border-heading  d-lg-none mt-2 pt-lg-2'>
					EMI
				</p>
				<p className='warranty-border-subheading  d-lg-none'>
					{" "}
					Buying an EM e-bike is easier than ever.
					A simple message to our team and you
					will get customised quotes and No Cost
					EMIs.
				</p>
				<div className='mt-5  d-none d-lg-block '>
					<div className='d-flex down-border pb-4 '>
						<div className='col-8'>
							<p className=' font-mon border-heading pt-lg-3'>
								EMI
							</p>
						</div>
						<div className='col-4'>
							<p className='text-end'>
								Buying an EM e-bike is easier than
								ever. A simple message to our team
								and you will get customised quotes
								and No Cost EMIs.
							</p>
						</div>
					</div>
				</div>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi2.jpg'
					style={{ borderRadius: "5px" }}
					className='img-fluid mt-lg-4'
					alt=''
				/>

				<div className='row mt-lg-5 mt-2  '>
					<div className='col-lg-7 col-12'>
						<form onSubmit={submitForm}>
							<div className='emi-form p-lg-4 pt-2 ps-0'>
								<input
									type='text'
									onChange={(e) =>
										setName(e.target.value)
									}
									style={{
										textTransform: " capitalize",
									}}
									placeholder='NAME'
								/>
								<input
									type='email'
									placeholder='EMAIL'
									onChange={(e) =>
										setEmail(e.target.value)
									}
								/>{" "}
								<input
									type='number'
									onChange={(e) =>
										setPhoneNumber(e.target.value)
									}
									style={{
										textTransform: " capitalize",
									}}
									placeholder='PHONE NUMBER'
								/>{" "}
								<select
									name=''
									id=''
									onChange={(e) =>
										setBike(e.target.value)
									}>
									<option
										value=''
										disabled
										selected>
										SELECT BIKE
									</option>
									<option value='EMX'>EMX</option>
									<option value='T-REX+'>
										T-REX+
									</option>

									<option value='Lil E'>
										Lil E
									</option>
									<option value='DOODLE'>
										DOODLE
									</option>
								</select>
								<button
									type='submit'
									className='submit d-block'>
									SUBMIT
								</button>
							</div>
						</form>
					</div>
					<div className='col-lg-5 col-12'></div>
				</div>
				<div className='pt-5 d-lg-none'></div>
				<div className='margin-top-5'>
					<p className=' font-mon warranty-border-heading pt-lg-3 down-border pb-lg-5 pb-3 '>
						EM's EMI PARTNER
					</p>
				</div>

				<div className='row mt-5 d-none d-lg-block align-items-center'>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/EarlyS.png'
						className='col-2 '
						alt=''
					/>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/AXI.png'
						className='col-2 '
						alt=''
					/>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/HDFC-BA.png'
						className='col-2 '
						alt=''
					/>
					<img
						src='	https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/bajaj-f.png'
						className='col-2 '
						alt=''
					/>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/LIQ.png'
						className='col-2 '
						alt=''
					/>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/ZES.png'
						className='col-2 '
						alt=''
					/>
				</div>

				<div className=' d-flex d-lg-none'>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/HDFC-BA.png'
						className='col-6 '
						alt=''
					/>
					<img
						src='	https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/bajaj-f.png'
						className='col-6 '
						alt=''
					/>
				</div>

				<div className=' d-flex d-lg-none'>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/LIQ.png'
						className='col-6 '
						alt=''
					/>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/ZES.png'
						className='col-6 '
						alt=''
					/>
				</div>
				<div className=' d-flex d-lg-none'>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/EarlyS.png'
						className=' col-6'
						alt=''
					/>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/AXI.png'
						className=' col-6 '
						alt=''
					/>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default EMIScreen
