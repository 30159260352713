import React from "react"
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"
import "../index.css"
import Meta from "../Components/Meta"

const CommunityScreen = () => {
	return (
		<>
			<Meta
				title={" EMotorad | Community"}
				description={
					"EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
				}
			/>

			<Navbar />

			<div className='container mt-5'>
				<div className='specs-headings text-center text-lg-start'>
					<h1>JOIN US ON DISCORD</h1>
					<div className='row align-items-center'>
						<div className='col-12 col-lg-6'>
							<img
								src='https://ar-euro.s3.ap-south-1.amazonaws.com/website-europe/images/Team+spirit-bro.png'
								className='img-fluid community-img'
								alt='dwad'
							/>
						</div>
						<div className='col-12 col-lg-6'>
							<a
								href='https://discord.com/invite/u8CQ3R9zzU'
								className='submit d-block mx-auto text-center text-decoration-none'>
								{" "}
								<i class='fa-brands fa-discord me-2'></i> Join us
							</a>
						</div>
					</div>
				</div>
				<div></div>
			</div>
			<div className='  d-none '>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website-europe/images/Team+spirit-bro.png'
					className='img-fluid'
					alt=''
				/>
				<a
					href='https://discord.com/invite/u8CQ3R9zzU'
					style={{ fontSize: "1.1em" }}
					className='btn btn-dark d-block pt-2 pb-2 w-50 mx-auto'
					rel='noreferrer'
					target={"_blank"}>
					<i class='fa-brands fa-discord me-2'></i>Join us
				</a>
				<div className='socials w-100 ps-5 mt-5 mb-3 pe-lg-5 pe-5  mt-3 mt-lg-4 d-flex justify-content-between'>
					<div>
						<a
							href='https://www.facebook.com/emotorad/'
							rel='noreferrer'
							target={"_blank"}>
							<i class='fa-brands fa-facebook-f text-dark '></i>
						</a>
					</div>
					<div>
						<a
							href='https://twitter.com/e_motorad'
							rel='noreferrer'
							target={"_blank"}>
							<i class='fa-brands fa-twitter  text-dark'></i>
						</a>
					</div>
					<div>
						<a
							href='https://www.instagram.com/e_motorad/?hl=en'
							rel='noreferrer'
							target={"_blank"}>
							<i class='fa-brands fa-instagram  text-dark'></i>
						</a>
					</div>
					<div>
						<a
							href='https://www.linkedin.com/company/emotorad'
							rel='noreferrer'
							target={"_blank"}>
							<i class='fa-brands fa-linkedin-in  text-dark'></i>
						</a>
					</div>
					<div>
						<a
							href='https://www.youtube.com/channel/UCBaDv3uoPiBPSVDZJHGCCWg'
							rel='noreferrer'
							target={"_blank"}>
							<i class='fa-brands fa-youtube  text-dark'></i>
						</a>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default CommunityScreen
