import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import {
	CONTACT_US_RESET,
	EMI_RESET,
	INSURANCE_RESET,
	PARTNER_WITH_US_RESET,
	WARRANTY_RESET,
} from "../Constants/formConstants"
import { useDispatch } from "react-redux"
import "../CSS/thankyou.css"

const ThankYouPage = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch({
			type: CONTACT_US_RESET,
		})
		dispatch({ type: EMI_RESET })
		dispatch({ type: PARTNER_WITH_US_RESET })
		dispatch({ type: WARRANTY_RESET })

		dispatch({
			type: INSURANCE_RESET,
		})
	}, [])

	return (
		<div className='container'>
			<div className='thankyou-div'>
				<p className='thankyou font-mon '>
					Thank you for getting in touch with us!
				</p>
				<h4>We'll speak soon!</h4>
				<h6>
					In the meantime, why not find out more
					about us and our e-bikes.
				</h6>
				<Link to={"/"} className='go-back-link'>
					Go Back
				</Link>
			</div>
		</div>
	)
}

export default ThankYouPage
