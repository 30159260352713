import axios from "axios";
import {
  ORDER_BY_ID_FAIL,
  ORDER_BY_ID_REQUEST,
  ORDER_BY_ID_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
} from "../Constants/orderConstants";

export const userOrderCreate =
  (
    userId,
    name,
    email,
    phoneNumber,
    addressLine1,
    addressLine2,
    city,
    state,
    pincode,
    amount,
    items,
    promoCode,
    navigate
  ) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      dispatch({ type: ORDER_CREATE_REQUEST });
      const { data } = await axios.post(
        "/api/order/create",
        {
          userId,
          name,
          email,
          phoneNumber,
          address: {
            addressLine1,
            addressLine2,
            city,
            state,
            pincode,
          },
          amount,
          items,
          promoCode,
        },
        config
      );

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: data,
      });
      if (data) {
        navigate("/ordersummary");
      } else {
        console.log("not created");
      }
    } catch (error) {
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getOrderDetailsById = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { loginInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginInfo.tokken}`,
      },
    };

    dispatch({ type: ORDER_BY_ID_REQUEST });
    const { data } = await axios.get(`/api/user/orders/${id}`, config);

    dispatch({
      type: ORDER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
