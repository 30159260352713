import React, { useEffect, useState } from "react"
import { GraphQLClient } from "graphql-request"
import { gql } from "graphql-request"
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"
import { Link } from "react-router-dom"
const BlogsScreen = () => {
	const weekday = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	]
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]

	const [blogPosts, setBlogPosts] = useState([])
	const hygraph = new GraphQLClient(
		"https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master",
	)

	const QUERY = gql`
		{
			posts(orderBy: date_DESC) {
				id
				title
				tags
				date
				excerpt
				coverImage {
					url
				}
				content {
					html
				}
			}
		}
	`
	useEffect(() => {
		const getBlogs = async () => {
			const { posts } = await hygraph.request(
				QUERY,
			)
			setBlogPosts(posts)
		}
		getBlogs()
	}, [])

	return (
		<>
			<>
				<Navbar />
				<div className='container mt-lg-5 mt-4'>
					<h1 className='pb-3 fw-bold   mt-2 pt-lg-2'>
						BLOGS
					</h1>
					<hr />
					{blogPosts.map((post) => (
						<>
							<div className='row align-items-md-center'>
								<div className='fw-bold col-md-4 col-12'>
									<img
										src={post.coverImage.url}
										alt=''
										style={{
											borderRadius: "5px",
										}}
										className='img-fluid'
									/>
								</div>
								<div className='col-md-8 col-12 p-3'>
									<h4 className='font-mon'>
										{post.title}
									</h4>
									<p>{post.excerpt}</p>
									<span className='pe-2 text-muted'>
										{
											weekday[
												new Date(
													post.date,
												).getDay()
											]
										}
										,{" "}
									</span>

									<span className='pe-2 text-muted'>
										{new Date(
											post.date,
										).getDate()}{" "}
										{""}
										{
											months[
												new Date(
													post.date,
												).getMonth()
											]
										}
									</span>
									<span className='pe-2 text-muted'>
										{new Date(
											post.date,
										).getFullYear()}
									</span>
									<Link
										to={`/blog/${post.id}`}
										className='text-decoration-none'>
										<h6 className='text-primary  mt-4 fw-bold'>
											Read More
										</h6>
									</Link>
								</div>
							</div>
							<hr />
						</>
					))}
				</div>
				<Footer />
			</>
		</>
	)
}

export default BlogsScreen
