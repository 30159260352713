import React, { useEffect } from "react"
import Navbar from "../Components/Navbar"
import WhatsAppBot from "../Components/WhatsAppBot"
import { getAllAccessories } from "../Actions/bikeActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { Link } from "react-router-dom"
import Meta from "../Components/Meta"
import "../CSS/accessories.css"
import Footer from "../Components/Footer"

const AllAccessoriesScreen = () => {
	const currencyFormat = (num) => {
		return (
			"$" +
			" " +
			num
				.toFixed(0)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		)
	}

	const dispatch = useDispatch()

	const allAccessories = useSelector(
		(state) => state.allAccessories,
	)
	const { accessories } = allAccessories

	useEffect(() => {
		dispatch(getAllAccessories())
	}, [])

	return (
		<>
			<WhatsAppBot bottom={"5%"} />
			<Navbar />
			<Meta
				title={" EMotorad | Accessories"}
				description={
					"EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
				}
			/>
			{accessories && (
				<>
					{" "}
					<div className='container-fluid  mt-0 p-lg-4'>
						<div className='row align-items-center'>
							<div className=' col-md-3 mt-2 col-12'>
								<small className='category-subheading-1'>
									Battery
								</small>
								<h4 className='mx-auto category-heading-1'>
									Find your perfect fit
								</h4>{" "}
							</div>

							{accessories &&
								accessories.map(
									(product) =>
										product.subCategory ===
											"Battery" && (
											<div className='   col-md-3 mt-2  p-md-3  col-6'>
												<Link
													to={`/accessories/${product.urlName}`}
													className='text-decoration-none text-dark'>
													<div className=' p-lg-5 p-lg-2 p-2  acc-card'>
														<img
															src={
																product
																	.cartImages[0]
															}
															alt=''
															className='img-fluid   d-block mx-auto '
														/>
													</div>
													<div className='d-md-flex justify-content-between'>
														<p className='acc-name'>
															{product.name}
														</p>
														<div
															className='d-flex'
															style={{
																gap: "1em",
															}}>
															{product.slashPrice >
																0 && (
																<p
																	className='acc-price'
																	style={{
																		textDecoration:
																			"line-through",
																	}}>
																	{currencyFormat(
																		product.slashPrice,
																	)}
																</p>
															)}

															<p className='acc-price'>
																{currencyFormat(
																	product.price,
																)}
															</p>
														</div>
													</div>
												</Link>
											</div>
										),
								)}
						</div>
					</div>
					{/* <div className='container-fluid  ps-lg-3 pe-lg-3'>
						<div
							className=' mt-4'
							style={{
								borderTop: "1px solid lightgrey",
							}}></div>
					</div>
					<div className='container-fluid  mt-0 p-lg-4'>
						<div className='row align-items-center'>
							<div className=' col-md-3 mt-2 col-12'>
								<small className='category-subheading-1'>
									Safety
								</small>
								<h4 className='mx-auto category-heading-1'>
									Come and go well-equipped
								</h4>{" "}
							</div>

							{accessories &&
								accessories.map(
									(product) =>
										product.subCategory ===
											"helmet" && (
											<div className='   col-md-3 mt-2  p-md-3  col-6'>
												<Link
													to={`/accessories/${product.urlName}`}
													className='text-decoration-none text-dark'>
													<div className=' p-lg-5 p-lg-2 p-2  acc-card'>
														<img
															src={
																product.DisplayImg
															}
															alt=''
															className='img-fluid w-75  d-block mx-auto '
														/>
													</div>
													<div className='d-md-flex justify-content-between'>
														<p className='acc-name'>
															{product.name}
														</p>
														<div
															className='d-flex'
															style={{
																gap: "1em",
															}}>
															{product.slashPrice >
																0 && (
																<p
																	className='acc-price'
																	style={{
																		textDecoration:
																			"line-through",
																	}}>
																	{currencyFormat(
																		product.slashPrice,
																	)}
																</p>
															)}

															<p className='acc-price'>
																{currencyFormat(
																	product.price,
																)}
															</p>
														</div>
													</div>
												</Link>
											</div>
										),
								)}
						</div>
					</div>
					<div className='container-fluid ps-lg-3 pe-lg-3'>
						<div
							className=' mt-4'
							style={{
								borderTop: "1px solid lightgrey",
							}}></div>
					</div>
					<div className='container-fluid  mt-0 p-lg-4'>
						<div className='row align-items-center'>
							<div className=' col-md-3 mt-2 col-12'>
								<small className='category-subheading-1'>
									Maintenance
								</small>
								<h4 className='mx-auto category-heading-1'>
									Keep your bike fit
								</h4>{" "}
							</div>

							{accessories &&
								accessories.map(
									(product) =>
										product.subCategory ===
											"pump" && (
											<div className='  col-md-3 mt-2  p-md-3  col-6'>
												{" "}
												<Link
													to={`/accessories/${product.urlName}`}
													className='text-decoration-none text-dark'>
													<div className=' p-lg-5 p-2  acc-card'>
														<img
															src={
																product.DisplayImg
															}
															alt=''
															className='img-fluid w-75 d-block mx-auto '
														/>
													</div>
													<div className='d-md-flex justify-content-between'>
														<p className='acc-name'>
															{product.name}
														</p>
														<div
															className='d-flex'
															style={{
																gap: "1em",
															}}>
															{product.slashPrice >
																0 && (
																<p
																	className='acc-price'
																	style={{
																		textDecoration:
																			"line-through",
																	}}>
																	{currencyFormat(
																		product.slashPrice,
																	)}
																</p>
															)}

															<p className='acc-price'>
																{currencyFormat(
																	product.price,
																)}
															</p>
														</div>
													</div>
												</Link>
											</div>
										),
								)}
						</div>
					</div>
					<div className='container-fluid ps-lg-3 pe-lg-3'>
						<div
							className=' mt-4'
							style={{
								borderTop: "1px solid lightgrey",
							}}></div>
					</div>
					<div className='container-fluid  mt-0 p-lg-4'>
						<div className='row align-items-center'>
							<div className=' col-md-3 mt-2 col-12'>
								<small className='category-subheading-1'>
									Security
								</small>
								<h4 className='mx-auto category-heading-1'>
									Guaranteed Assurance
								</h4>{" "}
							</div>

							{accessories &&
								accessories.map(
									(product) =>
										product.subCategory ===
											"lock" && (
											<div className='  col-md-3 mt-2  p-md-3  col-6'>
												<Link
													to={`/accessories/${product.urlName}`}
													className='text-decoration-none text-dark'>
													<div className=' p-lg-3 p-2  acc-card'>
														<img
															src={
																product.DisplayImg
															}
															alt=''
															className='img-fluid w-75 d-block mx-auto '
														/>
													</div>
													<div className='d-md-flex justify-content-between'>
														<p className='acc-name'>
															{product.name}
														</p>
														<div
															className='d-flex'
															style={{
																gap: "1em",
															}}>
															{product.slashPrice >
																0 && (
																<p
																	className='acc-price'
																	style={{
																		textDecoration:
																			"line-through",
																	}}>
																	{currencyFormat(
																		product.slashPrice,
																	)}
																</p>
															)}

															<p className='acc-price'>
																{currencyFormat(
																	product.price,
																)}
															</p>
														</div>
													</div>
												</Link>
											</div>
										),
								)}
						</div>
					</div>
					<div className='container-fluid ps-lg-3 pe-lg-3'>
						<div
							className=' mt-4'
							style={{
								borderTop: "1px solid lightgrey",
							}}></div>
					</div>
					<div className='container-fluid  mt-0 p-lg-4'>
						<div className='row align-items-center'>
							<div className=' col-md-3 mt-2 col-12'>
								<small className='category-subheading-1'>
									Commute
								</small>
								<h4 className='mx-auto category-heading-1'>
									Modern days' bare necessities
								</h4>{" "}
							</div>

							{accessories &&
								accessories.map(
									(product) =>
										product.subCategory ===
											"bag" && (
											<div className='  col-md-3 mt-2  p-md-3  col-6'>
												<Link
													to={`/accessories/${product.urlName}`}
													className='text-decoration-none text-dark'>
													<div className=' p-lg-5 p-2   acc-card'>
														<img
															src={
																product.DisplayImg
															}
															alt=''
															className='img-fluid w-75 d-block mx-auto '
														/>
													</div>
													<div className='d-md-flex justify-content-between'>
														<p className='acc-name'>
															{product.name}
														</p>
														<div
															className='d-flex'
															style={{
																gap: "1em",
															}}>
															{product.slashPrice >
																0 && (
																<p
																	className='acc-price'
																	style={{
																		textDecoration:
																			"line-through",
																	}}>
																	{currencyFormat(
																		product.slashPrice,
																	)}
																</p>
															)}

															<p className='acc-price'>
																{currencyFormat(
																	product.price,
																)}
															</p>
														</div>
													</div>
												</Link>
											</div>
										),
								)}
							{accessories &&
								accessories.map(
									(product) =>
										product.subCategory ===
											"holder" && (
											<div className='  col-md-3 mt-2  p-md-3  col-6'>
												<Link
													to={`/accessories/${product.urlName}`}
													className='text-decoration-none text-dark'>
													<div className=' p-lg-5 p-2   acc-card'>
														<img
															src={
																product.DisplayImg
															}
															alt=''
															className='img-fluid w-75 d-block mx-auto '
														/>
													</div>
													<div className='d-md-flex justify-content-between'>
														<p className='acc-name'>
															{product.name}
														</p>
														<div
															className='d-flex'
															style={{
																gap: "1em",
															}}>
															{product.slashPrice >
																0 && (
																<p
																	className='acc-price'
																	style={{
																		textDecoration:
																			"line-through",
																	}}>
																	{currencyFormat(
																		product.slashPrice,
																	)}
																</p>
															)}

															<p className='acc-price'>
																{currencyFormat(
																	product.price,
																)}
															</p>
														</div>
													</div>
												</Link>
											</div>
										),
								)}
						</div>
					</div>
					<div className='container-fluid ps-lg-3 pe-lg-3'>
						<div
							className=' mt-4'
							style={{
								borderTop: "1px solid lightgrey",
							}}></div>
					</div>
					<div className='container-fluid  mt-0 p-lg-4'>
						<div className='row align-items-center'>
							<div className=' col-md-3 mt-2 col-12'>
								<small className='category-subheading-1'>
									Add-ons
								</small>
								<h4 className='mx-auto category-heading-1'>
									Add a little extra life
								</h4>{" "}
							</div>

							{accessories &&
								accessories.map(
									(product) =>
										product.subCategory ===
											"spare" && (
											<div className='  col-md-3 mt-2  p-md-3  col-6'>
												<Link
													to={`/accessories/${product.urlName}`}
													className='text-decoration-none text-dark'>
													<div className=' p-lg-3 p-2  acc-card'>
														<img
															src={
																product.DisplayImg
															}
															alt=''
															className='img-fluid w-75 d-block mx-auto '
														/>
													</div>
													<div className='d-md-flex justify-content-between'>
														<p className='acc-name'>
															{product.name}
														</p>
														<div
															className='d-flex'
															style={{
																gap: "1em",
															}}>
															{product.slashPrice >
																0 && (
																<p
																	className='acc-price'
																	style={{
																		textDecoration:
																			"line-through",
																	}}>
																	{currencyFormat(
																		product.slashPrice,
																	)}
																</p>
															)}

															<p className='acc-price'>
																{currencyFormat(
																	product.price,
																)}
															</p>
														</div>
													</div>
												</Link>
											</div>
										),
								)}
						</div>
					</div> */}
					<Footer />
				</>
			)}
		</>
	)
}

export default AllAccessoriesScreen
