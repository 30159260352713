import React, { useEffect } from "react"
import Meta from "../Components/Meta"
import '../CSS/login.css'
import { GoogleLogin } from "react-google-login"

import {
	GoogleLoginButton,
	FacebookLoginButton,
} from "react-social-login-buttons"
import { useDispatch, useSelector } from "react-redux"

import { loginUser } from "../Actions/userAction"

import { useNavigate } from "react-router-dom"
import { getloginUserInfo } from "../Actions/userAction"

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

const LoginScreen = () => {

	
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const userLogin = useSelector((state) => state.userLogin)
	const { loginInfo } = userLogin

	const getUserInfo = useSelector((state) => state.getUserInfo)
	const { userInfo: userDetails } = getUserInfo

	// redirect after login

	useEffect(() => {
		if (loginInfo) {
			dispatch(getloginUserInfo(loginInfo._id))
		}
	}, [loginInfo])

	// google respnse
	const successResponse = (response) => {
		const name = response.profileObj.name
		const email = response.profileObj.email
		const socialId = response.profileObj.googleId
		const profilePicture = response.profileObj.imageUrl
		const loginType = "google"
		dispatch(loginUser(socialId, name, email, loginType, profilePicture))
		navigate("/")
	}

	// facebook respone
	const responseFacebook = (response) => {
		const name = response.name
		const email = response.email
		const socialId = response.id
		const profilePicture = response.picture.data.url
		const loginType = "facebook"

		dispatch(loginUser(socialId, name, email, loginType, profilePicture))
	}

	return (
		<div className='container-fluid login '>
			<Meta
				title={
					' EMotorad | Login'
				}
				description={
					'EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price.'
				}
			/>
			<div className='row login  '>
				<div className='col-lg-3 p-3   ' >
				<i
						className='fa-solid text-dark fa-arrow-left-long mb-5 me-4'
						style={{ fontWeight: "900", fontSize: "1em" , cursor:'pointer' }}
						onClick={() => navigate('/')}> <span className="ms-2">GO BACK</span></i> 
					<img src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/black+1.png" className="img-fluid  mx-auto d-block w-50 margin-top-5" alt="" />
					<h6 className="text-center mt-2">Login With Social</h6>
					<div className='mt-5   login-btn mx-auto'>
						<GoogleLogin
							render={(renderProps) => (
								<GoogleLoginButton
									theme={"dark"}
									style={{
										paddingTop: "30px",
										paddingBottom: "30px",
										paddingLeft: "20px",
										borderRadius: "5px",
									}}
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}
									className=''></GoogleLoginButton>
							)}
							clientId={process.env.REACT_APP_CLIENT_ID}
							buttonText='Login'
							onSuccess={successResponse}
							cookiePolicy={"single_host_origin"}
						/>
					</div>
					<div className='mt-4 login-btn mx-auto'>
						<FacebookLogin
							appId={process.env.REACT_APP_FACEBOOK_ID}
							fields='name,email,picture'
							callback={responseFacebook}
							render={(renderProps) => (
								<FacebookLoginButton
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}
									style={{
										paddingTop: "30px",
										paddingBottom: "30px",
										paddingLeft: "20px",
										borderRadius: "5px",
									}}
								/>
							)}
						/>
					</div>
				</div>
				<div className='col-lg-9 p-0 d-none d-lg-block login-item'>
					
				</div>

				<div className='col-12 p-3 d-none text-light'>
					<i
						className='fa-solid text-dark fa-arrow-left-long me-4'
						style={{ fontWeight: "900", fontSize: "1.2em" }}
						onClick={() => navigate(-1)}></i>{" "}
				</div>

				<div className='col-12 d-none mt-2'>
					
					<h5 className='mt-5' style={{ fontSize: "1.2em", fontWeight: "600" }}>
						Sign in With Social
					</h5>
					<div className='mt-4'>
						<GoogleLogin
							render={(renderProps) => (
								<GoogleLoginButton
									theme={"dark"}
									style={{
										paddingTop: "30px",
										paddingBottom: "30px",
										paddingLeft: "20px",
										borderRadius: "5px",
									}}
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}
									className=''></GoogleLoginButton>
							)}
							clientId={process.env.REACT_APP_CLIENT_ID}
							buttonText='Login'
							onSuccess={successResponse}
							cookiePolicy={"single_host_origin"}
						/>
					</div>
					<div className='mt-4'>
						<FacebookLogin
							appId={process.env.REACT_APP_FACEBOOK_ID}
							fields='name,email,picture'
							callback={responseFacebook}
							render={(renderProps) => (
								<FacebookLoginButton
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}
									style={{
										paddingTop: "30px",
										paddingBottom: "30px",
										paddingLeft: "20px",
										borderRadius: "5px",
									}}
								/>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginScreen
