import React, { useEffect } from "react"
import Navbar from "../Components/Navbar"
import WhatsAppBot from "../Components/WhatsAppBot"
import { getAllBikes } from "../Actions/bikeActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { Link } from "react-router-dom"
import Meta from "../Components/Meta"
import Footer from "../Components/Footer"

const AllBikesScreen = () => {
	const currencyFormat = (num) => {
		return (
			"$" +
			" " +
			num
				.toFixed(0)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		)
	}

	const dispatch = useDispatch()

	const allAccessories = useSelector(
		(state) => state.allAccessories,
	)
	const { accessories } = allAccessories

	useEffect(() => {
		dispatch(getAllBikes())
	}, [])

	return (
		<>
			<WhatsAppBot bottom={"5%"} />
			<Navbar />
			<Meta
				title={" EMotorad | Accessories"}
				description={
					"EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
				}
			/>
			<div className='position-relative '>
				<div
					className='d-none d-md-block position-relative banner-img'
					style={{
						backgroundImage:
							"url('https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/allbikes/All-Bikes-Home-Banner.jpg')",
					}}></div>

				<div
					className='d-md-none d-md-block position-relative banner-img-sm'
					style={{
						backgroundImage:
							"url('https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/allbikes/All-Bike-Mobile-Home-Banner-.jpg')",
						backgroundSize: "cover",
						backgroundPosition: "bottom",
					}}></div>
			</div>
			<div className='container mt-lg-5 mt-3'>
				<h2 className='mt-lg-5 mb-1 fw-bold font-mon mt-4'>
					Our Range
				</h2>

				<div className='row mt-4 mb-4'>
					{accessories &&
						accessories.map(
							(product, i) =>
								product.category === "bike" && (
									<div className='col-12 mt-5 mt-lg-3 pt-4  pt-lg-0 col-lg-4  '>
										<Link
											to={`/bikes/${product.urlName}`}
											className='text-decoration-none'>
											<div className='  bike-card  pt-lg-4 pb-lg-4'>
												<div className='flag'></div>
												<div className='bike-img-bg'>
													<img
														src={
															product
																.cartImages[0]
														}
														className='img-fluid'
														alt=''
													/>
												</div>
												<h5 className='card-bike-name mt-3 text-center text-dark'>
													{product.name}
												</h5>
												<div className='card-points text-dark mt-3'>
													{product.highLights.map(
														(spec) => (
															<p>
																<i class='fa-solid fa-circle-stop text-em '></i>{" "}
																{spec}
															</p>
														),
													)}
												</div>
												<hr />
												<p className='card-price text-dark d-flex'>
													{currencyFormat(
														product.price,
													)}
													<span className=''>
														{" "}
														{currencyFormat(
															product.slashPrice,
														)}
													</span>
												</p>{" "}
												{product.inStock > 0 ? (
													<Link
														className='text-decoration-none'
														to={`/bikes/${product.urlName}`}>
														<button>
															EXPLORE
														</button>
													</Link>
												) : (
													<button className='disabled btn btn-dark rounded-0'>
														Out of Stock
													</button>
												)}
											</div>
										</Link>
									</div>
								),
						)}
				</div>
			</div>
			<Footer />
		</>
	)
}

export default AllBikesScreen
