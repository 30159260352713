import React, { useState, useEffect } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import "../CSS/XFactorPrebook.css"
import logo from "../images/logo1.png"
import { useNavigate } from "react-router-dom"

const XFactorPrebookScreen = () => {
	const [orderInfo, setOrderInfo] = useState({})
	const [bike, setBike] = useState([])
	const [acc, setAcc] = useState([])

	const { id } = useParams()
	const navigate = useNavigate()

	const bikes = [
		{
			name: "X1",
			price: 24999,
			model: [
				{
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/B1.png",
					colorName: "Deep Blue",
					bgColor:
						"linear-gradient(180deg, #87A5D1 0%, rgba(193, 218, 255, 0.6) 100%);",
					colorCode:
						"linear-gradient(to bottom left, #447DBA 50%, #383c3e 50%)",
				},
				{
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/Y1.png",
					colorName: "Traffic Yellow",
					bgColor:
						"linear-gradient(180deg, #C8C0AC 0%, rgba(217, 209, 190, 0.65) 100%)",
					colorCode:
						"linear-gradient(to bottom left, #EED644 50%, #383c3e 50%)",
				},
			],
			accessories: [
				{
					name: "Light",
					price: 999,
					desc: "",
					qty: 0,
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/addons-accessories/light+3.png",
				},
				{
					name: "Light 2",
					price: 999,
					desc: "",
					qty: 0,
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/addons-accessories/light+21.png",
				},
			],
		},
		{
			name: "X2",
			price: 27999,
			model: [
				{
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/R1.png",
					colorName: "Warm Red",
					bgColor:
						"linear-gradient(180deg, #C8C0AC 0%, rgba(217, 209, 190, 0.65) 100%)",
					colorCode:
						"linear-gradient(to bottom left, #EE4C46 50%, #464646 50%)",
				},
				{
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/1.png",
					colorName: "Sky Blue",
					bgColor:
						"linear-gradient(180deg, #C8C0AC 0%, rgba(217, 209, 190, 0.65) 100%)",
					colorCode:
						"linear-gradient(to bottom left, #C1DAD9 50%, #3f3f3f 50%)",
				},
			],
			accessories: [
				{
					name: "Light",
					price: 999,
					desc: "",
					qty: 0,
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/addons-accessories/light+3.png",
				},
			],
		},
		{
			name: "X3",
			price: 32999,
			model: [
				{
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/G1.png",
					colorName: "Champagne Gold",
					bgColor:
						"linear-gradient(180deg, #C8C0AC 0%, rgba(217, 209, 190, 0.65) 100%)",
					colorCode:
						"linear-gradient(to bottom left, #D7D5CD 50%, #181818 50%)",
				},
				{
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/S1.png",
					colorName: "Polished Silver",
					bgColor:
						"linear-gradient(180deg, #C8C0AC 0%, rgba(217, 209, 190, 0.65) 100%)",
					colorCode:
						"linear-gradient(to bottom left, #DADADA 50%, #181818 50%)",
				},
			],
			accessories: [
				{
					name: "Light",
					price: 999,
					desc: "",
					qty: 0,
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/addons-accessories/light+3.png",
				},
				{
					name: "Light 2",
					price: 999,
					desc: "",
					qty: 0,
					img: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/addons-accessories/light+21.png",
				},
			],
		},
	]

	const loadScript = (src) => {
		return new Promise((resolve) => {
			const script =
				document.createElement("script")
			script.src = src
			script.onload = () => {
				resolve(true)
			}
			script.onerror = () => {
				resolve(false)
			}
			document.body.appendChild(script)
		})
	}

	useEffect(() => {
		loadScript(
			"https://checkout.razorpay.com/v1/checkout.js",
		)
	})

	useEffect(() => {
		const getPrebookingOrderDetails =
			async () => {
				const { data } = await axios.get(
					`/api/form/preorders/${id}`,
				)

				if (data) {
					setOrderInfo(data)
					setBike(
						bikes.filter(
							(item) =>
								item.name ===
								data.bikeInfo.bikeName,
						),
					)
				}
			}
		getPrebookingOrderDetails()
	}, [])

	const displayRazorpay = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		}

		const result = await axios.post(
			"/api/payment/fullpreorder",
			{
				amount:
					bike[0].price -
					999 +
					acc.reduce(
						(a, item) => a + item.price,
						0,
					),
				id: orderInfo._id,
			},
			config,
		)

		if (!result) {
			alert("Server error. Are you online?")
			return
		}

		const {
			amount,
			currency,
			id: order_id,
		} = result.data

		// console.log(result.data)

		const options = {
			key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
			amount: amount.toString(),
			currency: currency,
			name: "EMotorad",

			image: logo,
			order_id: order_id,
			handler: async function (response) {
				// console.log(response)
				const data = {
					orderCreationId: order_id,
					razorpayPaymentId:
						response.razorpay_payment_id,
					razorpayOrderId:
						response.razorpay_order_id,
					razorpaySignature:
						response.razorpay_signature,
					orderId: orderInfo._id,
				}

				const result = await axios.post(
					"/api/payment/fullsuccess",

					{ data, orderInfo },
					config,
				)

				if (result) {
					navigate("/preorder-success")
				} else {
				}
			},
			prefill: {
				name: orderInfo.name,
				email: orderInfo.email,
				contact: orderInfo.phoneNumber,
			},
		}

		const paymentObject = new window.Razorpay(
			options,
		)
		const paydata = await paymentObject.open(
			options,
		)
	}

	const accessoriesAddOrRemove = (i, name) => {
		if (
			acc.find(
				(item) =>
					item.name ===
					bike[0].accessories[i].name,
			)
		) {
			setAcc(
				acc.filter((item) => item.name !== name),
			)
		} else {
			setAcc([...acc, bike[0].accessories[i]])
		}
	}

	const currencyFormat = (num) => {
		return (
			"Rs" +
			" " +
			num
				.toFixed(0)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		)
	}

	const changeColor = (i) => {
		setOrderInfo((prevState) => ({
			...prevState,
			bikeInfo: {
				...prevState.bikeInfo,
				color: bike[0].model[i].colorName,
			},
		}))
	}

	const changeBike = async (newBikeName) => {
		try {
			const c = bikes.filter(
				(item) => item.name === newBikeName,
			)
			setOrderInfo((prevState) => ({
				...prevState,

				bikeInfo: {
					...prevState.bikeInfo,
					bikeName: newBikeName,

					color: c[0].model[0].colorName,
				},
			}))
			setBike(
				bikes.filter(
					(item) => item.name === newBikeName,
				),
			)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div className='container-fluid '>
			{orderInfo && bike.length > 0 && (
				<div>
					<div className='row main'>
						<div
							className='col-lg-8 position-relative align-items-center justify-content-center d-flex h-100 '
							style={{
								background:
									"linear-gradient(180deg, #C8C0AC 0%, rgba(217, 209, 190, 0.65) 100%)",
							}}>
							<img
								src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/black+1.png'
								alt=''
								className='img-fluid logo'
							/>
							<div
								className='x-fator-colors d-flex'
								style={{ gap: "3em" }}>
								<div className='change-bike d-none d-lg-block'>
									<h6>Change Bike</h6>
									<select
										value={
											orderInfo.bikeInfo.bikeName
										}
										onChange={(e) =>
											changeBike(e.target.value)
										}
										className='form-select bike-select'
										aria-label='Default select example'>
										<option value='X1'>X1</option>
										<option value='X2'>X2</option>
										<option value='X3'>X3</option>
									</select>
								</div>
								<div>
									<h6>Change Color</h6>
									<div
										className='d-flex '
										style={{ gap: "0.6em" }}>
										{bike[0].model.map(
											(item, i) => (
												<div
													onClick={() =>
														changeColor(i)
													}
													className={`p-1 ${
														orderInfo.bikeInfo
															.color ===
															item.colorName &&
														" bike-color-icons-active"
													} `}
													style={{
														borderRadius: "50%",
													}}>
													{" "}
													<div
														className='bike-color-icons'
														style={{
															background:
																item.colorCode,
														}}></div>
												</div>
											),
										)}
									</div>
								</div>
							</div>

							<div className='x-bike-name '>
								<h5>
									{orderInfo.bikeInfo.bikeName} |{" "}
									{""}
									{orderInfo.bikeInfo.color}
								</h5>
							</div>

							{orderInfo.bikeInfo.bikeName ==
								"X1" &&
								orderInfo.bikeInfo.color ==
									"Deep Blue" && (
									<img
										src={
											"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/B1.png"
										}
										className='img-fluid bike-img'
										alt=''
									/>
								)}
							{orderInfo.bikeInfo.bikeName ==
								"X1" &&
								orderInfo.bikeInfo.color ==
									"Traffic Yellow" && (
									<img
										src={
											"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/Y1.png"
										}
										className='img-fluid bike-img'
										alt=''
									/>
								)}
							{orderInfo.bikeInfo.bikeName ==
								"X2" &&
								orderInfo.bikeInfo.color ==
									"Warm Red" && (
									<img
										src={
											"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/R1.png"
										}
										className='img-fluid bike-img'
										alt=''
									/>
								)}
							{orderInfo.bikeInfo.bikeName ==
								"X2" &&
								orderInfo.bikeInfo.color ==
									"Sky Blue" && (
									<img
										src={
											"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/1.png"
										}
										className='img-fluid bike-img'
										alt=''
									/>
								)}
							{orderInfo.bikeInfo.bikeName ==
								"X3" &&
								orderInfo.bikeInfo.color ==
									"Champagne Gold" && (
									<img
										src={
											"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/G1.png"
										}
										className='img-fluid bike-img'
										alt=''
									/>
								)}
							{orderInfo.bikeInfo.bikeName ==
								"X3" &&
								orderInfo.bikeInfo.color ==
									"Polished Silver" && (
									<img
										src={
											"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/S1.png"
										}
										className='img-fluid bike-img'
										alt=''
									/>
								)}
						</div>
						<div className='col-lg-4 info  p-lg-2 ps-lg-4 pe-lg-4 pb-lg-0  '>
							<div className='d-lg-none'>
								<h6 className='mb-2'>
									Change Bike
								</h6>
								<select
									value={
										orderInfo.bikeInfo.bikeName
									}
									onChange={(e) =>
										changeBike(e.target.value)
									}
									className='form-select m-0 bike-select'
									aria-label='Default select example'>
									<option value='X1'>X1</option>
									<option value='X2'>X2</option>
									<option value='X3'>X3</option>
								</select>
							</div>

							<div className='userInfo mt-4 mt-lg-5'>
								<div className='d-flex mb-2  pe-lg-4 justify-content-between'>
									<h6 className='m-0'>
										ORDER DETAILS
									</h6>

									<i class='fa-solid fa-pen-to-square'></i>
								</div>
								<div
									className='p-3'
									style={{
										border: "2px solid #DED9D9",
										borderRadius: "5px",
									}}>
									<div
										className='d-flex'
										style={{ gap: "1em" }}>
										<p>Name:</p>
										<p className='fw-bold'>
											{orderInfo.name}
										</p>
									</div>
									<div
										className='d-flex'
										style={{ gap: "1em" }}>
										<p>Email:</p>
										<p className='fw-bold'>
											{orderInfo.email}
										</p>
									</div>
									<div
										className='d-flex'
										style={{ gap: "1em" }}>
										<p>Phone Number: </p>
										<p className='fw-bold'>
											{orderInfo.phoneNumber}
										</p>
									</div>
									<div
										className='d-flex'
										style={{ gap: "1em" }}>
										<p className='mb-0'>
											Address:{" "}
										</p>
										<p className='fw-bold mb-0'>
											{orderInfo.address}
										</p>
									</div>
								</div>
							</div>

							<hr />

							<div className='userInfo d-none mt-lg-4'>
								<h6>ACCESSORIES</h6>
								<div
									className='p-3 pe-lg-4 p-lg-4 pt-lg-3'
									style={{
										border: "2px solid #DED9D9",
										borderRadius: "5px",
									}}>
									{bike[0].accessories.map(
										(item, i) => (
											<>
												<div className='row'>
													<div
														className={`col-12  p-2 d-flex ${
															acc.find(
																(item) =>
																	item.name ===
																	bike[0]
																		.accessories[
																		i
																	].name,
															) &&
															"border border-dark"
														} `}
														onClick={() => {
															accessoriesAddOrRemove(
																i,
																item.name,
															)
														}}>
														<div
															className='position-relative'
															style={{
																width: "140px",
																height: "100px",
																background:
																	"#D6D3CC",
																borderRadius:
																	"5px",
															}}>
															<img
																src={item.img}
																alt=''
																className='img-fluid d-block mx-auto '
															/>
															<i
																style={{
																	right: "4%",
																	top: "4%",
																}}
																className={`fa-solid  text-muted fa-circle-check position-absolute ${
																	acc.find(
																		(item) =>
																			item.name ===
																			bike[0]
																				.accessories[
																				i
																			].name,
																	)
																		? "d-block"
																		: "d-none"
																}`}></i>
														</div>

														<div className='w-100 pt-2  ps-3'>
															<h6 className='d-flex  justify-content-between m-0'>
																{" "}
																<span>
																	{item.name}{" "}
																</span>{" "}
																<span>₹999</span>
															</h6>
															<p
																className='mt-2'
																style={{
																	lineHeight:
																		"17px",
																	fontSize:
																		"0.7em",
																}}>
																Lorem ipsum, dolor
																sit amet
																consectetur
																adipisicing elit.
																Aperiam nobis ea
															</p>
														</div>
													</div>
												</div>
												<hr />
											</>
										),
									)}
								</div>
							</div>
							<hr />
							<div className='userInfo mt-lg-4'>
								<h6 className=''>
									Invoice Details
								</h6>
								<div
									className=' w-100 mb-4 p-3'
									style={{
										border: "2px solid #DED9D9",
										borderRadius: "5px",
									}}>
									<table class='table table-borderless '>
										<tbody>
											<tr>
												<td className=''>
													{
														orderInfo.bikeInfo
															.bikeName
													}{" "}
													| {""}
													{
														orderInfo.bikeInfo
															.color
													}
												</td>
												<td className='fw-bold'>
													{currencyFormat(
														bike[0].price,
													)}
												</td>
											</tr>
											<tr>
												<td className=''>
													Prebooking Amount
												</td>
												<td className='fw-bold text-success'>
													{" "}
													- ₹999
												</td>
											</tr>
											<tr className='d-none'>
												<td className=''>
													Accessories
												</td>
												<td className='fw-bold'>
													{currencyFormat(
														acc.reduce(
															(a, item) =>
																a + item.price,
															0,
														),
													)}
												</td>
											</tr>
											<tr>
												<td className=''>
													Total
												</td>
												<td className='fw-bold'>
													{currencyFormat(
														bike[0].price -
															999 +
															acc.reduce(
																(a, item) =>
																	a + item.price,
																0,
															),
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div
								className=' d-none d-lg-flex pt-3 m-0 pb-3 position-sticky align-items-center'
								style={{
									bottom: "0%",
									backgroundColor: "#fff",
									borderTop: "1px solid #DED9D9",
								}}>
								<div className='col-5  '>
									<p
										style={{ fontSize: "0.9em" }}
										className='m-0 text-muted'>
										Total Order Value
									</p>
									<h3 className='fw-bold m-0'>
										{" "}
										{currencyFormat(
											bike[0].price -
												999 +
												acc.reduce(
													(a, item) =>
														a + item.price,
													0,
												),
										)}
									</h3>
								</div>
								<div className='col-7'>
									<button
										onClick={displayRazorpay}
										style={{
											borderRadius: "40px",
										}}
										className='btn w-100 p-3 btn-dark'>
										<p className='m-0'>
											PROCEED AND PAY
										</p>
									</button>
								</div>
							</div>
						</div>
						<div
							className='d-flex  d-lg-none pt-3 m-0 pb-3 position-sticky align-items-center'
							style={{
								bottom: "0%",
								backgroundColor: "#fff",
								borderTop: "1px solid #DED9D9",
							}}>
							<div className='col-5  '>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-muted'>
									Total Order Value
								</p>
								<h3 className='fw-bold m-0'>
									{" "}
									{currencyFormat(
										bike[0].price -
											999 +
											acc.reduce(
												(a, item) =>
													a + item.price,
												0,
											),
									)}
								</h3>
							</div>
							<div className='col-7'>
								<button
									onClick={displayRazorpay}
									style={{ borderRadius: "40px" }}
									className='btn w-100 p-2 btn-dark'>
									<p className='m-0'>
										PROCEED AND PAY
									</p>
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default XFactorPrebookScreen
