import React, { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useDispatch, useSelector } from "react-redux";
import { getAllBikes } from "../Actions/bikeActions";
import Meta from "../Components/Meta";
import axios from "axios";
import "../CSS/home.css";
import "../CSS/mobHome.css";

import Navbar from "../Components/Navbar";

import { useNavigate } from "react-router-dom";
const HomeScreen = () => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBikes());
  }, []);
  const [email, setEmail] = useState("");

  const [newsPosts, setNewsPosts] = useState([]);
  const hygraph = new GraphQLClient(
    "https://api-ap-south-1.hygraph.com/v2/cldbcsdpf0ldg01t905z8hcx8/master"
  );

  const QUERY = gql`
    {
      posts(first: 1, orderBy: date_DESC) {
        id
        title
        link
        date
        excerpt
        coverImage {
          url
        }
      }
    }
  `;
  useEffect(() => {
    const getBlogs = async () => {
      const { posts } = await hygraph.request(QUERY);
      setNewsPosts(posts);
    };
    getBlogs();
  }, []);

  const allAccessories = useSelector((state) => state.allAccessories);
  const { accessories } = allAccessories;

  const collectEmail = async (e) => {
    try {
      e.preventDefault();
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/form/prebook",
        {
          email,
        },
        config
      );
      if (data) {
        setEmail("");
      }
    } catch (error) {}
  };

  const currencyFormat = (num) => {
    return "$" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <>
      {" "}
      <Meta
        title={
          " EMotorad | Buy Electric Bicycle & Bike Online | Best E Bicycle & E Bike"
        }
        description={
          "EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
        }
      />
      <Navbar />
      <div className="">
        <Splide
          className="p-0"
          options={{
            arrows: true,
            drag: true,
            type: "loop",
            pagination: true,
            autoplay: false,
            perMove: 1,
            gap: "0em",
            pauseOnHover: false,
            resetProgress: false,
            interval: 5000,
            perPage: 1,
            speed: 1000,
            breakpoints: {
              640: {
                perPage: 1,
                arrows: true,
              },
            },
          }}
        >
          <SplideSlide>
            <div className="position-relative ">
              <div
                className="d-none d-md-block position-relative banner-img"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/home/banners/desktop/Doodle-Home-Banner.jpg')",
                }}
              >
                <div className="slide-1-content">
                  <h1>Doodle</h1>
                  <p>All eyes on you</p>
                  <div className="mt-5">
                    <Link to="/bikes/doodle">Explore Now</Link>
                  </div>
                </div>
              </div>

              <div
                className="d-md-none d-md-block position-relative banner-img-sm"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/banners/mobile/1-Mobile-Home-Banner.jpg')",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                }}
              >
                <div className="slide-1-content-sm">
                  <div className="mt-3  ">
                    <Link to="/bikes/doodle">Explore Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="position-relative ">
              <div
                className="d-none d-md-block position-relative banner-img"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/banners/desktop/AUS-Home-Banner1.jpg')",
                }}
              >
                <div className="slide-1-content">
                  <h1>Beach Rider</h1>
                  <p>Ride the tide</p>
                  <div className="mt-5">
                    <Link to="/bikes/beach-rider">Explore Now</Link>
                  </div>
                </div>
              </div>

              <div
                className="d-md-none d-md-block position-relative banner-img-sm"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/banners/mobile/2-Mobile-Home-Banner.jpg')",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                }}
              >
                <div className="slide-1-content-sm">
                  <div className="mt-3  ">
                    <Link to="/bikes/beach-rider">Explore Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="position-relative ">
              <div
                className="d-none d-md-block position-relative banner-img"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/banners/desktop/AUS-Home-Banner-2.jpg')",
                }}
              >
                <div className="slide-1-content">
                  <h1>Doodle</h1>
                  <p>Unfold The Adventure</p>
                  <div className="mt-5">
                    <Link to="/bikes/doodle">Explore Now</Link>
                  </div>
                </div>
              </div>

              <div
                className="d-md-none d-md-block position-relative banner-img-sm"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/home/banners/mobile/012-Home-Banner.jpg')",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                }}
              >
                <div className="slide-1-content-sm">
                  <div className="mt-3  ">
                    <Link to="/bikes/doodle">Explore Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="position-relative ">
              <div
                className="d-none d-md-block position-relative banner-img"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/banners/desktop/AUS-Home-Banner-4.jpg')",
                }}
              >
                <div className="slide-1-content">
                  <h1>Beach Rider</h1>
                  <p>Rule the Shores</p>
                  <div className="mt-5">
                    <Link to="/bikes/beach-rider">Explore Now</Link>
                  </div>
                </div>
              </div>

              <div
                className="d-md-none d-md-block position-relative banner-img-sm"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/banners/mobile/3-Mobile-Home-Banner.jpg')",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                }}
              >
                <div className="slide-1-content-sm">
                  <div className="mt-3  ">
                    <Link to="/bikes/beach-rider">Explore Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>{" "}
          <SplideSlide>
            <div className="position-relative ">
              <div
                className="d-none d-md-block position-relative banner-img"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/banners/desktop/AUS-EMX-Home-Banner-4.jpg')",
                }}
              >
                <div className="slide-1-content">
                  <h1>EMX</h1>
                  <p>The Dual Suspension Beast</p>
                  <div className="mt-5">
                    <Link to="/allbikes">Explore Now</Link>
                  </div>
                </div>
              </div>

              <div
                className="d-md-none d-md-block position-relative banner-img-sm"
                style={{
                  backgroundImage:
                    "url('https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/banners/mobile/4-Mobile-Home-Banner.jpg')",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                }}
              >
                <div className="slide-1-content-sm">
                  <div className="mt-3  ">
                    <Link to="/allbikes">Explore Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>
      {/* <h4>
				A network of support at the ready and on
				the road.
			</h4> */}
      <div className="range overflow-auto container-fluid p-3  mt-lg-5 mb-4 mb-lg-5">
        <div className="d-flex ">
          <div className=" col-11 col-lg-4  p-2 range-card ">
            <Link to="/bikes/beach-rider">
              <div className="position-relative rounded">
                <div className="overlay rounded"></div>
                <div className="headline rounded d-flex  align-items-center  justify-content-between w-100 p-3 p-lg-5">
                  <p className="m-0">Beach Rider</p>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/home/Arrow_Icon.png"
                    className="range-arrow"
                  />
                </div>
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/Beach-Rider-Home-Banner.jpg"
                  className="img-fluid rounded"
                  alt=""
                />
              </div>
            </Link>
          </div>{" "}
          <div className="col-11 col-lg-4 p-2 range-card ">
            <div className="position-relative rounded">
              <Link to={"/bikes/doodle"} className="text-decoration-none">
                <div className="overlay rounded "></div>
                <div className="headline  rounded d-flex  align-items-center  justify-content-between w-100 p-3 p-lg-5">
                  <p className="m-0">Doodle</p>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/home/Arrow_Icon.png"
                    className="range-arrow"
                  />
                </div>
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/home/banners/mobile/03Range-Home-Banner.jpg"
                  className="img-fluid  rounded"
                  alt=""
                />
              </Link>
            </div>
          </div>{" "}
          <div className=" col-11 col-lg-4 p-2 range-card ">
            <Link to="/bikes/emx">
              <div className="position-relative rounded">
                <div className="overlay rounded"></div>
                <div className="headline  rounded d-flex  align-items-center  justify-content-between w-100 p-3 p-lg-5">
                  <p className="m-0">EMX</p>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/home/Arrow_Icon.png"
                    className="range-arrow"
                  />
                </div>
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/EMX-Home-Banner.jpg"
                  className="img-fluid rounded "
                  alt=""
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* .......... */}
      <div className="container-fluid overflow-hidden mb-5 best-seller">
        <div className="row align-items-center">
          <div className="col-md-8 col-12  ">
            {" "}
            <p className="best-seller-text-subHeading d-md-none text-align-center">
              Bestseller
            </p>
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/home/Best+Seller.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-4 col-12  ">
            <div className="best-seller-text ">
              <p className="best-seller-text-subHeading d-none d-md-block">
                Bestseller
              </p>
              <h1>DOODLE</h1>
              <p className="best-seller-text-desc">
                The Fat tyre folding e-bike Doodle is an agile and quick e-bike
                with power to spare when you are in the mood for a quick trip.
              </p>
              <Link to="/bikes/doodle">
                <button className="mt-lg-4 ">BUY NOW</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* ............. */}
      {accessories && (
        <div className="container-fluid p-lg-5 mb-4 the-bikes">
          <h1 className="fw-bold text-center text-md-start">Our Bikes</h1>
          <Splide
            className="p-lg-5 ps-0 pe-0"
            options={{
              arrows: true,
              drag: false,
              type: "loop",
              pagination: true,
              autoplay: false,
              perMove: 1,
              gap: "0.5em",
              pauseOnHover: false,
              resetProgress: false,
              interval: 5000,
              perPage: 3,
              speed: 1000,
              breakpoints: {
                640: {
                  arrows: true,
                  perPage: 1,
                  pagination: true,
                },
              },
            }}
          >
            {accessories.map(
              (bike) =>
                bike.category === "bike" && (
                  <SplideSlide>
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/bikes/${bike.urlName}`}
                    >
                      <div className=" h-100  bike-card  pt-lg-4 pb-lg-4">
                        <div className="flag"></div>
                        <div className="bike-img-bg">
                          <img
                            src={bike.cartImages[0]}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <h5 className="card-bike-name">{bike.name}</h5>
                        <div className="card-points mt-3">
                          {bike.highLights &&
                            bike.highLights.map((highlight) => (
                              <p
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  width: "100%",
                                }}
                              >
                                <i class="fa-solid fa-circle-stop text-em "></i>{" "}
                                {highlight}
                              </p>
                            ))}
                        </div>
                        <hr />
                        <p className="card-price d-flex">
                          {currencyFormat(bike.price)}
                          {bike.slashPrice > 0 && (
                            <span className="">
                              {currencyFormat(bike.slashPrice)}
                            </span>
                          )}
                        </p>{" "}
                        <Link
                          className="text-decoration-none"
                          to={`/bikes/${bike.urlName}`}
                        >
                          <button>EXPLORE</button>
                        </Link>
                      </div>
                    </Link>
                  </SplideSlide>
                )
            )}
          </Splide>
        </div>
      )}
      {/* ........ */}
      <div className=" position-relative breaker-1 mt-lg-5">
        <div className="position-absolute breaker-1-text">
          <h1>Do more than just move.</h1>
          <p>
            We know what riders want from their e-bike, and we’ve crafted
            precisely that.
            <br /> Upgrade your travels with our premium e-bikes.
          </p>
        </div>
      </div>
      <div className="container-fluid accessories-section mb-4 mb-md-0   ">
        <div className="row p-0 ">
          <div className="col-12 position-relative col-lg-6  p-3 p-md-0">
            {" "}
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/Coming-soon-EMX-Home-Banner.png"
              className="img-fluid w-100"
              alt=""
            />
          </div>

          <div className="col-12    d-md-flex col-lg-6 align-items-center">
            <div className=" mx-auto  ">
              <h4 className="">The Dual Suspension Beast</h4>
              <h1>EMX</h1>
              <h4 className=" mt-lg-4">
                EMotorad’s all-new high performance MTB e-bike <br />{" "}
              </h4>
              <Link className="text-decoration-none" to={"/bikes/emx"}>
                <button className="btn btn-light w-25 d-block  d-none d-lg-block mt-4">
                  Explore Now
                </button>
                <button className="btn btn-dark w-50 d-block mx-auto  d-lg-none mt-4">
                  Explore Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* ................ */}
      <div className=" position-relative breaker-2">
        <div className="position-absolute breaker-2-text">
          <p>We hear you </p>
          <h1>
            Get in touch <br /> with us
          </h1>
          <Link to="/contactus" className="text-decoration-none">
            <button>Send Message</button>
          </Link>
        </div>
      </div>
      {/* ............. */}
      <div className="testimonials">
        <h1>Testimonials</h1>
        <div className="container testimonials-cards  ">
          <Splide
            className="p-0"
            options={{
              arrows: true,
              drag: false,
              type: "loop",
              pagination: true,
              autoplay: false,
              perMove: 1,
              gap: "1em",
              pauseOnHover: false,
              resetProgress: false,
              interval: 5000,
              perPage: 3,
              speed: 1000,
              breakpoints: {
                640: {
                  perPage: 1,
                  arrows: true,
                  drag: true,
                },
              },
            }}
          >
            <SplideSlide>
              <div className="p-4">
                <div className="mx-auto">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/reviews/Rider-A.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p
                  className="d-flex justify-content-center mt-4"
                  style={{ gap: "1.5em" }}
                >
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                </p>

                <h5 className="text-center">Craig</h5>
                <p className="testimonials-desc">
                  Woo hoo we went and got our new wheels yesterday!! Craigs very
                  cleaver as he had to modify the pups seats! Loving riding
                  these bikes that make it so easy. Pups love there new seats!
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="p-4">
                <div className="mx-auto">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/home/reviews/Rider-1.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p
                  className="d-flex justify-content-center mt-4"
                  style={{ gap: "1.5em" }}
                >
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                </p>

                <h5 className="text-center">Luca R.</h5>
                <p className="testimonials-desc">
                  "The Doodle e-bike has been a game changer for me. Its long
                  battery life and comfortable ride make it easy to tackle the
                  beach on weekends. I couldn't be happier with my purchase."
                </p>
              </div>
            </SplideSlide>{" "}
            <SplideSlide>
              <div className="p-4">
                <div className="mx-auto">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/reviews/Rider-D.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p
                  className="d-flex justify-content-center mt-4"
                  style={{ gap: "1.5em" }}
                >
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star-half"></i>
                </p>

                <h5 className="text-center">Liam Mitchell</h5>
                <p className="testimonials-desc">
                  "This is the ultimate beach cruiser! The fat tires make it
                  easy to ride on sand and the foldable design makes it easy to
                  store in my beach house."
                </p>
              </div>
            </SplideSlide>{" "}
            <SplideSlide>
              <div className="p-4">
                <div className="mx-auto">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/reviews/Rider-F.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p
                  className="d-flex justify-content-center mt-4"
                  style={{ gap: "1.5em" }}
                >
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star-half"></i>
                </p>

                <h5 className="text-center">Daniel W.</h5>
                <p className="testimonials-desc">
                  " I take it to the grocery store, the gym, and even to meet up
                  with friends. Love riding an ebike"
                </p>
              </div>
            </SplideSlide>{" "}
            <SplideSlide>
              <div className="p-4">
                <div className="mx-auto">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/home/reviews/Rider-5.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p
                  className="d-flex justify-content-center mt-4"
                  style={{ gap: "1.5em" }}
                >
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star-half"></i>
                </p>

                <h5 className="text-center">Martin S.</h5>
                <p className="testimonials-desc">
                  "The Doodle e-bike is a great choice for anyone looking for a
                  high-performance bike that can be convenient to take anywhere.
                  Its folding frame and reliable motor make it a joy to ride, no
                  matter where you go."
                </p>
              </div>
            </SplideSlide>{" "}
            <SplideSlide>
              <div className="p-4">
                <div className="mx-auto">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/home/reviews/Rider-4.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p
                  className="d-flex justify-content-center mt-4"
                  style={{ gap: "1.5em" }}
                >
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                </p>

                <h5 className="text-center">Maria G.</h5>
                <p className="testimonials-desc">
                  "I've been using the Doodle e-bike for my daily commute and
                  it's been a game changer. The bike is easy to maneuver and the
                  pedal assist feature makes it effortless to tackle the city
                  traffic. Highly recommend!"
                </p>
              </div>
            </SplideSlide>{" "}
            <SplideSlide>
              <div className="p-4">
                <div className="mx-auto">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/reviews/Rider-B.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p
                  className="d-flex justify-content-center mt-4"
                  style={{ gap: "1.5em" }}
                >
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                </p>

                <h5 className="text-center">Melque</h5>
                <p className="testimonials-desc">
                  Pic from before riding my monster to work today. Beautiful
                  sunset! 😍
                </p>
              </div>
            </SplideSlide>{" "}
            <SplideSlide>
              <div className="p-4">
                <div className="mx-auto">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/home/reviews/Rider-C.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p
                  className="d-flex justify-content-center mt-4"
                  style={{ gap: "1.5em" }}
                >
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                  <i class="fa-solid star-color fa-star"></i>
                </p>

                <h5 className="text-center">Charlotte L.</h5>
                <p className="testimonials-desc">
                  "I've been using the ebike to explore new areas around my town
                  and it's been a blast! "
                </p>
              </div>
            </SplideSlide>{" "}
          </Splide>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeScreen;
