import React, { useState, useEffect } from "react"

import logo from "../images/mainlogo.png"
import logo2 from "../images/logo2.png"

import navlogo from "../images/nav.png"
import cancel from "../images/cancel.png"
import { Link } from "react-router-dom"
import { redirectDomain } from "../Actions/redirectAction"

import {
	useDispatch,
	useSelector,
} from "react-redux"
import { getloginUserInfo } from "../Actions/userAction"

const Navbar = ({}) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "auto",
		})
	}, [])
	useEffect(() => {
		redirectDomain()
	}, [])
	const [open, openNav] = useState(false)
	const dispatch = useDispatch()

	const userLogin = useSelector(
		(state) => state.userLogin,
	)
	const { loginInfo } = userLogin

	const getUserInfo = useSelector(
		(state) => state.getUserInfo,
	)
	const { userInfo: userDetails } = getUserInfo

	const cart = useSelector((state) => state.cart)
	const { cartItems } = cart

	useEffect(() => {
		if (loginInfo) {
			dispatch(getloginUserInfo(loginInfo._id))
		}
	}, [loginInfo])

	const closeNavforDeadLink = () => {
		openNav(false)
	}

	return (
		<>
			<div
				className='position-sticky navigation w-100'
				style={{ zIndex: "2", top: "0%" }}>
				<div className=' desktop-nav m-0 p-0 d-none d-lg-block p-3 '>
					<div className='d-flex container  justify-content-between align-items-center'>
						<div className='col-3 '>
							<Link
								to='/'
								className='text-decoration-none'>
								<img
									src={logo}
									className='img-fluid'
									style={{ width: "200px" }}
									alt=''
								/>
							</Link>
						</div>{" "}
						<div
							className='d-flex   col-6 justify-content-center'
							style={{ gap: "3em" }}>
							<Link
								to='/'
								className='text-decoration-none dropdown-heading '>
								<p className='m-0'>Home</p>
							</Link>
							<div className='dropdown  '>
								<p
									className='m-0 dropdown-heading'
									style={{ cursor: "pointer" }}
									data-bs-toggle='collapse'
									data-bs-target='#collapseExample'>
									Bikes
									<i class='fa-solid ms-2 fa-angle-down'></i>
								</p>
								<div className='dropdown-content dropdown-content-1'>
									<div className=' mt-3  dropdown-content-list'>
										<Link
											to='/allbikes'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												All Bikes
											</p>
										</Link>
										<Link
											to='/bikes/doodle'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												Doodle
											</p>
										</Link>
										<Link
											to='/bikes/monster'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												Monster
											</p>
										</Link>
										<Link
											to='/bikes/thunder'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												Thunder
											</p>
										</Link>
										<Link
											to='/bikes/beach-rider'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												Beach Rider
											</p>
										</Link>
										<Link
											to='/bikes/emx'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												EMX
											</p>
										</Link>
										<Link
											to='/bikes/t-rex+'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												T-Rex+
											</p>
										</Link>
										<Link
											to='/bikes/x1'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												X1
											</p>
										</Link>
										<Link
											to='/bikes/x2'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												X2
											</p>
										</Link>
									</div>
								</div>
							</div>
							<Link
								to='/all-accessories'
								className='text-decoration-none dropdown-heading '>
								<p className='m-0'>Add-on</p>
							</Link>
							<Link
								to='/ourstory'
								className='text-decoration-none dropdown-heading '>
								<p className='m-0'>About Us</p>
							</Link>
							<div className='dropdown  '>
								<p
									className='m-0 dropdown-heading'
									style={{ cursor: "pointer" }}
									data-bs-toggle='collapse'
									data-bs-target='#collapseExample'>
									Assurance
									<i class='fa-solid ms-2 fa-angle-down'></i>
								</p>
								<div className='dropdown-content dropdown-content-2'>
									<div className=' mt-3  dropdown-content-list'>
										<Link
											to='/partner-with-us'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												Partner With Us
											</p>
										</Link>
										<Link
											to='/warranty'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												Warranty
											</p>
										</Link>
										<Link
											to='/contactus'
											className='text-decoration-none '>
											<p className='m-0  pt-4 p-3 nav-border-bottom nav-link'>
												Contact
											</p>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div
							className='col-3 d-flex justify-content-end align-items-center'
							style={{ gap: "2em" }}>
							<div className='pt-2'>
								<Link to={"/cart"}>
									<iconify-icon
										icon='clarity:shopping-cart-line'
										style={{ color: "white" }}
										width='23'></iconify-icon>{" "}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Mobile Navbar */}
			<div className='col-12 mobile-nav ps-2 pe-2  nav d-lg-none '>
				<div className=' w-100 d-flex justify-content-between align-items-center p-1 ps-0 pe-0'>
					<img
						src={navlogo}
						style={{ width: "25px" }}
						onClick={() => openNav(true)}
						alt='hamburger'
					/>

					<Link to='/'>
						<img
							src={logo2}
							className=''
							style={{ width: "50px" }}
							alt='em-logo'
						/>
					</Link>

					<Link
						to='/cart'
						className='text-decoration-none'>
						<span
							className='iconify'
							data-icon='clarity:shopping-cart-line'
							style={{ color: "#fff" }}
							data-width='25'></span>
						<sup className='text-decoration-none text-light  font-bold'>
							{cartItems &&
								cartItems.length > 0 &&
								cartItems.length}
						</sup>
					</Link>
				</div>
			</div>

			<div
				className={`mobile-sidenavbar d-lg-none ${
					open ? "navbar-open" : "navbar-off"
				} `}>
				<div className=''>
					<div className='d-flex pe-2 align-items-center justify-content-between'>
						<Link
							to='/'
							onClick={closeNavforDeadLink}>
							<img
								src={logo2}
								className=''
								style={{ width: "50px" }}
								alt='logo'
							/>
						</Link>
						<div
							className=''
							onClick={() => openNav(false)}>
							<i
								class='fa-solid fa-xmark'
								style={{ fontSize: "1.5em" }}></i>
						</div>
					</div>

					<div className='nav-links text-light  ps-1 '>
						<hr className=' mt-4 ' />

						<Link
							to={"/"}
							className='mt-2  text-decoration-none'
							onClick={closeNavforDeadLink}>
							<div className='pe-4'>
								<p className='mb-1 mt-1 text-light'>
									Home
								</p>
							</div>
						</Link>

						<hr className='mt-4 ' />

						<div className='pe-4'>
							<div
								className='d-flex justify-content-between align-items-center'
								data-bs-toggle='collapse'
								data-bs-target='#collapseExample'
								aria-expanded='false'
								aria-controls='collapseExample'>
								<p className='mb-0 mt-1 '>
									Bikes
								</p>
								<span
									className='iconify'
									data-icon='ep:arrow-down'
									style={{ color: "white" }}
									data-width='17'
									data-height='17'></span>
							</div>

							<div
								className='collapse mt-4  border-r-5 p-3 '
								style={{
									background: " #1a1a1a",
									borderRadius: "2px",
								}}
								id='collapseExample'>
								<Link
									to={"/allbikes"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									All Bikes
								</Link>
								<hr className=' ' />
								<Link
									to={"/bikes/doodle"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									Doodle
								</Link>{" "}
								<hr className=' ' />
								<Link
									to={"/bikes/monster"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									Monster
								</Link>{" "}
								<hr className=' ' />
								<Link
									to={"/bikes/thunder"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									Thunder
								</Link>{" "}
								<hr className=' ' />
								<Link
									to={"/bikes/beach-rider"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									Beach Rider
								</Link>
								<hr className=' ' />
								<Link
									to={"/bikes/emx"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									EMX
								</Link>{" "}
								<hr className=' ' />
								<Link
									to={"/bikes/t-rex+"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									T-Rex+
								</Link>
								<hr className=' ' />
								<Link
									to={"/bikes/x1"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									X1
								</Link>{" "}
								<hr className=' ' />
								<Link
									to={"/bikes/x2"}
									className='mt-2 fw-bold text-light   text-decoration-none'
									onClick={closeNavforDeadLink}>
									X2
								</Link>
							</div>
						</div>
						<hr className=' mt-4 ' />

						<Link
							to={"/all-accessories"}
							className='  text-light  text-decoration-none'
							onClick={closeNavforDeadLink}>
							Add-on
						</Link>
						<hr className='mt-4 ' />
						<Link
							to={"/blogs"}
							onClick={closeNavforDeadLink}
							className='mt-2 text-light  text-decoration-none'>
							<div className='pe-4'>
								<p className='mb-1 mt-1'>Blogs</p>
							</div>
						</Link>
						<hr className=' mt-4 ' />

						<Link
							to={"/partner-with-us"}
							onClick={closeNavforDeadLink}
							className='mt-2 text-light  text-decoration-none'>
							<div className='pe-4'>
								<p className='mb-1 mt-1'>
									Partner With Us
								</p>
							</div>
						</Link>
						<hr className=' mt-4 ' />
						<div className='pe-4'>
							<div
								className='d-flex justify-content-between align-items-center'
								data-bs-toggle='collapse'
								data-bs-target='#collapseExample1'
								aria-expanded='false'
								aria-controls='collapseExample'>
								<p className='mb-0 mt-1'>
									Assurance
								</p>
								<span
									className='iconify'
									data-icon='ep:arrow-down'
									style={{ color: "white" }}
									data-width='17'
									data-height='17'></span>
							</div>

							<div
								className='collapse border-r-5 mt-4 p-3 '
								style={{
									background: " #1a1a1a",
								}}
								id='collapseExample1'>
								<Link
									to={"/contactus"}
									onClick={closeNavforDeadLink}
									className='mt-2 fw-bold  text-light  text-decoration-none'>
									Contact Us
								</Link>
								<hr className=' ' />
								<Link
									to={"/warranty"}
									onClick={closeNavforDeadLink}
									className='mt-2 fw-bold  text-light text-decoration-none'>
									Warranty
								</Link>
							</div>
						</div>

						<hr className=' mt-4 ' />
					</div>
				</div>
			</div>
		</>
	)
}

export default Navbar
