import React from "react"
import { Link } from "react-router-dom"
import "../CSS/event.css"

const EventXpLandingScreen = () => {
	return (
		<div className='container-fluid p-0'>
			<div className='event-nav p-3  '>
				<Link
					to='/'
					className='text-decoration-none'>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/black+1.png'
						className='img-fluid d-block mx-auto'
						style={{ width: "170px" }}
						alt=''
					/>
				</Link>
			</div>

			<div className='container event-bikes mt-2  pt-lg-2 pb-3 bg-light'>
				<h5 className='mt-lg-2 mt-0 pt-3 pt-lg-1 font-mon'>
					X-Factor
				</h5>
				<div className='row'>
					<div className='col-lg-4 col-12 col-md-6 p-2 pt-0 pt-lg-0'>
						{" "}
						<Link to='/launchbikes/x1'>
							<img
								src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/event-19-01-23/X1.jpg'
								alt=''
								className='img-fluid mt-1 mt-lg-1'
								srcset=''
							/>
						</Link>
					</div>
					<div className='col-lg-4 col-12 col-md-6 p-2 pt-lg-0'>
						<Link to='/launchbikes/x2'>
							<img
								src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/event-19-01-23/X2.jpg'
								alt=''
								className='img-fluid mt-3 mt-lg-1'
								srcset=''
							/>
						</Link>
					</div>

					<div className='col-lg-4 col-12 col-md-6 p-2 pt-lg-0'>
						<Link to='/launchbikes/x3'>
							<img
								src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/event-19-01-23/X3.jpg'
								alt=''
								className='img-fluid mt-3 mt-lg-1'
								srcset=''
							/>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EventXpLandingScreen
