export const ORDER_CREATE_REQUEST =  'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS =  'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL =  'ORDER_CREATE_FAIL'
export const ORDER_CREATE_RESET =  'ORDER_CREATE_RESET'




export const ORDER_CONFIRM_SUCCESS =  'ORDER_CONFIRM_SUCCESS'
export const ORDER_CONFIRM_FAIL =  'ORDER_CONFIRM_FAIL'
export const ORDER_CONFIRM_RESET =  'ORDER_CONFIRM_RESET'



export const ORDER_BY_ID_REQUEST =  'ORDER_BY_ID_REQUEST'
export const ORDER_BY_ID_SUCCESS =  'ORDER_BY_ID_SUCCESS'
export const ORDER_BY_ID_FAIL =  'ORDER_BY_ID_FAIL'

