import React, { useState, useEffect } from "react"
import Navbar from "../Components/Navbar"
import { partnerWithUsCreate } from "../Actions/formActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import Footer from "../Components/Footer"
import { useNavigate } from "react-router-dom"
const PartnerScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] =
		useState("")
	const [businessName, setBusinessName] =
		useState("")
	const [message, setMessage] = useState("")

	const partnerWithUs = useSelector(
		(state) => state.partnerWithUs,
	)
	const { partnerStatus } = partnerWithUs

	useEffect(() => {
		if (partnerStatus) {
			navigate("/submitted")
		}
	}, [partnerStatus])

	const submitForm = (e) => {
		e.preventDefault()
		dispatch(
			partnerWithUsCreate(
				name,
				email,
				phoneNumber,
				businessName,
				message,
			),
		)
	}
	return (
		<>
			<Navbar />
			<div
				className='container-fluid  m-0 pb-0 '
				style={{
					background: "black",
				}}>
				<div className='row p-0 '>
					<div className='col-lg-5 p-0'>
						<img
							src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/Partner-with-us.jpg'
							className='img-fluid'
							alt=''
						/>
					</div>
					<div className='col-lg-7 contactus-main'>
						<div className=' mx-auto mt-lg-5'>
							<h1>Partner With Us</h1>
							<h5 className='subline fw-bold'>
								Let's power the world, together!
							</h5>
							<small className='subline'>
								Partnering with us means joining
								forces with a team of passionate
								and dedicated individuals who are
								committed to making a difference.
								We believe that collaboration and
								mutual support are essential for
								achieving our goals and driving
								growth, with our customer at the
								core of everything we do.
							</small>
							<form
								action=''
								onSubmit={submitForm}>
								<div className='mt-4'>
									<div class='mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Name
										</label>
										<input
											type='text'
											class='form-control '
											style={{ padding: "0.7em" }}
											id='exampleFormControlInput1'
											value={name}
											required
											onChange={(e) =>
												setName(e.target.value)
											}
											placeholder=''
										/>
									</div>
									<div class='mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Email
										</label>
										<input
											type='email'
											class='form-control'
											style={{ padding: "0.7em" }}
											value={email}
											required
											onChange={(e) =>
												setEmail(e.target.value)
											}
											id='exampleFormControlInput1'
											placeholder=''
										/>
									</div>
									<div class=' mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Phone Number
										</label>
										<input
											type='number'
											class='form-control'
											style={{ padding: "0.7em" }}
											id='exampleFormControlInput1'
											value={phoneNumber}
											onChange={(e) =>
												setPhoneNumber(
													e.target.value.slice(
														0,
														11,
													),
												)
											}
											placeholder=''
										/>
									</div>
									<div class='mb-lg-5 mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Company Name
										</label>
										<input
											type='text'
											class='form-control'
											style={{ padding: "0.7em" }}
											id='exampleFormControlInput1'
											value={businessName}
											required
											onChange={(e) =>
												setBusinessName(
													e.target.value,
												)
											}
											placeholder=''
										/>
									</div>
									<div class='mb-3'>
										<label
											for='exampleFormControlTextarea1'
											class='form-label text-light '>
											Message
										</label>
										<textarea
											value={message}
											required
											onChange={(e) =>
												setMessage(e.target.value)
											}
											class='form-control'
											id='exampleFormControlTextarea1'
											rows='4'></textarea>
									</div>

									<button className='btn btn-primary ms-auto d-block fw-bold mt-lg-4 '>
										Send Message
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)
}

export default PartnerScreen
