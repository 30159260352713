import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import "../CSS/event.css"
import { useNavigate } from "react-router-dom"
const Event3DModelScreen = () => {
	const { bike } = useParams()
	const navigate = useNavigate()
	const [model, setModel] = useState(0)

	useEffect(() => {
		if (bike) {
			// console.log(bike)
			if (bikesSpecsData) {
				const onProgress = (event) => {
					const progressBar =
						event.target.querySelector(".load")

					if (event.detail.totalProgress === 1) {
						progressBar.classList.add("hide")
					} else {
						progressBar.classList.remove("hide")
						if (
							event.detail.totalProgress === 0
						) {
							event.target
								.querySelector(
									".center-pre-prompt",
								)
								.classList.add("hide")
						}
					}
				}
				document
					.querySelector("model-viewer")
					.addEventListener(
						"progress",
						onProgress,
					)
			}
		}
	}, [])
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "auto",
		})
	}, [])
	const bikeSpecs = [
		{
			name: "x1",
			title: "EMotorad X1",
			tagline: "Experience the Thrill",
			models: [
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/GLTF_X1/Blue/X1.gltf",
					color:
						"linear-gradient(to bottom left, #437DBA 50%, #383c3e 50%)",
				},
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/GLTF_X1/Yellow/X1.gltf",
					color:
						"linear-gradient(to bottom left, #EED644 50%, #383c3e 50%)",
				},
			],
			specs: [
				{
					title: "Motor",
					value: "36V 250W Rear Hub Motor",
				},
				{
					title: "Suspension",
					value:
						"100 mm Travel Front Fork Suspension",
				},

				{
					title: "Frame",
					value: `18" (45.72cm) MTB High Tensile Steel Frame`,
				},
				{
					title: "Range",
					value:
						"35 to 40 KM on PAS and 30 Km on Throttle",
				},
				{
					title: "Charging Time",
					value: "Upto 80% in 3hr*",
				},
				{
					title: "Drivetrain",
					value: "Single Speed Free Wheel",
				},
				{
					title: "Battery",
					value:
						"36V 7.8Ah Li-Ion Removable Battery",
				},
				{
					title: "Display",
					value:
						"Intuitive Battery Indicator on the Throttle",
				},
				{
					title: "Tyres",
					value: `27.5" x 2.1" (69.85cm x 5.334cm) Hindustan Tyres`,
				},

				{
					title: "Brakes",
					value: `Mechanical Disc Brakes with Auto Cut-off`,
				},
			],
		},

		{
			name: "x2",
			title: "EMotorad X2",
			tagline: "Step in, Ride out",
			models: [
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/GLTF_X2/Blue/X2.gltf",
					color:
						"linear-gradient(to bottom left, #C1DAD9 50%, #3f3f3f 50%)",
				},
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/GLTF_X2/Red/X2.glb",

					color:
						"linear-gradient(to bottom left, #EE4C46 50%, #464646 50%)",
				},
			],
			specs: [
				{
					title: "Motor",
					value: "36V 250W Rear Hub Motor",
				},
				{
					title: "Suspension",
					value:
						"100 mm Travel Front Fork Suspension",
				},

				{
					title: "Frame",
					value: `Unisex Step-through 16" (40.64cm) High Tensile Steel Frame`,
				},
				{
					title: "Range",
					value:
						"35 to 40 KM on PAS and 30 Km on Throttle",
				},
				{
					title: "Charging Time",
					value: "Upto 80% in 3hr*",
				},
				{
					title: "Drivetrain",
					value: "Single Speed Free Wheel",
				},
				{
					title: "Battery",
					value:
						"36V 7.8Ah Li-Ion Removable Battery",
				},
				{
					title: "Display",
					value: "P9 LCD display",
				},
				{
					title: "Tyres",
					value: `27.5" x 2.1 Inches (69.85cm x 5.334cm) Hindustan Tyres`,
				},

				{
					title: "Brakes",
					value: `Mechanical Disc Brakes with Auto Cut-off`,
				},
			],
		},

		{
			name: "x3",
			title: "EMotorad X3",
			tagline: "More Power, More Adventure",
			models: [
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/GLTF_X3/Gold/X3_01.gltf",
					color:
						"linear-gradient(to bottom left, #D7D5CD 50%, #181818 50%)",
				},
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/GLTF_X3/Silver/X3_02.gltf",
					color:
						"linear-gradient(to bottom left, #DADADA 50%, #181818 50%)",
				},
			],
			specs: [
				{
					title: "Motor",
					value: "36V 250W Rear Hub Motor",
				},
				{
					title: "Suspension",
					value:
						"60 mm Travel Front Fork Suspension",
				},

				{
					title: "Frame",
					value: `18" (45.72cm) MTB High Tensile Steel Frame`,
				},
				{
					title: "Range",
					value:
						"35 to 40 KM on PAS and 30 Km on Throttle",
				},
				{
					title: "Charging Time",
					value: "Upto 80% in 3hr*",
				},
				{
					title: "Drivetrain",
					value: "Single Speed Free Wheel",
				},
				{
					title: "Battery",
					value:
						"36V 7.0Ah Li-Ion Frame Integrated Battery",
				},
				{
					title: "Display",
					value: "Colour-Rich LCD Display",
				},
				{
					title: "Tyres",
					value: `700X45C Hartex Grit Gravel Tyre with 3mm Puncture Resistance`,
				},

				{
					title: "Brakes",
					value: `Mechanical Disc Brakes with Auto Cut-off`,
				},
			],
		},
		{
			name: "night-hawk",
			title: "Night Hawk",
			tagline: "Over-ride the Normal",
			models: [
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/M058-Grey/M058+Grey.gltf",
					color:
						"linear-gradient(to bottom left, #68AEB1 50%, #68AEB1 50%)",
				},
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/M058-Blue/M058.gltf",
					color:
						"linear-gradient(to bottom left, #1030AF 50%, #1030AF 50%)",
				},
			],

			specs: [
				{
					title: "Motor",
					value: "Bafang 250W Mid Drive Motor",
				},
				{
					title: "Suspension",
					value:
						"SRAM Rockshox 150 mm travel Front Fork Suspension",
				},
				{
					title: "Rear Suspension",
					value: "Rockshox Delux Select 135*55mm",
				},
				{
					title: "Frame",
					value: "Aluminium Alloy 6061 MTB Frame",
				},

				{
					title: "Derailleur",
					value:
						"SRAM X5 RD-X5-A1 With Exact Actuation Shifting",
				},
				{
					title: "Battery",
					value:
						"36V 17.5Ah Li-Ion Frame Integrated Battery",
				},
				{
					title: "Display",
					value:
						"Throttle Integrated Bafang LCD Display",
				},
				{
					title: "Tyres",
					value: `29" Maxxis Minion DHF Exo Protection Tubeless Tyres`,
				},

				{
					title: "Brakes",
					value: `Tektro Disc Brakes`,
				},
				{
					title: "Seat Post",
					value: `EXAForm Dropper Seat Post`,
				},
			],
		},
		{
			name: "desert-eagle",
			title: "Desert Eagle",
			tagline: "Uncap the Power",
			models: [
				{
					url: "https://ar-euro.s3.ap-south-1.amazonaws.com/webite_Models/M057/Elite+M057.gltf",
					color:
						"linear-gradient(to bottom left, #3D3D3D 50%, #E8DC66 50%)",
				},
			],
			specs: [
				{
					title: "Motor",
					value: "Bafang  250W  Mid Drive Motor",
				},
				{
					title: "Suspension",
					value:
						"Rockshox Bluto 120 mm travel Front Fork Suspension",
				},
				{
					title: "Rear Suspension",
					value: "Rockshox Delux Select 135*55mm",
				},
				{
					title: "Frame",
					value: "Aluminium Alloy 6061 MTB Frame",
				},

				{
					title: "Derailleur",
					value:
						"SRAM X5 RD-X5-A1 With Exact Actuation Shifting",
				},
				{
					title: "Battery",
					value:
						"36V 17.5Ah Li-Ion Frame Integrated Battery",
				},
				{
					title: "Display",
					value:
						"Throttle Integrated Bafang LCD Display",
				},
				{
					title: "Tyres",
					value: `Kenda Juggernaut 30 TPI with Punture Resistant Tire Casing `,
				},

				{
					title: "Brakes",
					value: `Tektro Disc Brakes`,
				},
				{
					title: "Seat Post",
					value: `EXAForm Dropper Seat Post`,
				},
			],
		},
	]
	const bikesSpecsData = bikeSpecs.find(
		(b) => b.name === bike,
	)
	return (
		<>
			{bikesSpecsData && (
				<div className='container-fluid pb-0 '>
					<div className='row'>
						<div className='event-nav  align-items-center d-flex col-12 p-3 d-lg-none  '>
							<div className='col-3'>
								<i
									onClick={() => navigate(-1)}
									style={{ fontSize: "1.5em" }}
									className='fa-solid text-muted fa-arrow-left   '></i>
							</div>
							<Link
								to='/'
								className=' col-6 text-decoration-none'>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/black+1.png'
									className='img-fluid d-block mx-auto'
									style={{ width: "170px" }}
									alt=''
								/>
							</Link>
							<div className='col-3'></div>
						</div>
						<div className='col-12 p-0 col-md-8 event-3d-model'>
							<model-viewer
								style={{
									background:
										"linear-gradient(to bottom left, #a4b9ba 0%, #6c8182 100%)",
								}}
								class='w-100 position-relative '
								id='model-hotspot-camera-view-demo'
								bounds='tight'
								// disable-zoom
								enable-pan
								// auto-rotate

								min-camera-orbit='auto 0deg auto'
								max-camera-orbit='auto 90deg auto'
								max-field-of-view={"24deg"}
								min-field-of-view={"24deg"}
								src={
									bikesSpecsData.models[model].url
								}
								ar
								exposure={1}
								interaction-prompt={`auto`}
								ar-placement='floor'
								render-scale='1'
								ar-scale='auto'
								ar-modes='webxr'
								camera-controls
								camera-orbit='-10deg 85deg   '
								environment-image='neutral'
								shadow-intensity='2'>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/black+1.png'
									className='position-absolute d-none d-lg-block'
									style={{
										width: "170px",
										top: "2%",
										left: "2%",
									}}
									alt=''
								/>
								<div
									style={{
										zIndex: 3,
									}}
									className='load position-relative h-100 w-100 bg-em'>
									<div className='main-load'>
										<span class='event-loader'></span>
									</div>
								</div>
								<div id='ar-prompt'></div>
							</model-viewer>
						</div>

						<div className='col-12 position-relative bg-light p-0 col-md-4'>
							<hr
								style={{ width: "90%" }}
								className='  d-block mx-auto d-lg-none'
							/>
							<div className='p-3 p-lg-5 pt-1'>
								<h3 className='font-mon'>
									{" "}
									{bikesSpecsData.title}
								</h3>
								<h6 className='text-muted fw-bold'>
									{bikesSpecsData.tagline}
								</h6>
								<div className='colors'>
									<p className='mb-1'>Colors</p>
									<div
										className='d-flex'
										style={{ gap: "1em" }}>
										{bikesSpecsData.models.map(
											(item, i) => (
												<div
													className={`color d-flex ${
														i === model &&
														" event-active-color"
													}  align-items-center`}>
													<div
														onClick={() =>
															setModel(i)
														}
														className='color-item'
														style={{
															background: `${item.color}`,
														}}></div>
												</div>
											),
										)}
									</div>
								</div>
								<div className='row mb-0 pb-0  mt-5 specs-table'>
									<h3
										style={{ color: "#30403f" }}
										className='col-12 mb-3 font-mon pb-1 '>
										SPECIFICATIONS
									</h3>
									{bikesSpecsData.specs.map(
										(item) => (
											<div className='col-6 h-100 mt-lg-5'>
												<h6>{item.title}</h6>
												<p>{item.value}</p>
											</div>
										),
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default Event3DModelScreen
