import axios from 'axios'
import {
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CART_SAVE_SHIPPING_ADDRESS,
	CHECK_PROMOCODE_FAIL,
	CHECK_PROMOCODE_REQUEST,
	CHECK_PROMOCODE_SUCCESS,
} from '../Constants/cartConstants'

export const addToCart =
	(id, color, qty, variantType) => async (dispatch, getState) => {
		const { data } = await axios.post(`/api/bikes/`, { id })

		dispatch({
			type: CART_ADD_ITEM,
			payload: {
				product: data._id,
				name: data.name,
				cartImages: data.cartImages,
				price: data.price,
				gst: data.gst,
				withoutGST: data.withoutGST,
				models: data.models,
				tagLine: data.productType,
				slashPrice: data.slashPrice,
				category: data.category,
				accPrice: data.accPrice,
				color,
				qty,
				variant: data.variant,
				variantType,
			},
		})

		localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
	}

export const removeFromCart = (id) => (dispatch, getState) => {
	dispatch({
		type: CART_REMOVE_ITEM,
		payload: id,
	})

	localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
	dispatch({
		type: CART_SAVE_SHIPPING_ADDRESS,
		payload: data,
	})
	localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const getPromocodes = (promocode) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		dispatch({ type: CHECK_PROMOCODE_REQUEST })
		const { data } = await axios.post(
			'/api/order/promocode',
			{
				promocode,
			},
			config
		)

		dispatch({
			type: CHECK_PROMOCODE_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: CHECK_PROMOCODE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
