import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBikeByName } from "../Actions/bikeActions.js";
import { useParams, useLocation, Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { addToCart } from "../Actions/cartActions";
import Navbar from "../Components/Navbar.js";
import { useNavigate } from "react-router-dom";
import Meta from "../Components/Meta";
import Footer from "../Components/Footer.js";
import "../CSS/product.css";
import "../CSS/mobproduct.css";
import ImageGallery from "react-image-gallery";

const ProductScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useParams();
  const [Collapse, setCollapse] = useState(false);
  const [variant, setVariant] = useState(0);
  const ind = useRef(0);

  const bikeStatsGallery = useRef(0);

  const [showModel, setShowModel] = useState(false);

  const [fullScreenClose, setfullScreenClose] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bikeByName = useSelector((state) => state.bikeByName);
  const { bike: bikeInfo } = bikeByName;

  const [model, setModel] = useState(0);

  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (name) {
      dispatch(getBikeByName(name));
      setImageIndex(0);
      setShowModel(false);
      if (bikeInfo) {
        if (bikeInfo.models.length > params.get("color")) {
          setModel(Number(params.get("color")));
        }
      }
    }
  }, [dispatch, name, userInfo]);

  const currencyFormat = (num) => {
    return "$" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const addCart = () => {
    dispatch(addToCart(bikeInfo._id, model, 1, variant));
    navigate("/cart");
  };

  const bikeHighlights = [
    {
      name: "doodle",

      mainHeading:
        "From highways to biking trails, riding is effortless with EMX, Whether you are commuting to the office, exploring local streets or climbing the hills.",
      poster1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/Product-Banner-3.jpg",

      manual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/user+manuals/doodle+User+Manual+V2(S).pdf",
      tagline: "Glide through the distance",
      writeup:
        "No sweat, no stress, no limits. Doodle has a smart pedal assist, a foldable aluminium frame, and a 36V 10.4Ah removable battery that keeps you going.",
      productImages: [
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/doodle/Doodle_Chistmas_Catalog.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/white/ALI01818.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/white/ALI01856.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/white/ALI01858.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/white/ALI01818_Ceat.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/white/ALI01872.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/white/ALI01818_FrontTyre.jpg",
          },
        ],
      ],
      lifestyleImg1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/lifestyle/LifeStyle_C4.jpg",
      lifestyleImg2:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/lifestyle/LifeStyle_C1.jpg",
      lifestyleImg3:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/lifestyle/LifeStyle_C2.jpg",
      lifestyleImg4:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/doodle/lifestyle/LifeStyle_C3.jpg",
      lifeStyleHeading: "The e-bike that loves to travel",
      lifeStyleSubHeading1: "Fits your space and your lifestyle",

      lifeStyleSubHeading2: "Enjoy the smoothest ride",
      lifeStyleSubHeading3: "Bring joy to every ride",
      lifeStyleSubHeading4: "Guiding you home",
      lifeStyleSubDesc1:
        "The folding e-bike frame combines style and function. It sports a frame-integrated 10.4Ah battery with quick release making it dust and water resistant and easy to charge on the go.",
      lifeStyleSubDesc2:
        "Fat 20”x4” tyres and a wide double-stitched PU hydrodyne soft cushion saddle make cruising on any terrain a delight.",
      lifeStyleSubDesc3:
        "The high-torque rear hub motor always delivers efficient performance. Electrify your weekends with a silent yet powerful 250W motor.",

      lifeStyleSubDesc4:
        "The integrated LED headlight and rear light comes with high luminosity and a wide beam, giving you the confidence to ride at night.",
      reviews: [
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/reviews/Luca.R.jpg",
          text: "I love my foldable Doodle! It’s so easy to store and carry around. I use it for commuting, camping and exploring new places. It’s the best investment I ever made.",
        },
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/reviews/Martin.S.jpg",
          text: "Doodle is a game-changer for me. I can ride it on the beach, on the trails and on the road. It’s super comfortable and stable, and it folds up in a few. It’s perfect for travelling and adventure.",
        },
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/product/reviews/Maria.G.jpg",
          text: "I’m so happy with my Doodle ebike. It’s a great way to get some exercise and enjoy nature.. It’s very durable and it fits in my car trunk easily. I can take it anywhere I want without hassle.",
        },
      ],
    },
    {
      name: "beach-rider",

      mainHeading:
        "From highways to biking trails, riding is effortless with EMX, Whether you are commuting to the office, exploring local streets or climbing the hills.",
      poster1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/lifestyle/Product_REV-Banner-3+(1).jpg",

      tagline: "Go with the flow",
      writeup:
        "The Aluminium Alloy 6061 folding frame and a portable 10Ah li-ion battery make travel worry-free.",
      productImages: [
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/Full_Bike_Beach+Rider_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/Australia_BeachRider_FullBike.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/Handle.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/Light.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/Seat.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/Australia_Front+Tyre.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/Australia_Back+Tyre.jpg",
          },
        ],
      ],
      lifestyleImg1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/lifestyle/BeachRider_LifeStyle_4.jpg",
      lifestyleImg2:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/lifestyle/BeachRider_LifeStyle_1.jpg",
      lifestyleImg3:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/lifestyle/BeachRider_LifeStyle_2.jpg",
      lifestyleImg4:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/lifestyle/BeachRider_LifeStyle_3.jpg",
      lifeStyleHeading: "Sail through the streets",
      lifeStyleSubHeading1: "The perfect frame for adventure",
      lifeStyleSubHeading2: "Weather? Check ",
      lifeStyleSubHeading3: "Instant Assistance ",
      lifeStyleSubHeading4: "The Powerhouse",
      lifeStyleSubDesc1:
        "The smart choice for eco-friendly riders, it can fold up for easy transport, so you can pack it, store it and take it for a ride wherever you want. ",
      lifeStyleSubDesc2:
        "The 20”X4” fat tyres make the ultimate tyres for an adventurer with temperature and puncture resistance, shock absorption and traction enhancement",
      lifeStyleSubDesc3:
        "The 5-level pedal assist mode in Beach Rider is designed to give you instant & smooth power for the best riding experience.",
      lifeStyleSubDesc4:
        "The 250W motor on Beach Rider is a powerful and efficient device that enhances your riding performance.",
      reviews: [
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/reviews/02_review.jpg",
          text: "Woo hoo we went and got our new wheels yesterday!! Craigs very cleaver as he had to modify the pups seats! Loving riding these bikes that make it so easy. Pups love there new seats!",
        },
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/reviews/04_review.jpg",
          text: "This is the ultimate beach cruiser! The fat tires make it easy to ride on sand and the foldable design makes it easy to store in my beach house.",
        },

        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/reviews/07_review.jpg",
          text: "The fat tires make it a smooth ride on any surface and the foldable design makes it easy to store in my RV.",
        },
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/reviews/08_review.jpg",
          text: "Ebikes are perfect for exploring the great outdoors. I take it on trails and off-road adventures and it never disappoints. ",
        },
      ],
    },
    {
      name: "monster",

      mainHeading:
        "From highways to biking trails, riding is effortless with EMX, Whether you are commuting to the office, exploring local streets or climbing the hills.",
      poster1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/lifestyle/monster-poster-by-anup-kumar.jpg",

      tagline: "Power Amplified",
      writeup:
        "The Aluminium Alloy 6061 folding frame and a portable 10 Ah li-ion battery makes travel worry-free.",
      productImages: [
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/Full_Bike_Monster_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/Full+Bike.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/Handle.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/Light.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/Seat.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/Front+Tyre.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/Back+Tyre.jpg",
          },
        ],
      ],
      lifestyleImg1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/lifestyle/Moster_LifeStyle_3.jpg",
      lifestyleImg2:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/lifestyle/Moster_LifeStyle_4.jpg",
      lifestyleImg3:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/lifestyle/Moster_LifeStyle_1.jpg",
      lifestyleImg4:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Monster/lifestyle/Moster_LifeStyle_2.jpg",
      lifeStyleHeading: "Glide through the trails",
      lifeStyleSubHeading1: "The bike that keeps giving",
      lifeStyleSubHeading2: " Unfold a whole new world ",
      lifeStyleSubHeading3: " Driven to feel good",
      lifeStyleSubHeading4: "Fun compact Sized",
      lifeStyleSubDesc1:
        "With a quick responsive front fork alloy suspension, ride comfortably even over bumps.",
      lifeStyleSubDesc2:
        "The high-impact Aluminium Alloy folding frame is lightweight and durable, making it ideal to fit your lifestyle.  ",

      lifeStyleSubDesc3:
        "The 26”x4” Fat tyres are the ultimate choice for adventurous rides on snow, sand, mud and gravel.",
      lifeStyleSubDesc4:
        "The folding e-bike frame combines form and function with style. Pop it in the trunk and unfold when in the mood for an exciting ride.",
      reviews: [
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/beachRider/reviews/06_review.jpg",
          text: "The Monster foldable eBike is perfect for outdoor enthusiasts like me. I love taking it on long rides.",
        },
      ],
    },
    {
      name: "thunder",

      mainHeading:
        "From highways to biking trails, riding is effortless with EMX, Whether you are commuting to the office, exploring local streets or climbing the hills.",
      poster1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/lifestyle/thuder-poster.jpg",

      tagline: "Ride on the edge ",
      writeup:
        "Zip zap around the city with Thunder. It’s got a punchy 36V 250W motor, a durable 36V 13Ah battery, and a chic aluminium alloy folding frame.",
      productImages: [
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/Full_Bike_Thunder_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/Full_Bike.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/Handle.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/Light.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/Seat.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/Front_Tyre.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/Back_Tyre.jpg",
          },
        ],
      ],
      lifestyleImg1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/lifestyle/Thunfder_LifeStyle_1.jpg",
      lifestyleImg2:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/lifestyle/Thunfder_LifeStyle_3.jpg",
      lifestyleImg3:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/lifestyle/Thunfder_LifeStyle_4.jpg",
      lifestyleImg4:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/lifestyle/Thunfder_LifeStyle_2.jpg",
      lifeStyleHeading: "The high voltage thrill  ",
      lifeStyleSubHeading1: "Power up your Adventures",
      lifeStyleSubHeading4: "Go wherever you go",
      lifeStyleSubHeading3: "Ready for anything",
      lifeStyleSubHeading2: "Explore all the more",
      lifeStyleSubDesc1:
        "The high torque 36V 250W rear hub motor exceeds expectations in all regards. Thunder is a powerful MTB electric bike with a performance to match.",
      lifeStyleSubDesc4:
        "The portable and lightweight aluminium alloy 6061 frame is here to make life easier. Pop it into the trunk or store it away.",
      lifeStyleSubDesc3:
        "It's hard not to smile while cruising on 26”x”2.10 all-terrain tyres coupled with a dual disc brake set-up for better control on wet and dry surfaces.",
      lifeStyleSubDesc2:
        "The 36V 13Ah Lithium-ion quick-release battery allows for complete control and flexibility in travel with up to 60 km* pedal assist range.",
      reviews: [
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/reviews/09_review.jpg",
          text: "I ride to my office. The electric assist makes it easy to tackle hills and the fat tires provide extra grip on wet pavement. And when I'm done, I can easily fold it up and store it in my office.",
        },
        {
          img: "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/Thunder/reviews/10_review.jpg",
          text: " I couldn't be happier with my purchase. ",
        },
      ],
    },
    {
      name: "emx",

      mainHeading:
        "From highways to biking trails, riding is effortless with EMX, Whether you are commuting to the office, exploring local streets or climbing the hills.",
      poster1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/lifestyle/Banner+image.jpg",
      manual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/user+manuals/emx+USER+MANUAL(S).pdf",

      tagline: "Designed for all terrains",
      writeup:
        "The dual suspension frame with a compact high torque 250W hub motor for rugged performance and a smooth ride even at high speed.",
      productImages: [
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/orange/Full_Bike_EMX_Orange_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/orange/full+bike.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/orange/light+and+suspension.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/orange/motor.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/orange/seat.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/orange/suspension.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/orange/disk+break.jpg",
          },
        ],
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/green/Full_Bike_EMX_Green_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/green/full+bike.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/green/light+and+suspension.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/green/motor.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/green/seat.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/green/suspension.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/green/disk+break.jpg",
          },
        ],
      ],
      lifestyleImg1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/lifestyle/1.jpg",
      lifestyleImg2:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/lifestyle/3.jpg",
      lifestyleImg3:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/lifestyle/4.jpg",
      lifestyleImg4:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/emx/lifestyle/2.jpg",
      lifeStyleHeading: "Rugged, zippy performance",
      lifeStyleSubHeading1: "Light Compact Power ",
      lifeStyleSubHeading2: "Dual Action Dexterity",
      lifeStyleSubHeading3: "Smooth Operator",
      lifeStyleSubHeading4: "The Captain’s Helm",
      lifeStyleSubDesc1:
        "The 250W rear hub motor is a lightweight, high torque unit that delivers efficient performance no matter where you are.",
      lifeStyleSubDesc2:
        "The Horst-link swing arm dual suspension frame makes for smooth performance with its adjustable suspension aiding superior control and traction.",
      lifeStyleSubDesc3:
        "21-speed Shimano derailleurs allow a seamless transition from city streets to adventurous terrain. Stay on pace no matter where you are riding.",
      lifeStyleSubDesc4:
        "The M5 Multifunctional LCD display has an easy-to-read panel with a speedometer, pedal assist gauge and so much more.",
    },
    {
      name: "t-rex+",

      mainHeading:
        "From highways to biking trails, riding is effortless with EMX, Whether you are commuting to the office, exploring local streets or climbing the hills.",
      poster1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/lifestyle/banner+image.jpg",
      manual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/user+manuals/TREX%2B+USER+MANUAL(S).pdf",

      tagline: "Beast that never stops",
      writeup:
        "The T-Rex+ hardtail MTB ebike - with a 250W rear hub motor & removable 13Ah battery. Lord of every terrain.",
      productImages: [
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/Full_Bike_T-Rex%2B_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/full+bike+copy.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/battery.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/disk+break.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/handle.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/seat.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/motor.jpg",
          },
        ],
      ],
      lifestyleImg1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/lifestyle/3.jpg",
      lifestyleImg2:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/lifestyle/2.jpg",
      lifestyleImg3:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/lifestyle/1.jpg",
      lifestyleImg4:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/trex/lifestyle/4.jpg",
      lifeStyleHeading: "The Hardtail Adrenaline Source ",
      lifeStyleSubHeading1: "A Compact Powerhouse",
      lifeStyleSubHeading2: "Doubly Secured ",
      lifeStyleSubHeading3: "Seamless Shifting",
      lifeStyleSubHeading4: "The Bigger, Better Beast",
      lifeStyleSubDesc1:
        "The compact 250W rear hub motor powers this beast to take on energetic urban roads and adventurous terrains, that too, with relative ease.",
      lifeStyleSubDesc2:
        "Enjoy better stopping power with a dual disc setup allowing for better control on wet and dry surfaces.",
      lifeStyleSubDesc3:
        "Navigate rocky terrain or tough climbs with a 7-speed Shimano derailleur that delivers torque from a wide range of gearing.",
      lifeStyleSubDesc4:
        "T-Rex+. All the good stuff. Only bigger and better. A power-packed 36V 13Ah Li-Ion battery for a greater range.",
    },
    {
      name: "x1",
      ratings: "1357",
      mainHeading:
        "From highways to biking trails, riding is effortless with EMX, Whether you are commuting to the office, exploring local streets or climbing the hills.",
      poster1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/lifestyle/X1+Always+on+the+ready.jpg",
      tagline: " Always on the ready",
      writeup:
        "The X1 launches the rider into an adrenaline zone thanks to its agile high-tensile frame, the power of its lightweight 250W rear hub motor and the range afforded by its 7.65Ah removable Li-ion battery",

      productImages: [
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/product/Full_Bike_X1_Blue_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/product/X1+(White+BG)+Full+Bike.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/product/X1+(White+BG)+Pedal+Assist.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/product/X1+(White+BG)+Battery.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/product/X1+(White+BG)+Handle.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/product/X1+(White+BG)+Disc+Break.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/product/X1+(White+BG)+SeatPost.jpg",
          },
        ],
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/yellow/Full_Bike_X1_Yellow_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/yellow/X1+Yellow+Full+Bike.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/yellow/X1+Yellow+Pedal+Assist.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/yellow/X1+Yellow+Battery.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/yellow/X1+Yellow+Handle.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/yellow/X1+Yellow+Disc+Break.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/yellow/X1+Yellow+SeatPost.jpg",
          },
        ],
      ],
      lifestyleImg1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/product/x1/lifestyle/LifeStyle_X1_1.jpg",
      lifestyleImg2:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/lifestyle/X1+Unlock+a+new+experience+(1).jpg",
      lifestyleImg3:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/lifestyle/X1+Unlock+a+new+experience+(2).jpg",
      lifestyleImg4:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x1/lifestyle/X1+Unlock+a+new+experience+(3).jpg",
      lifeStyleHeading: "Unlock a new experience ",
      lifeStyleSubHeading1: "Activate Energy",
      lifeStyleSubHeading2: "Accentuate Comfort",
      lifeStyleSubHeading3: "Agility and Control",
      lifeStyleSubHeading4: "Adventure friendly",
      lifeStyleSubDesc1:
        "A high torque 250W EMotorad motor combined with the removable 36V 7.65Ah battery system provides powerful pedal assist, making the riding experience effortlessly dynamic",
      lifeStyleSubDesc2:
        "Ergonomic geometry combined with a high tensile stainless steel frame, and trail-tuned front suspension makes it as comfortable as it is attractive",
      lifeStyleSubDesc3:
        "The 27.5” all-terrain tyres provide ample grip on roads, gravel and loose dirt. Designed to take on both heavy-duty riding and light commute duties with equal ease",
      lifeStyleSubDesc4:
        "The sturdy stainless steel frame easily is designed with the geometry to take on any trail. The X1 is the go-to choice to make your commute come alive",

      reviews: [],
    },
    {
      name: "x2",
      ratings: "1457",
      mainHeading:
        "From highways to biking trails, riding is effortless with EMX, Whether you are commuting to the office, exploring local streets or climbing the hills.",
      poster1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/lifestyle/X2+Always+on+the+ready.jpg",
      tagline: "Escape the Cityscape",
      writeup:
        "The X2 makes city cruising and skipping traffic look easy. Its instant acceleration and effortless handling with a step-through frame is just the right fit for the city. ",

      productImages: [
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/product/Full_Bike_X2_Red_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/product/X2+(White+BG)+Full+Bike.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/product/X2+(White+BG)+Pedal+Assist.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/product/X2+(White+BG)+Battery.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/product/X2+(White+BG)+Display.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/product/X2+(White+BG)+Disc+Break.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/product/X2+(White+BG)+Suspension.jpg",
          },
        ],
        [
          // {
          //   original:
          //     "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/blue/Full_Bike_X2_Blue_Christmas.jpg",
          // },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/blue/X2+Blue+Full+Bike.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/blue/X2+Blue+Pedal+Assist.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/blue/X2+Blue+Battery.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/blue/X2+Blue+Display.jpg",
          },

          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/blue/X2+Blue+Disc+Break.jpg",
          },
          {
            original:
              "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/blue/X2+Blue+Suspension.jpg",
          },
        ],
      ],
      lifestyleImg1:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/lifestyle/X2+Unlock+a+new+experience+(2).jpg",
      lifestyleImg2:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/lifestyle/X2+Unlock+a+new+experience+(1).jpg",
      lifestyleImg3:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/lifestyle/X2+Unlock+a+new+experience+(3).jpg",
      lifestyleImg4:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/product/x2/lifestyle/X2+Unlock+a+new+experience+(3).jpg",
      lifeStyleHeading: "Zip Zap through the city  ",
      lifeStyleSubHeading1: "Extreme Versatility",
      lifeStyleSubHeading2: "Smart Performance",
      lifeStyleSubHeading3: "Effortless handling",
      lifeStyleSubHeading4: "Constant Comfort",
      lifeStyleSubDesc1:
        "The high tensile step-through frame and adjustable telescopic seat of the X2 ensure ultimate comfort even on long rides. This makes it the most popular choice for riders of all types and ages",
      lifeStyleSubDesc2:
        "A 36V 250W Motor and ultra capacious 7.65Ah Battery power the electric assist feature, letting users experience a truly premium and effortless ride",
      lifeStyleSubDesc3:
        "The X2 sports a dual disc brake setup with auto cut-off, which means braking is a smooth and effortless affair!    ",
      lifeStyleSubDesc4:
        "The X2’s front suspension, with 100 mm travel, dispatches uneven city roads and off-road trails with utmost ease",

      reviews: [],
    },
  ];

  const bikeSpecs = [
    {
      name: "doodle",
      boxBrakes: "Dual Disc Brakes",
      boxRange: "50 km on PAS* ",
      boxBattery: "36V 10.4Ah Removable",
    },
    {
      name: "beach-rider",
      boxBrakes: "Dual Disc Brakes",
      boxRange: "40 km+ on PAS*",
      boxBattery: "36V 10Ah Removable",
    },
    {
      name: "monster",
      boxBrakes: "Dual Disc Brakes",
      boxRange: "50 km on PAS*",
      boxBattery: "36V 10Ah Removable",
    },
    {
      name: "thunder",
      boxBrakes: "Dual Disc Brakes",
      boxRange: "50 km on PAS* (10Ah)",
      boxBattery: "36V 10Ah Removable",
    },
    {
      name: "emx",
      boxBrakes: "Dual Disc Brakes",
      boxRange: "70 km on PAS",
      boxBattery: "36V 13Ah Removable",
    },
    {
      name: "t-rex+",
      boxBrakes: "Dual Disc Brakes",
      boxRange: "70 km on PAS",
      boxBattery: "36V 13Ah Removable",
    },
    {
      name: "x1",
      boxBrakes: "Dual Disc Brakes",
      boxRange: "40+ km on PAS",
      boxBattery: "7.65Ah Removable",
    },
    {
      name: "x2",
      boxBrakes: "Dual Disc Brakes",
      boxRange: "40+ km on PAS",
      boxBattery: "7.65Ah Removable",
    },
  ];

  const tableSpecsData = bikeSpecs.find((b) => b.name === name);
  const bikeHighlightsData = bikeHighlights.find((b) => b.name === name);

  return (
    <>
      <Navbar />
      {bikeInfo && (
        <>
          <div className="container mt-lg-5 mt-1 mb-5">
            <div className="row">
              <div className="col-lg-8 p-0  ">
                <div className="position-relative bg-light p-lg-0 p-2">
                  <ImageGallery
                    items={bikeHighlightsData.productImages[model]}
                    ref={ind}
                    onClick={() => {
                      if (fullScreenClose) {
                        ind.current.exitFullScreen();
                        setfullScreenClose(false);
                      } else {
                        ind.current.fullScreen();
                        setfullScreenClose(true);
                      }
                    }}
                    useBrowserFullscreen={false}
                    className="img-fluid d-flex align-items-center"
                    showPlayButton={false}
                    showThumbnails={false}
                    additionalClass="img-fluid "
                    renderLeftNav={(onClick, disabled) => (
                      <iconify-icon
                        icon="ic:baseline-keyboard-arrow-left"
                        style={{
                          color: "white",
                          zIndex: "3",
                          position: "absolute",
                          top: "47%",
                          left: "4%",
                        }}
                        onClick={onClick}
                        disabled={disabled}
                        width="50"
                      ></iconify-icon>
                    )}
                    renderRightNav={(onClick, disabled) => (
                      <iconify-icon
                        icon="ic:baseline-keyboard-arrow-right"
                        style={{
                          color: "white",
                          zIndex: "3",
                          position: "absolute",
                          top: "47%",
                          right: "4%",
                        }}
                        onClick={onClick}
                        disabled={disabled}
                        width="50"
                      ></iconify-icon>
                    )}
                  />
                </div>
                <div
                  className="d-flex p-2 p-md-2 mt-lg-2 justify-content-center"
                  style={{ gap: "0.5em" }}
                >
                  {bikeHighlightsData &&
                    bikeHighlightsData.productImages[model].map((image, i) => (
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          ind.current.slideToIndex(i);
                        }}
                        className="p-2  pt-0 pb-0   ps-0 pe-0"
                      >
                        <div className=" ">
                          <img
                            className="img-fluid   "
                            src={image.original}
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>{" "}
              <div className="col-lg-4">
                <div className="bike-info ps-lg-5">
                  <h1 className="fw-bold mt-4 mt-lg-0">{bikeInfo.name}</h1>

                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "0.3em" }}
                  >
                    <i
                      class="fa-solid text-light  fa-star "
                      style={{
                        background: "#10b068",
                        fontSize: "0.7em",
                        padding: "0.5em",
                        paddingLeft: "0.4em",
                        paddingtop: "0.0em",
                        paddingBottom: "0.5em",

                        paddingRight: "0.4em",
                      }}
                    ></i>
                    <i
                      class="fa-solid text-light  fa-star "
                      style={{
                        background: "#10b068",
                        fontSize: "0.7em",
                        padding: "0.5em",
                        paddingLeft: "0.4em",
                        paddingtop: "0.0em",
                        paddingBottom: "0.5em",

                        paddingRight: "0.4em",
                      }}
                    ></i>
                    <i
                      class="fa-solid text-light  fa-star "
                      style={{
                        background: "#10b068",
                        fontSize: "0.7em",
                        padding: "0.5em",
                        paddingLeft: "0.4em",
                        paddingtop: "0.0em",
                        paddingBottom: "0.5em",

                        paddingRight: "0.4em",
                      }}
                    ></i>
                    <i
                      class="fa-solid text-light  fa-star "
                      style={{
                        background: "#10b068",
                        fontSize: "0.7em",
                        padding: "0.5em",
                        paddingLeft: "0.4em",
                        paddingtop: "0.0em",
                        paddingBottom: "0.5em",

                        paddingRight: "0.4em",
                      }}
                    ></i>
                    <i
                      class="fa-solid text-light  fa-star "
                      style={{
                        background: "#10b068",
                        fontSize: "0.7em",
                        padding: "0.5em",
                        paddingLeft: "0.4em",
                        paddingtop: "0.0em",
                        paddingBottom: "0.5em",

                        paddingRight: "0.4em",
                      }}
                    ></i>
                  </div>
                  <p className="mt-3 bike-desc text-muted">
                    {bikeInfo.description}
                  </p>
                  <div className="d-flex mt-lg-4" style={{ gap: "2em" }}>
                    <p className="bike-price">
                      {currencyFormat(bikeInfo.price)}
                    </p>
                    <p className="slash-bike-price">
                      {currencyFormat(bikeInfo.slashPrice)}
                    </p>
                  </div>

                  <small className="including-gst">Including GST</small>
                  <div className="bike-colors mt-4 mt-lg-4">
                    <p className="mb-2 mb-lg-3">
                      Colour - {bikeInfo.models[model].colorName}
                    </p>
                  </div>

                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "1.5em" }}
                  >
                    {bikeInfo.models.map((color, i) => (
                      <div className={` ${i === model && "active-color"}`}>
                        <div
                          className="bike-color"
                          onClick={() => setModel(i)}
                          style={{
                            background: color.colorCode,
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>

                  {bikeInfo.variant && bikeInfo.variant.length > 0 && (
                    <>
                      <div className="bike-colors mt-4 ">
                        <p className="mb-2 mb-lg-3">
                          {bikeInfo.variant[0].variantType} -{" "}
                          {bikeInfo.variant[variant].variantName}
                        </p>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{
                          gap: "1.2em",
                        }}
                      >
                        {bikeInfo.variant.map((item, i) => (
                          <div
                            className={`w-100 p-1 ${
                              i === variant && "variant-border"
                            }`}
                          >
                            <div
                              style={{
                                background: "#F2F5F5",
                                borderRadius: "5px",
                              }}
                              className={` pt-3 pb-3 w-100 text-center p-2  ${
                                i === variant && "bg-primary text-light fw-bold"
                              }`}
                              onClick={() => setVariant(i)}
                            >
                              {item.variantName}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  <div className="mt-4 d-none d-md-block">
                    {bikeInfo.inStock > 0 ? (
                      bikeInfo.models ? (
                        <div>
                          {bikeInfo.models[model].inStock ? (
                            bikeInfo.variant ? (
                              bikeInfo.variant[variant].inStock ? (
                                <>
                                  <button
                                    className="p-3 btn btn-dark mt-lg-4 w-100 rounded-0"
                                    onClick={() =>
                                      addCart(bikeInfo._id, model, variant, 1)
                                    }
                                  >
                                    BUY NOW
                                  </button>
                                </>
                              ) : (
                                <button className="p-3 btn btn-dark disabled w-100 mt-lg-4 rounded-0">
                                  OUT OF STOCK
                                </button>
                              )
                            ) : (
                              <>
                                <button
                                  className="p-3 btn btn-dark mt-lg-4 w-100 rounded-0"
                                  onClick={() =>
                                    addCart(bikeInfo._id, model, 1)
                                  }
                                >
                                  BUY NOW
                                </button>
                              </>
                            )
                          ) : (
                            <button className="p-3 btn btn-dark disabled w-100 mt-lg-4 rounded-0">
                              OUT OF STOCK
                            </button>
                          )}
                        </div>
                      ) : (
                        <>
                          <button
                            className="p-3 btn btn-dark mt-lg-4 w-100 rounded-0"
                            onClick={() => addCart(bikeInfo._id, model, 1)}
                          >
                            BUY NOW
                          </button>
                        </>
                      )
                    ) : (
                      <button className="p-3 btn btn-dark disabled w-100 mt-lg-4 rounded-0">
                        OUT OF STOCK
                      </button>
                    )}
                    {bikeInfo.price < 1500 && (
                      <div>
                        <afterpay-placement
                          data-locale="en_AU"
                          data-currency="AUD"
                          data-amount={`${bikeInfo.price}.00`}
                        ></afterpay-placement>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid d-none mt-lg-5 pt-lg-5">
            <h1 className="fw-bold ">Key Features </h1>
            <Splide
              ref={bikeStatsGallery}
              className="p-0"
              options={{
                arrows: true,
                drag: true,
                type: "loop",
                gap: "0rem",
                padding: "10em",
                pagination: true,
                autoplay: false,
                pauseOnHover: false,
                resetProgress: false,
                interval: 5000,
                perPage: 3,
                speed: 900,
                focus: "center",
              }}
            >
              <SplideSlide>
                <Link to={"/x-factor"}>
                  <img
                    src="https://d1mo5ln9tjltxq.cloudfront.net/-/media/images/my21/bikes/electric/e-urban/adventure-neo/pdp-images/c21_adventureneo_tech_radar.ashx?mh=2560&mw=1920&hash=0249768851651F540E776B17B630FC90"
                    alt="Doodle"
                    className="img-fluid"
                  />
                </Link>
              </SplideSlide>{" "}
              <SplideSlide>
                <Link to={"/x-factor"}>
                  <img
                    src="https://d1mo5ln9tjltxq.cloudfront.net/-/media/images/my21/bikes/electric/e-urban/adventure-neo/pdp-images/c21_adventureneo_tech_radar.ashx?mh=2560&mw=1920&hash=0249768851651F540E776B17B630FC90"
                    alt="Doodle"
                    className="img-fluid"
                  />
                </Link>
              </SplideSlide>
              <SplideSlide>
                <Link to={"/x-factor"}>
                  <img
                    src="https://d1mo5ln9tjltxq.cloudfront.net/-/media/images/my21/bikes/electric/e-urban/adventure-neo/pdp-images/c21_adventureneo_tech_radar.ashx?mh=2560&mw=1920&hash=0249768851651F540E776B17B630FC90"
                    alt="Doodle"
                    className="img-fluid"
                  />
                </Link>
              </SplideSlide>
              <SplideSlide>
                <Link to={"/x-factor"}>
                  <img
                    src="https://d1mo5ln9tjltxq.cloudfront.net/-/media/images/my21/bikes/electric/e-urban/adventure-neo/pdp-images/c21_adventureneo_tech_radar.ashx?mh=2560&mw=1920&hash=0249768851651F540E776B17B630FC90"
                    alt="Doodle"
                    className="img-fluid"
                  />
                </Link>
              </SplideSlide>
            </Splide>
          </div>
          <div className="banner-video">
            <div className="container">
              <h3 className="text-light fw-bold">
                {bikeHighlightsData.tagline}
              </h3>

              <img
                src={bikeHighlightsData.poster1}
                className="img-fluid"
                alt=""
              />

              <div className="row  mt-4">
                <div className="col-lg-6  h-100">
                  <p className="text-light summary-writeup">
                    {bikeHighlightsData.writeup}
                  </p>
                </div>
                <div className="col-lg-6 h-100  d-flex featured-box">
                  <div className=" h-100 col-4  p-lg-3 pt-lg-0 p-1">
                    <div
                      className="p-lg-2 pt-lg-3   bg-dark"
                      style={{
                        minHeight: "115px",
                      }}
                    >
                      <h2 className="text-light text-center m-0 "> Range</h2>
                      <p className=" mt-2 text-center">
                        {tableSpecsData.boxRange}
                      </p>
                    </div>
                  </div>
                  <div className=" h-100 col-4 p-lg-3 pt-lg-0 p-1">
                    <div
                      className="p-lg-2 pt-lg-3  bg-dark"
                      style={{
                        minHeight: "115px",
                      }}
                    >
                      <h2 className="text-light text-center m-0 ">Brakes</h2>
                      <p className=" mt-2 text-center">
                        {tableSpecsData.boxBrakes}
                      </p>
                    </div>
                  </div>
                  <div className=" h-100 col-4 p-lg-3 pt-lg-0 p-1">
                    <div
                      className="p-lg-2  pt-lg-3 h-100 bg-dark"
                      style={{
                        minHeight: "115px",
                      }}
                    >
                      <h2 className="text-light text-center m-0"> Battery</h2>
                      <p className=" mt-2 text-center">
                        {tableSpecsData.boxBattery}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container lifestyle mt-lg-5">
              <div className="row">
                <div className="col-12  h-100 col-lg-6">
                  <h1>{bikeHighlightsData.lifeStyleHeading}</h1>
                  <img
                    src={bikeHighlightsData.lifestyleImg1}
                    className="img-fluid mt-4 "
                    alt=""
                  />
                  <h4 className="mt-3 ">
                    {bikeHighlightsData.lifeStyleSubHeading1}
                  </h4>
                  <p>{bikeHighlightsData.lifeStyleSubDesc1}</p>
                </div>
                <div className="col-12 h-100 col-lg-5 offset-lg-1">
                  <img
                    src={bikeHighlightsData.lifestyleImg2}
                    className="img-fluid vertical-img  mt-0"
                    alt=""
                  />
                  <h4 className="mt-3 ">
                    {bikeHighlightsData.lifeStyleSubHeading2}
                  </h4>
                  <p>{bikeHighlightsData.lifeStyleSubDesc2}</p>
                </div>
              </div>

              <div className="row  mt-lg-2 pt-lg-5">
                <div className="col-12 h-100 col-lg-5 ">
                  <img
                    src={bikeHighlightsData.lifestyleImg3}
                    className="img-fluid  vertical-img  mt-0"
                    alt=""
                  />
                  <h4 className="mt-3 ">
                    {bikeHighlightsData.lifeStyleSubHeading3}
                  </h4>
                  <p>{bikeHighlightsData.lifeStyleSubDesc3}</p>
                </div>{" "}
                <div className="col-12  offset-lg-1 h-100 col-lg-6">
                  <img
                    src={bikeHighlightsData.lifestyleImg4}
                    className="img-fluid  "
                    alt=""
                  />
                  <h4 className="mt-3 ">
                    {bikeHighlightsData.lifeStyleSubHeading4}
                  </h4>
                  <p>{bikeHighlightsData.lifeStyleSubDesc4}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="specs mt-4 mb-2 ">
            <div className="container">
              <div className="row text-light ">
                <div className="col-lg-5 position-relative  ">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="">Key Specifications </h2>
                    {bikeHighlightsData.manual && (
                      <a
                        href={bikeHighlightsData.manual}
                        download
                        style={{
                          color: "grey",
                          fontSize: "0.9em",
                        }}
                        target="_blank"
                        className="d-lg-none text-decoration-none"
                      >
                        {" "}
                        <i class="fa-solid fa-download"></i> Manual
                      </a>
                    )}
                  </div>

                  {bikeHighlightsData.manual && (
                    <a
                      href={bikeHighlightsData.manual}
                      download
                      target="_blank"
                      className="d-none d-lg-block"
                      style={{
                        background: "#141414",
                        position: "absolute",
                        borderRadius: "20px",
                        boxShadow: "1px 1px 8px grey",
                        top: "45%",
                        textDecoration: "none",
                        width: "50%",
                        translate: "0% -50%",
                      }}
                    >
                      <div className=" text-center  pt-lg-4 pb-lg-4 ">
                        <i
                          class="fa-solid  fa-gears"
                          style={{
                            color: "#3b3c3d",
                            fontSize: "8em",
                          }}
                        ></i>
                        <p
                          className="mt-5 mb-0"
                          style={{
                            color: "grey",
                            fontSize: "0.9em",
                          }}
                        >
                          {" "}
                          <i class="fa-solid fa-download me-3"></i>
                          Download User manual{" "}
                        </p>
                      </div>
                    </a>
                  )}
                </div>
                <div className="col-lg-7 specs-data mt-4 mt-md-0  text-light">
                  {bikeInfo?.specs.map(
                    (item, i) =>
                      i <= 6 && (
                        <div className="row p-lg-4 pt-4 pb-4 ps-2 pe-2">
                          <div className="col-md-6 col-5 ">
                            <p className="m-0">{item.name}</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p className="m-0">{item.value}</p>
                          </div>
                        </div>
                      )
                  )}
                  {bikeInfo.specs.map(
                    (item, i) =>
                      i > 6 && (
                        <div class="collapse" id="collapseExample">
                          <div className="row p-lg-4 pt-4 pb-4 ps-2 pe-2">
                            <div className="col-md-6 col-5 ">
                              <p className="m-0">{item.name}</p>
                            </div>
                            <div className="col-md-6 col-7">
                              <p className="m-0">{item.value}</p>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                  <button
                    class="specs-btn mt-4 mx-auto d-block "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    onClick={() => setCollapse(!Collapse)}
                    aria-controls="collapseExample"
                  >
                    {Collapse ? "Collapse" : " See More"}
                  </button>
                </div>
              </div>
            </div>
          </div>{" "}
          {bikeHighlightsData.reviews &&
            bikeHighlightsData.reviews.length > 0 && (
              <div className="container p-lg-4">
                <h2 className="mt-4 mt-lg-2">Rider Reviews</h2>
                <div className="mt-4">
                  <Splide
                    className="p-0"
                    options={{
                      arrows: true,
                      drag: false,
                      type: "slide",
                      pagination: true,
                      autoplay: false,
                      perMove: 1,
                      gap: "1em",
                      padding: {
                        left: "0rem",
                        right: "2rem",
                      },
                      pauseOnHover: false,
                      resetProgress: false,
                      interval: 5000,
                      perPage: 3,
                      speed: 1000,
                      breakpoints: {
                        640: {
                          perPage: 1,
                          type: "slide",
                          arrows: true,
                          drag: true,
                        },
                      },
                    }}
                  >
                    {bikeHighlightsData.reviews?.map((item) => (
                      <SplideSlide>
                        <div class="card h-100">
                          <img
                            src={item.img}
                            class="card-img-top img-fluid"
                            alt="..."
                          />
                          <div class="card-body">
                            <p class="card-text">{item.text}</p>
                          </div>
                        </div>
                      </SplideSlide>
                    ))}
                  </Splide>
                </div>
              </div>
            )}
          <div
            className=" pt-2  pb-2 p-2 d-lg-none position-sticky   "
            style={{
              bottom: "0%",
              border: "0px",
              width: "100%",
              zIndex: "5",
              background: "#fff",
            }}
          >
            {bikeInfo.inStock > 0 ? (
              bikeInfo.models ? (
                <div>
                  {bikeInfo.models[model].inStock ? (
                    <>
                      <button
                        onClick={() => addCart(bikeInfo._id, model, 1)}
                        className="text-light pt-3 pb-3 rounded-0 btn w-100 "
                        style={{
                          border: "none",
                          background: "rgba(0,0,0,1)",
                        }}
                      >
                        BUY NOW
                      </button>
                      {bikeInfo.price < 1500 && (
                        <div>
                          <afterpay-placement
                            data-locale="en_AU"
                            data-currency="AUD"
                            data-size="sm"
                            data-amount={`${bikeInfo.price}.00`}
                          ></afterpay-placement>
                        </div>
                      )}
                    </>
                  ) : (
                    <button className="p-3 btn  btn-dark disabled w-100 mt-lg-4  rounded-0">
                      OUT OF STOCK
                    </button>
                  )}
                </div>
              ) : (
                <>
                  <button
                    onClick={() => addCart(bikeInfo._id, model, 1)}
                    className="text-light pt-3 pb-3 rounded-0 btn w-100 "
                    style={{
                      border: "none",
                      background: "rgba(0,0,0,1)",
                    }}
                  >
                    BUY NOW
                  </button>
                  {bikeInfo.price < 1500 && (
                    <div>
                      <afterpay-placement
                        data-locale="en_AU"
                        data-size="sm"
                        data-currency="AUD"
                        data-amount={`${bikeInfo.price}.00`}
                      ></afterpay-placement>
                    </div>
                  )}
                </>
              )
            ) : (
              <button className="p-3 btn  btn-dark disabled w-100 mt-lg-4  rounded-0">
                OUT OF STOCK
              </button>
            )}
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default ProductScreen;
