import React from "react"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"
const TermsAndCondition = () => {
	return (
		<div className='container-fluid p-0'>
			<Navbar />
			<div className='container mb-lg-5 pb-lg-5'>
				<div className='mt-4'>
					<h1 className=' fw-bold mt-lg-5 mt-4'>
						TERMS AND CONDITIONS
					</h1>
					<hr className='d-lg-none' />

					<p className='mt-4 text-justify'>
						This Document is an electronic record
						in terms with the Laws of Australia
						and the GDPR, regarding the access and
						use of the website ‘EMotorad’
						(Hereinafter “website”) owned by
						AUSSTECH HOLDINGS PTY LTD (Hereinafter
						“Company”). The acceptance to the
						Terms & Conditions by the User shall
						be considered as a valid contract
						under the Laws of Australia. All
						notices under this agreement will be
						considered as written and have been
						duly signed once the same is
						electronically confirmed. For purposes
						of ease of understanding, terms such
						as “You” and “User” shall be used to
						refer to the customer. Please read
						this document carefully before
						partaking in the use of the Platform
						and any of the services so offered by
						it. By entering and using the services
						as provided by the website, you shall
						be deemed to have accepted and
						consented to the Terms of Use as
						stated under this document;
					</p>

					<ol className='mt-5'>
						<li className='h5 fw-bold '>
							<p>USER INFORMATION</p>
						</li>
						<ul>
							<li>
								The User of this Website shall be
								a person who has attained the age
								of 16 years. Any person below the
								age of 16 shall access the website
								only under the supervision of a
								guardian.
							</li>
							<li className='mt-3'>
								The User of this website shall
								provide with the authentic
								Identity details such as Contact
								Number, Email Address etc., as a
								proof of verification to access
								the Website.
							</li>
							<li className='mt-3'>
								The information collected by
								website shall be protected under
								the Data Privacy and such
								information shall be handed over
								to the Appropriate Law Enforcement
								Agencies as and when it shall be
								deemed required by the Appropriate
								Law Enforcement Agencies.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold '>
							DEFINITIONS:
						</li>
						<ul>
							<li className='mt-3'>
								<h6 className='fw-bold'>
									{" "}
									CONFIDENTIAL INFORMATION:{" "}
								</h6>
								All information collected by the
								Website for the purpose of
								creating an account and all the
								information collected in future to
								maintain, upgrade or degrade the
								account shall be the Confidential
								Information of the User.
							</li>

							<li className='mt-3'>
								<h6 className='fw-bold'>
									USER:{" "}
								</h6>
								<ul>
									<li>
										User shall be a person who has
										attained the age of 16 years
										and who is a sound mind.
									</li>
									<li>
										Any person irrespective of
										Nationality shall be termed as
										a user of this website under
										this USER POLICY.
									</li>
									<li>
										The user is an individual who
										basically utilizes the
										services provided by the
										website.
									</li>
									<li>
										The user reserves the duty to
										follow the points laid down in
										this User Policy.
									</li>
									<li>
										The user is an individual who
										assures the usage of the
										website for legitimate use
										only.
									</li>
								</ul>
							</li>
							<li className=' mt-3'>
								<h6 className='fw-bold'>
									INTELLECTUAL PROPERTY{" "}
								</h6>
								Trademarks, Copyrights, website
								content, Logos, Words,
								terminologies, business concept
								etc. belonging to website.
							</li>
							<li className=' mt-3'>
								<h6 className='fw-bold'>
									CYBER CRIME
								</h6>
								Any action committed by the User
								or any Third Party for any illegal
								or unlawful purpose by utilizing
								the services provided by website.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							SERVICES:
						</li>
						<ul>
							<li>
								‘AUSSTECH HOLDINGS PTY LTD’,
								creates electric bicycles,
								electric scooters, electric bikes
								and their parts.
							</li>
							<li>
								These bikes, bicycles, scooters
								are carefully created and
								manufactured by AUSSTECH HOLDINGS
								PTY LTD for creating an
								environment friendly alternative
								for the usage of the bikes,
								scooters and other vehicles.
							</li>
							<li>
								The bikes, scooters, bicycles, etc
								has been manufactured by AUSSTECH
								HOLDINGS PTY LTD for making it
								user friendly and more efficient
								in respect of nature. This is
								purely based on the rechargeable
								batteries and electricity.
							</li>
							<li>
								Additionally, we also intend to
								identify the present need for
								saving environment with effective
								measures. Therefore, we have
								created and manufactured the
								electric bikes and cycles.
							</li>
							<li>
								The User shall, for the purposes
								of using the services of the
								Platform, create an account and
								shall be responsible for
								maintaining the confidentiality of
								the login details, display name
								and purchase history. The User
								shall also be responsible for all
								activities taking place under his
								login ID and password. Details
								such as location, name, and so on
								as provided by the users shall be
								accessible by the website for the
								purpose of providing the services
								of the website as well as to other
								users contacting them.
							</li>
							<li>
								The Platform has the sole and
								exclusive right to suspend, block,
								impede, deny access and/or
								terminate the account of the User.
								In the event of there being any
								unauthorised breach of the account
								of the User, the User shall
								immediately notify the Grievance
								Officer of the same who shall take
								necessary Action in an expeditious
								manner.
							</li>
							<li>
								Should the User choose any paid
								activity or feature of the
								website, they shall confirm the
								purchase(s) and then be directed
								to the payment processor gateway
								(Payment Gateway) which shall be a
								third party. The User must go
								through their privacy policy and
								terms of use before proceeding to
								make a payment using the Payment
								Gateway. The website shall in no
								manner be responsible for any and
								all issues arising from and in
								relation to the Payment Gateway;
							</li>
							<li>
								In the event the User wishes to
								cancel, request refunding or
								terminate any purchase, then the
								User shall proceed in the manner
								as specified under this Agreement.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							CONFIDENTIALITY
						</li>
						<ul>
							<li>
								The information collected from the
								User shall be confidential
								information and it shall not be
								shared with the third person.
							</li>
							<li>
								However, the website reserves the
								right to share basic contents of
								the confidential information for
								the promotion of the Business of
								both the User and the Website.
							</li>
							<li>
								The confidential information shall
								only be shared with the Law
								Enforcement Agencies as and when
								appropriate procedure is followed.
							</li>
							<li>
								The confidential information shall
								be shared if it is a requirement
								to launch in other platforms such
								as IOS, Android, Google, Microsoft
								etc.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							USER CONDUCT
						</li>
						<p>The User shall not:</p>
						<p>
							That the User must, for the purposes
							of being granted access to the
							Website and services offered
							thereunder, uphold and abide the
							following:
						</p>
						<ul>
							<li className='mt-2'>
								Engage in any form of activity
								whereby the confidential
								information of other User(s),
								including but not limited to their
								location, password, login details,
								purchase history and other such
								account details, is collected in
								any manner contrary to the law of
								the land.
							</li>
							<li className='mt-2'>
								Partake in any form activity on
								the Website by using the account
								of another User without their
								consent / knowledge.
							</li>
							<li className='mt-2'>
								Publish, put up, conduct business
								in a manner that is wrong,
								harassing, blasphemous, obscene,
								defamatory, libellous, hateful,
								disparaging, promote illegal
								activities such as gambling,
								pornography, money laundering or
								any other acts, material or images
								deemed unlawful by the law of the
								land.
							</li>
							<li className='mt-2'>
								Partake in activities which
								infringe on the Privacy of other
								User(s), website and any other
								individuals so associated,
								representatives, agents and even
								employees without their explicit
								consent.
							</li>
							<li className='mt-2'>
								Behave in any manner deemed to be
								threatening, defamatory, obscene,
								disparaging and/or hurtful to any
								other individual on the basis of
								their religion, caste, class,
								creed, sex or other such factors
								deemed necessary in the eyes of
								law
							</li>
							<li className='mt-2'>
								Partake in any activity causing
								there to be unsolicited emails,
								messages, calls to other User(s),
								website and any other individuals
								so associated, representatives,
								agents and even employees of the
								same.
							</li>
							<li className='mt-2'>
								Partake in any action which
								directly or indirectly infringes
								on the intellectual property
								rights of the users, website or
								any other individual, association,
								corporate body, company, shop
								and/or commercial entity.
							</li>
							<li className='mt-2'>
								Partake in the marketing,
								advertising, providing
								information, instructions and/or
								solicitation of activities,
								objects, products, goods or works
								deemed unlawful in the eyes of the
								law.
							</li>
							<li className='mt-2'>
								Exceed the scope of authorised
								access to the website in manner so
								deemed unlawful and/or
								inappropriate by the law of the
								land.
							</li>
							<li className='mt-2'>
								Interfere with any other User(s)
								right to enjoy the services of the
								website in any manner which is
								held, construed and/or interpreted
								as being unlawful under the law of
								the land and/or as per the
								direction of the website.
							</li>
							<li className='mt-2'>
								Partake in the filing of
								information solely for fraudulent
								or misinformative purposes which
								are against the law of the land.
							</li>
							<li className='mt-2'>
								Partake in any actions that
								threaten the unity, integrity,
								defence, security or sovereignty
								of Australia, friendly relations
								with foreign states or public
								order or causes incitement to the
								commission of any cognizable
								offence or prevents investigation
								of any offence from happening or
								is insulting to any other nation.
							</li>
							<p>
								The User(s) shall be deemed to
								conducting themselves in an
								unlawful manner should they:
							</p>
							<li className='mt-2'>
								Gain access to the Platform
								despite refusing to fill in the
								account details in a proper
								manner.
							</li>
							<li className='mt-2'>
								Harass the user(s) / website owner
								and any other individuals so
								associated, representatives,
								agents and even employees of the
								same in any manner by using the
								contact details so provided.
							</li>
							<li className='mt-2'>
								Partake in any activities with the
								sole intention of causing loss of
								revenue / business issues and/or
								any other financial problems to
								the website.
							</li>
							<li className='mt-2'>
								Partake in any action that is held
								to be against the Laws of
								Australia and the rules
								thereunder, GDPR and the rules
								thereunder.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							INTELLECTUAL PROPERTY RIGHTS:
						</li>
						<ul>
							<li className='mt-2'>
								All information, photographs,
								illustrations, artwork and other
								graphic materials, and names,
								logos and trade marks on the
								Website are protected by copyright
								laws, trademark laws and/or other
								laws and/or international treaties
								and belong to us.
							</li>
							<li className='mt-2'>
								These works, logos, graphics,
								sounds or images may not be
								copied, reproduced, retransmitted,
								distributed, disseminated, sold,
								published, broadcasted, or
								circulated whether in whole or in
								part, unless expressly permitted
								by us.
							</li>
							<li className='mt-2'>
								The reference image uploaded on
								the website will be purchased
								before being uploaded. All the
								intellectual property rights in
								the design and concept of the
								website E-Motorad, the name, logo,
								trade secrets will vest in the
								owner.
							</li>
							<li className='mt-2'>
								Nothing contained on the Website
								should be construed as granting by
								implication, estoppel, or
								otherwise, any license or right to
								use any trademark displayed on the
								Website without the written
								permission. Misuse of any
								trademarks or any other content
								displayed on the website is
								prohibited.
							</li>
							<li className='mt-2'>
								We will not hesitate to take legal
								action against any unauthorised
								usage of our trademarks,
								copyrights, name or symbols to
								preserve and protect its rights in
								the matter. All rights not
								expressly granted herein are
								reserved. Other product and
								company names mentioned herein may
								also be the trademarks of their
								respective owners.
							</li>
							<li className='mt-2'>
								The User shall also not use the
								services provided by the website
								to broadcast messages to promote
								any form of business that is of
								similar nature to that of the
								services provided by website.
							</li>
							<li className='mt-2'>
								The concept and working of the
								website are protected under the
								Laws of Spain and therefore shall
								not be reverse engineered or
								replicated or re-created by the
								User.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							AMENDMENTS
						</li>
						<ul>
							<li className='mt-2'>
								We may periodically make changes
								to the contents of the Website,
								including to the descriptions of
								the services advertised, at any
								time and without notice. We assume
								no liability or responsibility for
								any errors or omissions in the
								content of the Website.
							</li>
							<li className='mt-2'>
								We reserve the right to amend
								these Terms and Conditions of Use
								from time to time without notice.
								The revised Terms and Conditions
								of Use will be posted on the
								Website and shall take effect from
								the date of such posting.
							</li>
							<li className='mt-2'>
								The user is advised to review
								these terms and conditions
								periodically as they are binding
								upon you.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							DISCLAIMER AND EXCLUSION OF
							LIABILITY:
						</li>
						<ul>
							<li className='mt-2'>
								The Website agrees to protect all
								data provided by the User for the
								purpose of using the Website.
							</li>
							<li className='mt-2'>
								The Website agrees to manage,
								store and delete all the data when
								necessary, belonging to the User
								in light of the circumstances that
								are raised.
							</li>
							<li className='mt-2'>
								The website shall abide by the
								provisions laid down in the Laws
								of Australia and GDPR to protect
								the data provided by the user for
								the purpose of using the website.
							</li>
							<li className='mt-2'>
								In light of a scenario where the
								Laws of Australia, or The Courts
								of appropriate Jurisdiction, or
								any other appropriate body, in the
								name of public welfare and cyber
								security request the website to
								provide the data so collected from
								users, then the website shall
								provide the data, but only under
								the provisions substantiated by
								the law.
							</li>
							<li className='mt-2'>
								The website shall take complete
								precaution and make effort to
								protect the data provided by the
								User. But considering situations
								unfathomable or beyond the control
								of reasonability, if the there is
								a data breach by the actions of a
								third party against public
								welfare, the website reserves the
								right to stay indemnified by the
								User.
							</li>
							<li className='mt-2'>
								To the fullest extent permitted by
								applicable law, we disclaim all
								representations and warranties
								relating to the website and its
								contents, including in relation to
								any inaccuracies or omissions in
								the website, warranties of
								merchantability, quality, fitness
								for a particular purpose,
								accuracy, availability,
								non-infringement or implied
								warranties from course of dealing
								or usage of trade.
							</li>
							<li className='mt-2'>
								The website will always be
								accessible, uninterrupted, timely,
								secure, error free or free from
								computer virus or other invasive
								or damaging code or that the
								Website will not be affected by
								any acts of God or other force
								majeure events, including
								inability to obtain or shortage of
								necessary materials, equipment
								facilities, power or
								telecommunications, lack of
								telecommunications equipment or
								facilities and failure of
								information technology or
								telecommunications equipment or
								facilities.
							</li>
							<li className='mt-2'>
								While the website may use
								reasonable efforts to include
								accurate and up-to- date
								information on the website, it
								does not guarantee the accuracy,
								timeliness or completeness of the
								Website.
							</li>
							<li className='mt-2'>
								We shall not be liable for any
								acts or omissions of any third
								parties howsoever caused, and for
								any direct, indirect, incidental,
								special, consequential or punitive
								damages, howsoever caused,
								resulting from or in connection
								with the website and the services
								offered in the website, your
								access to, use of or inability to
								use the website or the services
								offered in the website, reliance
								on or downloading from the website
								and/or services, or any delays,
								inaccuracies in the information or
								in its transmission including but
								not limited to damages for loss of
								business or profits, use, data or
								other intangible, even if we have
								been advised of the possibility of
								such damages.
							</li>
							<li className='mt-2'>
								We shall not be liable in
								contract, tort (including
								negligence or breach of statutory
								duty) or otherwise howsoever and
								whatever the cause thereof, for
								any indirect, consequential,
								collateral, special or incidental
								loss or damage suffered or
								incurred by you in connection with
								the website and these Terms and
								Conditions of Use. For the
								purposes of these Terms and
								Conditions of Use, indirect or
								consequential loss or damage
								includes, without limitation, loss
								of revenue, profits, anticipated
								savings or business, loss of data
								or goodwill, loss of use or value
								of any equipment including
								software, claims of third parties,
								and all associated and incidental
								costs and expenses.
							</li>
							<li className='mt-2'>
								The above exclusions and
								limitations apply only to the
								extent permitted by law. None of
								your statutory rights as a
								consumer that cannot be excluded
								or limited are affected.
							</li>
							<li className='mt-2'>
								Notwithstanding our efforts to
								ensure that our system is secure,
								the User acknowledges that all
								electronic data transfers are
								potentially susceptible to
								interception by others. We cannot,
								and do not, warrant that data
								transfers pursuant to the website,
								or electronic mail transmitted to
								and from us, will not be monitored
								or read by others.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							JURISDICTION AND LEGAL ACTION:
						</li>
						<ul>
							<li className='mt-2'>
								Any dispute arising with respect
								to the usage, services or any
								other dispute arising from third
								party shall be referred to
								Arbitration.
							</li>
							<li className='mt-2'>
								The user agrees to not take any
								legal action in the courts of
								Brisbane, Australia considering a
								scenario, the User has faced any
								issue.
							</li>
							<li className='mt-2'>
								The User shall intimate the
								website and initiate a discussion
								leading up to Arbitration if
								needed.
							</li>
							<li className='mt-2'>
								The website reserves the right to
								take legal countermeasures if the
								User chooses to take any legal
								action. The action so taken by us
								will be on grounds of breach of
								contract and misuse of the law.
							</li>
							<li className='mt-2'>
								Considering an action caused by a
								Third party, the User agrees to
								not initiate any action or
								proceeding for the damage caused
								by a third party.
							</li>
							<li className='mt-2'>
								If we suspect any misuse of the
								website by the User, then we
								reserve the right to initiate a
								strict legal action against the
								User.
							</li>
							<li className='mt-2'>
								The User must from time to time
								provide proof which is genuine in
								nature in any Dispute Resolution
								model.
							</li>
							<li className='mt-2'>
								Any action taken without the
								consent of the website will be
								treated as a violation of this
								User Agreement and if it results
								in any damages sustained by us,
								then the USER will be dealt with
								as per the provisions of by the
								laws of Australia.
							</li>
							<li className='mt-2'>
								In the event the Parties choose to
								resolve the dispute via
								litigation, they agree to limit
								themselves to the jurisdiction of
								the courts of Australia.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							TERMINATION
						</li>
						<ul>
							<p className='mt-2'>
								That the website shall have the
								sole and exclusive right to
								unilaterally terminate the account
								of the User immediately in the
								following events:
							</p>
							<li className='mt-2'>
								Should it be found that the said
								User has been engaging in
								activities that violate the Terms
								of Use, Privacy Policy and other
								such policies, the law of the land
								or any other rules and regulations
								put forward by the governing
								authorities.
							</li>
							<li className='mt-2'>
								Should continue in the engagement
								of such activities despite being
								given due warnings not do so via
								mail.
							</li>
							<li className='mt-2'>
								Are to manipulate, abuse, twist or
								mis-interpret the Terms of Use,
								Privacy Policy and other such
								policies, the law of the land or
								any other rules and regulations
								put forward by the governing
								authorities for purposes of
								partaking in activities that are
								unlawful.
							</li>
							<li className='mt-2'>
								Refusal to make the requisite
								payments for paid features, if
								applicable.
							</li>
							<li className='mt-2'>
								Harass, intimidate, provoke,
								abuse, hurt or partake in any
								unlawful action against the owner
								and the website, and any other
								individuals so associated,
								representatives, agents and even
								employees of the same.
							</li>
							<p className='mt-2'>
								Should the User decide to
								terminate their account
								themselves, they may do so only
								after having:
							</p>
							<li>
								Completed any and all pending
								payments.
							</li>
							<li className='mt-2'>
								Should not upload, create or share
								any malicious software, content,
								virus or destructive algorithm
								which may cause harm to the other
								users, website or any other
								concerned individual / third
								party.
							</li>
							<li className='mt-2'>
								Breach any part of the Terms of
								Use, Privacy Policy and other such
								policies, the law of the land or
								any other rules and regulations
								put forward by the governing
								authorities.
							</li>
							<p className='mt-2'>
								Once the account is so terminated,
								then the website shall not grant
								anyone access to the information
								and the same shall be erased
								within 90 days from any and all
								storage servers and other such
								personalised hardware used for the
								working of the Platform;
							</p>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							INDEMNITY
						</li>
						<ul>
							<li>
								The User shall hold harmless and
								indemnify the owner and the
								website, and any other individuals
								so associated, representatives,
								agents and even employees of the
								same from any and all claims,
								demands, actions, attorney’s fees
								made by any individual or third
								party due to a breach of the Terms
								of use, Privacy Policy and other
								such policies, the law of the land
								or any other rules and regulations
								put forward by the governing
								authorities  caused directly /
								indirectly by the User and/or
								their actions while utilizing the
								Platform;
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							GRIEVANCE OFFICER:
						</li>
						<ul>
							<li>
								In the event there is any
								complaint / clarification / doubt/
								issue that the User so seeks to be
								clarified, they must contact the
								Grievance Officer. Following are
								the details of the Grievance
								Officer:
							</li>
						</ul>
						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							FullName:
						</p>
						<h4 className='fw-bold'>
							Arjun Khatri
						</h4>
						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							Email ID:
						</p>
						<h4 className='fw-bold'>
							arjun@emotorad.com
						</h4>

						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							Office Number:
						</p>
						<h4 className='fw-bold'>
							+61 1300640097
						</h4>

						<hr />
						<p>
							The Grievance Officer shall attempt
							to resolve any and all issues so
							brought before him /her in a fair
							and expeditious manner within 28
							business days. After completion of
							the review the Grievance Officer
							shall submit a report to the User
							and the website.
						</p>
					</ol>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default TermsAndCondition
