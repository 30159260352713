import React, { useState, useEffect } from "react"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import Meta from "../Components/Meta"
import Navbar from "../Components/Navbar"
import { useNavigate } from "react-router-dom"
import { insuranceCreate } from "../Actions/formActions"
import storage from "../firebaseConfig"
import {
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from "firebase/storage"
import Footer from "../Components/Footer"
import "../CSS/warranty.css"
const InsuranceScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] =
		useState("")
	const [dateOfBirth, setDateOfBirth] =
		useState("")
	const [bike, setBike] = useState("")
	const [frameNumber, setFrameNumber] =
		useState("")
	const [invoiceNumber, setInvoiceNumber] =
		useState("")
	const [nomineeName, setNomineeName] =
		useState("")
	const [
		nomineeDateOfBirth,
		setNomineeDateOfBirth,
	] = useState("")
	const [nomineeRelation, setNomineeRelation] =
		useState("")
	const [address, setAddress] = useState("")
	const [city, setCity] = useState("")
	const [state, setState] = useState("")
	const [pincode, setPincode] = useState("")

	const [file, setFile] = useState()

	const insurance = useSelector(
		(state) => state.insurance,
	)
	const { insuranceStatus } = insurance

	useEffect(() => {
		if (insuranceStatus) {
			navigate("/submitted")
		}
	}, [insuranceStatus])

	const submitForm = (e) => {
		e.preventDefault()
		fileUpload()
	}

	const fileUpload = () => {
		if (!file) {
			alert("Please Upload Invoice")
		}

		// console.log(storage)

		const storageRef = ref(
			storage,
			`/files/${file.name}`,
		)
		const uploadTask = uploadBytesResumable(
			storageRef,
			file,
		)
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const percent = Math.round(
					(snapshot.bytesTransferred /
						snapshot.totalBytes) *
						100,
				)

				// update progress
			},
			(err) => console.log(err),
			() => {
				// download url
				getDownloadURL(
					uploadTask.snapshot.ref,
				).then((url) => {
					dispatch(
						insuranceCreate(
							name,
							email,
							phoneNumber,
							bike,
							frameNumber,
							invoiceNumber,
							dateOfBirth,
							nomineeName,
							nomineeDateOfBirth,
							nomineeRelation,
							address,
							city,
							state,
							pincode,
							url,
						),
					)
				})
			},
		)
	}

	return (
		<>
			<Navbar />
			<Meta
				title={" EMotorad | Insurance"}
				description={
					"EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
				}
			/>

			<div className='container'>
				<p className=' font-mon warranty-border-heading  d-lg-none mt-2 pt-lg-2'>
					INSURANCE
				</p>
				<p className='warranty-border-subheading  d-lg-none'>
					{" "}
					EMotorad and Global Assure jointly bring
					you a comprehensive e-bike insurance
					policy.
				</p>
				<div className='mt-5 d-none d-lg-block'>
					<div className='d-flex down-border pb-4 '>
						<div className='col-8'>
							<p className=' font-mon border-heading pt-lg-3'>
								INSURANCE
							</p>
						</div>
						<div className='col-4'>
							<p className='text-end'>
								EMotorad and Global Assure jointly
								bring you a comprehensive e-bike
								insurance policy.
							</p>
							<p className='mt-4 text-end fw-bold'>
								CLAIM NOW
							</p>
						</div>
					</div>
				</div>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/banners/Banner_Insurance.jpg'
					style={{ borderRadius: "5px" }}
					className='img-fluid mt-lg-4'
					alt=''
				/>

				<form action='' onSubmit={submitForm}>
					<div className='row mt-lg-5'>
						<div className='col-lg-6 col-12'>
							<div className='emi-form p-lg-4 pt-0 pb-0 ps-0'>
								<input
									type='text'
									style={{
										textTransform: " capitalize",
									}}
									value={name}
									required
									autoComplete='off'
									onChange={(e) =>
										setName(e.target.value)
									}
									placeholder='NAME'
								/>
								<input
									type='text'
									placeholder='EMAIL'
									value={email}
									required
									autoComplete='off'
									onChange={(e) =>
										setEmail(e.target.value)
									}
								/>
								<input
									type='number'
									required
									autoComplete='off'
									value={phoneNumber}
									onChange={(e) =>
										setPhoneNumber(e.target.value)
									}
									placeholder='PHONE NUMBER'
								/>{" "}
								<select
									name=''
									id=''
									onChange={(e) =>
										setBike(e.target.value)
									}>
									<option
										selected
										disabled
										value=''>
										Select Bike
									</option>
									<option value='EMX'>EMX</option>
									<option value='T-Rex+'>
										T-Rex+
									</option>
									<option value='Doodle'>
										Doodle
									</option>
									<option value='Lil E'>
										Lil E
									</option>
									<option value='T-Rex'>
										T-Rex
									</option>
								</select>
								<input
									type='date'
									id='dob'
									className='mb-0'
									required
									autoComplete='off'
									value={dateOfBirth}
									onChange={(e) =>
										setDateOfBirth(e.target.value)
									}
									placeholder='DATE OF BIRTH'
								/>
								<p className='ps-2'>
									Date Of Birth
								</p>
								<input
									type='text'
									placeholder='FRAME NUMBER'
									value={frameNumber}
									required
									autoComplete='off'
									onChange={(e) =>
										setFrameNumber(e.target.value)
									}
								/>{" "}
								<input
									type='text'
									placeholder='INVOICE NUMBER'
									value={invoiceNumber}
									required
									autoComplete='off'
									onChange={(e) =>
										setInvoiceNumber(
											e.target.value,
										)
									}
								/>
							</div>
						</div>
						<div className='col-lg-6 col-12 '>
							<div className='emi-form p-lg-4 pt-0 ps-0'>
								<input
									type='text'
									style={{
										textTransform: " capitalize",
									}}
									placeholder='NOMINEE NAME'
									value={nomineeName}
									required
									autoComplete='off'
									onChange={(e) =>
										setNomineeName(e.target.value)
									}
								/>
								<input
									type='date'
									id='dob'
									className='mb-0'
									placeholder='DATE OF BIRTH'
									required
									autoComplete='off'
									value={nomineeDateOfBirth}
									onChange={(e) =>
										setNomineeDateOfBirth(
											e.target.value,
										)
									}
								/>
								<p className='ps-2'>
									Nominee Date Of Birth
								</p>
								<input
									type='text'
									placeholder='RELATION WITH NOMINEE'
									value={nomineeRelation}
									required
									autoComplete='off'
									onChange={(e) =>
										setNomineeRelation(
											e.target.value,
										)
									}
								/>{" "}
								<input
									type='text'
									placeholder='ADDRESS'
									value={address}
									onChange={(e) =>
										setAddress(e.target.value)
									}
									required
									autoComplete='off'
								/>
								<div
									className='d-flex'
									style={{ gap: "1em" }}>
									<input
										type='text'
										placeholder='CITY'
										value={city}
										required
										autoComplete='off'
										onChange={(e) =>
											setCity(e.target.value)
										}
									/>
									<input
										type='text'
										placeholder='STATE'
										value={state}
										onChange={(e) =>
											setState(e.target.value)
										}
										required
										autoComplete='off'
									/>
								</div>
								<input
									type='text'
									placeholder='PINCODE'
									value={pincode}
									required
									autoComplete='off'
									onChange={(e) =>
										setPincode(e.target.value)
									}
								/>
								<label
									htmlFor='file'
									className='invoice-label'>
									{" "}
									{file
										? file.name
										: "UPLOAD INVOICE FILE"}
								</label>
								<input
									type='file'
									hidden
									onChange={(e) =>
										setFile(e.target.files[0])
									}
									accept=' '
									className=' upload-file mt-3'
									id='file'
									placeholder='INVOICE'
								/>{" "}
								<button
									type='submit'
									className='submit d-block'>
									SUBMIT
								</button>
							</div>
						</div>
					</div>

					<hr className='' />
					<div className='container mb-5 mt-5'>
						<h4
							style={{ lineHeight: "1.5em" }}
							className=' fw-bold text-center'>
							WE WANT YOU TO REMEMBER YOUR RIDE,
							NOT YOUR BILLS!
						</h4>
						<p className='text-center'>
							That’s why EMotorad and Global
							Assure jointly bring you the
							comprehensive e-bike insurance
							policy. Our goal is for you to cycle
							without you being preoccupied with
							fears or worries. Our Protection
							Plan is tailored so that cyclists
							can enjoy their rides while enjoying
							an extensive list of benefits. Enjoy
							health assistance, theft protection
							and roadside assistance, all while
							being easy on the pocket.
						</p>
						<div className='row'>
							<div className='col-6 p-2 col-lg-3 '>
								<div
									className=' h-100  p-3 p-lg-5 pt-4  rounded-1'
									style={{
										background: "#D9D9D9",
									}}>
									<i
										style={{ fontSize: "1.5em" }}
										className='fa-solid fa-battery-full d-block text-center'></i>
									<hr className='mt-2 mb-2' />
									<small className='d-block text-center ps-1 pe-1'>
										24x7 Phone Support
									</small>
								</div>
							</div>
							<div className='col-6 p-2 col-lg-3 '>
								<div
									className=' h-100 p-3 p-lg-5 pt-4  rounded-1'
									style={{
										background: "#D9D9D9",
									}}>
									<i
										style={{ fontSize: "1.5em" }}
										className='fa-solid fa-briefcase-medical d-block text-center'></i>
									<hr className='mt-2 mb-2' />
									<small className='pe-1 ps-1 d-block text-center'>
										Doctor Consultation
									</small>
								</div>
							</div>
							<div className='col-6 p-2 col-lg-3 '>
								<div
									className=' h-100 p-3 p-lg-5 pt-4  rounded-1'
									style={{
										background: "#D9D9D9",
									}}>
									<i
										style={{ fontSize: "1.5em" }}
										className=' fa-solid fa-message d-block text-center'></i>
									<hr className='mt-2 mb-2' />
									<small className=' ps-1 pe-1 d-block text-center'>
										Emergency Message Relay
									</small>
								</div>
							</div>
							<div className='col-6 p-2 col-lg-3 '>
								<div
									className=' h-100 p-3 p-lg-5 pt-4 rounded-1'
									style={{
										background: "#D9D9D9",
									}}>
									<i
										style={{ fontSize: "1.5em" }}
										className='fa-solid fa-building-shield d-block text-center'></i>
									<hr className='mt-2 mb-2' />
									<small className='d-block text-center'>
										Locating Nearest Police
										Station
									</small>
								</div>
							</div>
							<div className='col-6 p-2 col-lg-3 '>
								<div
									className=' h-100 p-3 p-lg-5 pt-4   rounded-1'
									style={{
										background: "#D9D9D9",
									}}>
									<i
										style={{ fontSize: "1.5em" }}
										className='fa-solid fa-truck-medical d-block text-center'></i>
									<hr className='mt-2 mb-2' />
									<small className='d-block pe-1 ps-1 text-center'>
										Ambulance Referral
									</small>
								</div>
							</div>
							<div className='col-6 p-2 col-lg-3 '>
								<div
									className='h-100 p-3  p-lg-5 pt-4  rounded-1'
									style={{
										background: "#D9D9D9",
									}}>
									<i
										style={{ fontSize: "1.5em" }}
										className='fa-solid fa-user-secret d-block text-center'></i>
									<hr className='mt-2 mb-2' />
									<small className='d-block text-center'>
										Cycle Theft Insurance*
									</small>
								</div>
							</div>
							<div className='col-6 p-2 col-lg-3 '>
								<div
									className=' h-100 p-3 p-lg-5 pt-4  rounded-1'
									style={{
										background: "#D9D9D9",
									}}>
									<i
										style={{ fontSize: "1.5em" }}
										className='fa-solid fa-fire  d-block text-center'></i>
									<hr className='mt-2 mb-2' />
									<small className='d-block text-center'>
										Cycle Damage From Fire*
									</small>
								</div>
							</div>
							<div className='col-6 p-2 col-lg-3 '>
								<div
									className=' h-100  p-3 p-lg-5 pt-4   rounded-1'
									style={{
										background: "#D9D9D9",
									}}>
									<i
										style={{ fontSize: "1.5em" }}
										className='fa-solid fa-shield-heart  d-block text-center'></i>
									<hr className='mt-2 mb-2' />
									<small className='d-block text-center'>
										Permanent Disability/ Accident
										Death Insurance
									</small>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<Footer />
		</>
	)
}

export default InsuranceScreen
