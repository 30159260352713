import React, { useState, useEffect } from "react"

import {
	useNavigate,
	Link,
} from "react-router-dom"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { getUserOrdersList } from "../Actions/userAction"

const MyOrdersScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const userLogin = useSelector(
		(state) => state.userLogin,
	)
	const { loginInfo } = userLogin

	const getUserOrders = useSelector(
		(state) => state.getUserOrders,
	)
	const { orders } = getUserOrders
	useEffect(() => {
		if (loginInfo) {
			dispatch(getUserOrdersList(loginInfo._id))
		}
	}, [loginInfo])

	return (
		<div className='container p-0'>
			<div
				className='col-12 fw-bold p-3 dropdown-content-list'
				style={{}}>
				<i
					className='fa-solid fw-bold fa-arrow-left-long me-4'
					onClick={() => navigate(-1)}></i>{" "}
				My Orders
			</div>
			<div className='orders_list'>
				<div className='container pt-0 '>
					<div className='row'>
						{orders &&
							orders.map((order, i) => (
								<div className='col-12 col-lg-4'>
									<Link
										to={`/profile/orders/${order._id}`}
										className='text-decoration-none text-dark'>
										<div
											className='row  align-items-center bg ps-3  p-3 pe-0'
											style={{
												borderBottom:
													"1px solid lightgrey",
											}}>
											<div
												className='order-product-img p-2 pe-0   col-4'
												style={{
													backgroundColor:
														"#eff0f1",
													borderRadius: "5px",
												}}>
												<img
													src={
														order.items[0]
															.cartImages[
															order.items[0].color
														]
													}
													className='img-fluid mx-auto d-block'
													alt=''
												/>
											</div>
											<div className='col-8 ps-3 pe-2 p-0 d-flex align-items-center '>
												<div className='col-11 '>
													<h6 className='font-bold'>
														{order.items[0].name}
													</h6>
													<p
														className='m-0 text-muuted'
														style={{
															fontSize: "0.7em",
														}}>
														Ordered on:{" "}
														{order.createdAt.slice(
															0,
															10,
														)}{" "}
													</p>
												</div>
												<div className='col-1  '>
													<p className='text-end text-muted'>
														<i className='fa-solid  fa-angle-right'></i>
													</p>
												</div>
											</div>
										</div>
									</Link>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default MyOrdersScreen
