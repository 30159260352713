import React, { useState, useEffect } from "react";

import Navbar from "../Components/Navbar";
import "@splidejs/react-splide/css";
import { userOrderCreate } from "../Actions/orderActions";
import { useSelector, useDispatch } from "react-redux";
import "../CSS/cart.css";
import { getAllAccessories } from "../Actions/bikeActions";
import { Link, useNavigate } from "react-router-dom";
import {
  addToCart,
  removeFromCart,
  getPromocodes,
} from "../Actions/cartActions";

import Meta from "../Components/Meta";

import Footer from "../Components/Footer";
import { ORDER_CREATE_RESET } from "../Constants/orderConstants";

const CartScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { loginInfo } = userLogin;

  const allAccessories = useSelector((state) => state.allAccessories);
  const { accessories } = allAccessories;

  const checkPromocode = useSelector((state) => state.checkPromocode);
  const { promocodes } = checkPromocode;

  const [promocode, setPromocode] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const orderCreate = useSelector((state) => state.orderCreate);
  const { orderResult } = orderCreate;

  useEffect(() => {
    if (orderResult) {
    }
  }, [orderResult, navigate]);

  useEffect(() => {
    let cartItemsFromStorage = localStorage.getItem("cartItems");

    cartItemsFromStorage = JSON.parse(cartItemsFromStorage);
    if (cartItemsFromStorage) {
      if (cartItemsFromStorage.length > 0) {
        for (let index = 0; index < cartItemsFromStorage.length; index++) {
          dispatch(
            addToCart(
              cartItemsFromStorage[index].product,
              cartItemsFromStorage[index].color,
              cartItemsFromStorage[index].qty,
              cartItemsFromStorage[index].variantType
            )
          );
        }
      }
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ORDER_CREATE_RESET });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAccessories());
  }, [dispatch]);

  const currencyFormat = (num) => {
    return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const removeFromCartHandeler = (id) => {
    dispatch(removeFromCart(id));
  };

  const userDetailsFromLocalStorage =
    localStorage.getItem("userDetails") &&
    JSON.parse(localStorage.getItem("userDetails"));

  const [name, setName] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.name : ""
  );
  const [email, setEmail] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.email : ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.mobileNumber : ""
  );
  const [state, setState] = useState(
    userDetailsFromLocalStorage
      ? userDetailsFromLocalStorage.state
      : "Australian Capital Territory"
  );
  const [city, setCity] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.city : ""
  );
  const [pincode, setPincode] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.pincode : ""
  );
  const [addressLine1, setAddressLine1] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.addressLine1 : ""
  );
  const [addressLine2, setAddressLine2] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.addressLine2 : ""
  );

  // check promocode
  useEffect(() => {
    dispatch(getPromocodes(promocode));
  }, [promocode, dispatch]);

  // add discount to products
  if (promocodes) {
    if (promocodes.length > 0 && promocodes[0].codeType === "price") {
      for (let index = 0; index < cartItems.length; index++) {
        if (
          promocodes[0].productsFor.find((i) => i === cartItems[index].product)
        ) {
          let newItem = cartItems[index];

          newItem.price = Number(
            cartItems[index].accPrice - promocodes[0].codeValue
          );
        }
      }
    }
  } else {
    for (let index = 0; index < cartItems.length; index++) {
      let newItem = cartItems[index];

      newItem.price = Number(cartItems[index].accPrice);
    }
  }

  useEffect(() => {
    // check variant
    if (cartItems) {
      for (let index = 0; index < cartItems.length; index++) {
        if (cartItems[index].variantType) {
          let newItem = cartItems[index];

          newItem.price = Number(
            cartItems[index].variant[cartItems[index].variantType].price
          );

          newItem.accPrice = Number(
            cartItems[index].variant[cartItems[index].variantType].price
          );
        }
      }
    }
  }, []);

  const saveAddress = (e) => {
    e.preventDefault();

    const amount = Number(
      cartItems.reduce((acc, item) => acc + item.qty * item.price, 0) + 49
    );

    dispatch(
      userOrderCreate(
        loginInfo && loginInfo._id,
        name,
        email,
        mobileNumber,
        addressLine1,
        addressLine2,
        city,
        state,
        pincode,
        amount,
        cartItems,
        promocodes && promocodes[0],
        navigate
      )
    );

    localStorage.setItem(
      "address",
      JSON.stringify({
        state,
        city,
        pincode,
        addressLine1,
      })
    );
  };
  return (
    <>
      <Meta
        title={" EMotorad | Cart"}
        description={
          "EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
        }
      />
      <Navbar />
      {cartItems.length > 0 ? (
        <div className="container mt-lg-5 mt-lg-0">
          {/* ..............Accessories list ................ */}
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-scrollable  modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h6 class="modal-title " id="exampleModalLabel">
                    Accessories
                  </h6>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  {accessories &&
                    accessories.map((accItem, i) => (
                      <>
                        <div
                          className="cart-item-sm p-lg-2 pt-4 pb-2 row align-items-center "
                          style={{
                            borderRadius: "5px",
                          }}
                        >
                          <div className=" col-4 overflow-hidden p-0">
                            <img
                              src={accItem.cartImages[0]}
                              alt=""
                              className=" img-fluid  "
                            />
                          </div>
                          <div className="col-5  p-0 ps-3">
                            <h6
                              className="mb-1"
                              style={{
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              {accItem.name}
                            </h6>

                            <h6
                              className="mb-0"
                              style={{
                                fontWeight: "700",
                                fontSize: "0.8em",
                                color: "#3CAB1F",
                              }}
                            >
                              MRP: {currencyFormat(accItem.accPrice)}
                            </h6>
                          </div>
                          <div className="col-3 ">
                            {cartItems.find(
                              (finder) => finder.product === accItem._id
                            ) ? (
                              <button
                                type="button"
                                className="bg-primary d-block mx-auto"
                                style={{
                                  outline: "0px",
                                  borderRadius: "50%",

                                  width: "40px",
                                  height: "40px",
                                  border: "0px",
                                }}
                              >
                                <i class="fa-solid  text-light fa-check"></i>
                              </button>
                            ) : accItem.inStock > 0 ? (
                              <button
                                className=" d-block mx-auto"
                                onClick={() =>
                                  dispatch(addToCart(accItem._id, 0, 1, 0))
                                }
                                style={{
                                  outline: "0px",
                                  borderRadius: "50%",

                                  width: "40px",
                                  height: "40px",
                                  border: "0px",
                                  background: "#10b068",
                                }}
                              >
                                <i class="fa-solid text-light fa-plus"></i>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="bg-muted d-block mx-auto"
                                style={{
                                  outline: "0px",
                                  borderRadius: "50%",

                                  width: "40px",
                                  height: "40px",
                                  border: "0px",
                                }}
                              >
                                <i class="fa-solid fa-exclamation"></i>
                              </button>
                            )}
                          </div>
                        </div>
                        <hr
                          className="mb-0 mt-2"
                          style={{
                            color: "grey",
                          }}
                        />
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={saveAddress}>
            {/* ........................................ */}
            <div className="row">
              <div className="col-lg-6 pe-lg-5  p-1">
                <h6 className=" ps-2 mt-3  d-lg-none cart-heading">
                  YOUR SELECTIONS
                </h6>
                <div className="cart-items d-lg-none  pt-0 p-3">
                  {cartItems.map((item) => (
                    <>
                      <div
                        className="cart-item-sm p-lg-2 pt-4 pb-2 row "
                        style={{
                          borderRadius: "5px",
                        }}
                      >
                        <div className="col-lg-3 col-4 overflow-hidden p-0">
                          <img
                            src={item.cartImages[item.color]}
                            alt=""
                            className=" img-fluid  "
                          />
                        </div>
                        <div className="col-4 pt-2 p-0 ps-3">
                          <h6
                            className="mb-1"
                            style={{
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            {item.name}{" "}
                          </h6>
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "0.7em",
                              color: "grey",
                            }}
                          >
                            ({item.models[item.color].colorName})
                          </p>
                          {item.variant && (
                            <p
                              className="mb-2"
                              style={{
                                fontSize: "0.7em",
                                color: "grey",
                              }}
                            >
                              {item.variant[item.variantType].variantName}
                            </p>
                          )}
                          <h6
                            className="mb-0 mt-2"
                            style={{
                              fontWeight: "700",
                              fontSize: "0.8em",
                              color: "#3CAB1F",
                            }}
                          >
                            MRP: {currencyFormat(item.accPrice)}
                          </h6>
                        </div>
                        <div className="col-4 ">
                          <div className="d-flex">
                            <div
                              className="col-4"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                onClick={(e) => {
                                  if (item.qty - 1 === 0) {
                                    removeFromCartHandeler(item.product);
                                  } else {
                                    dispatch(
                                      addToCart(
                                        item.product,
                                        item.color,
                                        Number(item.qty - 1),
                                        item.variantType
                                      )
                                    );
                                  }
                                }}
                                class="fa-solid fa-minus"
                              ></i>
                            </div>
                            <p className="col-4 text-center">{item.qty}</p>
                            <div
                              className="col-4 text-end"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                onClick={(e) =>
                                  dispatch(
                                    addToCart(
                                      item.product,
                                      item.color,
                                      Number(item.qty + 1),
                                      item.variantType
                                    )
                                  )
                                }
                                class="fa-solid fa-plus"
                              ></i>
                            </div>
                          </div>

                          <i
                            style={{
                              color: "#b8baba",
                              fontSize: "0.8em",
                            }}
                            onClick={() => removeFromCartHandeler(item.product)}
                            class="fa-sharp mt-3 text-end d-block fa-solid fa-trash"
                          ></i>
                        </div>
                      </div>
                      <hr
                        className="mb-0  mt-2"
                        style={{
                          color: "grey",
                        }}
                      />
                    </>
                  ))}
                </div>

                <div className="d-flex p-2 d-lg-none">
                  <p
                    className="col-8"
                    style={{
                      color: "grey",
                      fontSize: "0.7em",
                    }}
                  >
                    Personalize your ride with our wide selection of bicycle
                    accessories
                  </p>
                  <div className=" col-4">
                    <button
                      type="button"
                      className="btn w-100 ps-1 pe-1 pt-2 pb-2 "
                      style={{
                        background: "#cedfe0",
                        color: "grey",
                        fontSize: "0.6em",
                        outline: "0px",
                        border: "0px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i class="fa-solid fa-plus"></i> View Accessories
                    </button>
                  </div>
                </div>

                <div className="p-2 pb-0 mb-4 d-lg-none">
                  <div class="field-wrapper">
                    <input
                      value={promocode}
                      autoComplete="new-password"
                      className={`${promocode.length > 0 && "bg-white"}`}
                      onChange={(e) =>
                        setPromocode(e.target.value.toUpperCase().trim())
                      }
                      type="text"
                      name="promocode"
                      style={{
                        border: "2px dotted  #b1b3b6",
                      }}
                      id=""
                    />
                    <div class="field-placeholder">
                      <span>Apply Promocode</span>
                    </div>
                  </div>
                  {promocodes && promocode.length > 2 ? (
                    promocodes.length > 0 ? (
                      <p
                        className="col-8 mt-1 text-success"
                        style={{
                          fontSize: "0.7em",
                        }}
                      >
                        Promocode Applied
                      </p>
                    ) : (
                      <p
                        className="col-8 mt-1 text-danger"
                        style={{
                          fontSize: "0.7em",
                        }}
                      >
                        Invaid Promocode
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="p-2 pt-0 pb-0 d-lg-none">
                  <hr
                    className="mb-0 mt-2"
                    style={{
                      color: "grey",
                    }}
                  />
                </div>

                <div className="p-2 mt-3 mt-lg-2 pt-0">
                  <h6 className="cart-heading">BILLING & SHIPPING ADDRESS</h6>

                  <div>
                    <div className="row mt-lg-4 mt-3 mb-3">
                      <div className="col-lg-6 d-flex">
                        <div
                          style={{
                            width: "17%",
                            border: " 1px solid #b1b3b6",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            borderRight: "0px solid red",
                          }}
                          className="d-flex align-items-center "
                        >
                          <img
                            src="https://cdn-icons-png.flaticon.com/128/330/330451.png"
                            alt=""
                            className="img-fluid d-block mx-auto"
                            style={{
                              width: "60%",
                            }}
                          />
                        </div>
                        <div
                          className=""
                          style={{
                            width: "83%",
                          }}
                        >
                          <div class="field-wrapper">
                            <input
                              type="number"
                              name="phone number"
                              value={mobileNumber}
                              required
                              onChange={(e) =>
                                setMobileNumber(e.target.value.slice(0, 10))
                              }
                              autoComplete="new-password"
                              className={`${
                                mobileNumber.length > 0 && "bg-white"
                              }`}
                              style={{
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                              id=""
                            />
                            <div class="field-placeholder">
                              <span>Mobile Number*</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 mt-lg-0">
                        {" "}
                        <div class="field-wrapper">
                          <input
                            type="email"
                            name="email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            className={`${email.length > 0 && "bg-white"}`}
                            autoComplete="new-password"
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div class="field-placeholder">
                            <span>Email*</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 mt-lg-4">
                        {" "}
                        <div class="field-wrapper">
                          <input
                            type="text"
                            name="Full Name"
                            value={name}
                            required
                            autoComplete="new-password"
                            onChange={(e) => setName(e.target.value)}
                            className={`${name.length > 0 && "bg-white"}`}
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div class="field-placeholder">
                            <span>Full Name*</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 mt-lg-4">
                        {" "}
                        <div class="field-wrapper">
                          <input
                            type="number"
                            name="pincode"
                            autoComplete="new-password"
                            required
                            value={pincode}
                            onChange={(e) =>
                              setPincode(e.target.value.slice(0, 4))
                            }
                            className={`${pincode.length > 0 && "bg-white"}`}
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div class="field-placeholder">
                            <span>Postcode*</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-4 mt-lg-4">
                        {" "}
                        <div class="field-wrapper">
                          <input
                            type="text"
                            name="Address"
                            autoComplete="new-password"
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                            className={`${
                              addressLine1.length > 0 && "bg-white"
                            }`}
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div class="field-placeholder">
                            <span>Apartment, suite, etc</span>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-lg-12 mt-4 mt-lg-4">
                        {" "}
                        <div class="field-wrapper">
                          <input
                            type="text"
                            name="Address"
                            autoComplete="new-password"
                            required
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                            className={`${
                              addressLine2?.length > 0 && "bg-white"
                            }`}
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div class="field-placeholder">
                            <span>Address*</span>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-lg-6 mt-4 mt-lg-4">
                        {" "}
                        <div class="field-wrapper">
                          <input
                            value={city}
                            autoComplete="new-password"
                            onChange={(e) => setCity(e.target.value)}
                            className={`${city.length > 0 && "bg-white"}`}
                            type="text"
                            name="city"
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div class="field-placeholder">
                            <span>City*</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 mt-lg-4">
                        {" "}
                        <div class="field-wrapper">
                          <select
                            name="state"
                            id=""
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          >
                            <option selected disabled>
                              State
                            </option>
                            <option value="Australian Capital Territory">
                              Australian Capital Territory
                            </option>
                            <option value="New South Wales">
                              New South Wales
                            </option>
                            <option value="Northern Territory">
                              Northern Territory
                            </option>
                            <option value="Queensland">Queensland</option>
                            <option value="South Australia">
                              South Australia
                            </option>
                            <option value="Tasmania">Tasmania</option>
                            <option value="Victoria">Victoria</option>
                            <option value="Western Australia">
                              Western Australia
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-lg-flex p-2 d-none">
                    <p
                      className="col-8"
                      style={{
                        color: "grey",
                        fontSize: "0.95em",
                      }}
                    >
                      Personalize your ride with our wide selection of bicycle
                      accessories
                    </p>
                    <div className=" col-4">
                      <button
                        type="button"
                        className="btn w-100 ps-1 pe-1 pt-2 pb-2 "
                        style={{
                          background: "#cedfe0",
                          color: "grey",
                          fontSize: "0.8em",
                          outline: "0px",
                          border: "0px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i class="fa-solid fa-plus"></i> View Accessories
                      </button>
                    </div>
                  </div>
                  <div className="cart-items d-none d-lg-block p-3">
                    {cartItems.map((item) => (
                      <div className="cart-item mb-3 p-lg-2 pt-3 pb-3 row  ">
                        <div className="col-lg-3 col-4  p-0">
                          <img
                            src={item.cartImages[item.color]}
                            alt=""
                            className=" img-fluid  "
                          />
                        </div>
                        <div className="col-4 offset-1 pt-3  ">
                          <h6
                            className="mb-1"
                            style={{
                              fontWeight: "700",
                              fontSize: "20px",
                            }}
                          >
                            {item.name}{" "}
                          </h6>
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "0.85em",
                              color: "grey",
                            }}
                          >
                            ({item.models[item.color].colorName})
                          </p>
                          {item.variant && (
                            <p
                              className="mb-2"
                              style={{
                                fontSize: "0.85em",
                                color: "grey",
                              }}
                            >
                              {item.variant[item.variantType].variantName}
                            </p>
                          )}
                          <h6
                            className="mb-0 mt-2"
                            style={{
                              fontWeight: "700",
                              fontSize: "0.9em",
                              color: "#3CAB1F",
                            }}
                          >
                            MRP: {currencyFormat(item.accPrice)}
                          </h6>
                        </div>
                        <div className="col-3 offset-1 pt-3 position-relative ">
                          <div className="d-flex">
                            <div
                              className="col-4"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                class="fa-solid fa-minus"
                                onClick={(e) => {
                                  if (item.qty - 1 === 0) {
                                    removeFromCartHandeler(item.product);
                                  } else {
                                    dispatch(
                                      addToCart(
                                        item.product,
                                        item.color,
                                        Number(item.qty - 1),
                                        item.variantType
                                      )
                                    );
                                  }
                                }}
                              ></i>
                            </div>
                            <p className="col-4 text-center">{item.qty}</p>
                            <div
                              className="col-4 text-end"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                class="fa-solid fa-plus"
                                onClick={(e) => {
                                  dispatch(
                                    addToCart(
                                      item.product,
                                      item.color,
                                      Number(item.qty + 1),
                                      item.variantType
                                    )
                                  );
                                }}
                              ></i>
                            </div>

                            <div
                              className="position-absolute"
                              style={{
                                bottom: "0%",
                                right: "0%",
                              }}
                            ></div>
                          </div>
                          <i
                            style={{
                              color: "#b8baba",
                              cursor: "pointer",
                            }}
                            onClick={() => removeFromCartHandeler(item.product)}
                            class="fa-sharp mt-3 text-end d-block fa-solid fa-trash"
                          ></i>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-1 d-none justify-content-center pb-5 d-lg-flex">
                <div className="border" style={{ width: "1px" }}></div>
              </div>
              <div className="col-lg-5   ps-lg-5 pe-lg-5 p-1">
                <div className="p-2 mt-3 mt-lg-2 pt-0">
                  <div className=" pb-0 mb-3 d-none d-lg-block">
                    <div class="field-wrapper">
                      <input
                        type="text"
                        value={promocode}
                        autoComplete="new-password"
                        className={`${promocode.length > 0 && "bg-white"}`}
                        onChange={(e) =>
                          setPromocode(e.target.value.toUpperCase().trim())
                        }
                        name="promocode"
                        style={{
                          border: "2px dotted  #b1b3b6",
                        }}
                        id=""
                      />
                      <div class="field-placeholder">
                        <span>Apply Promocode</span>
                      </div>
                    </div>
                    {promocodes && promocode.length > 2 ? (
                      promocodes.length > 0 ? (
                        <p
                          className="col-8 mt-1 text-success"
                          style={{
                            fontSize: "0.7em",
                          }}
                        >
                          Promocode Applied
                        </p>
                      ) : (
                        <p
                          className="col-8 mt-1 text-danger"
                          style={{
                            fontSize: "0.7em",
                          }}
                        >
                          Invaid Promocode
                        </p>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="order-summary">
                    <h6 className="cart-heading p-lg-4 p-3 pb-lg-2 mb-0">
                      ORDER SUMMARY
                    </h6>
                    <div
                      className="position-relative  "
                      style={{ height: "20px" }}
                    >
                      <div
                        className=" w-100 position-absolute"
                        style={{
                          borderBottom: "2px  dotted #BCB5B5",
                          top: "50%",
                        }}
                      ></div>
                      <div
                        className=" position-absolute "
                        style={{
                          background: "#fff",
                          height: "20px",
                          borderRadius: "50%",
                          width: "20px",
                          left: "-2%",
                        }}
                      ></div>
                      <div
                        className=" position-absolute "
                        style={{
                          background: "#fff",
                          borderRadius: "50%",
                          height: "20px",
                          width: "20px",
                          right: "-2%",
                        }}
                      ></div>
                    </div>

                    <div className="mt-3 p-lg-4 p-3 pt-0 pb-lg-0 pt-lg-0">
                      <div className=" justify-content-between d-flex">
                        <p
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          Items
                        </p>
                        <p
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          {cartItems.reduce((acc, item) => acc + item.qty, 0)}
                        </p>
                      </div>
                      <div className=" justify-content-between d-flex">
                        <p
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          Sub Total
                        </p>
                        <p
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          {currencyFormat(
                            cartItems.reduce(
                              (acc, item) => acc + item.qty * item.accPrice,
                              0
                            )
                          )}
                        </p>
                      </div>
                      <div className=" justify-content-between d-flex">
                        <p
                          className="mb-2 col-6"
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          Delivery
                        </p>
                        <p
                          className="mb-2 text-end"
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          <span className="col-5  text-end">
                            <span className="">$49</span>{" "}
                          </span>
                        </p>
                      </div>

                      {promocodes &&
                      promocodes.length > 0 &&
                      promocodes[0].codeType === "price"
                        ? cartItems.find((value) => {
                            return promocodes[0].productsFor.includes(
                              value.product
                            );
                          }) && (
                            <div className="mt-1 justify-content-between d-flex">
                              <p
                                style={{
                                  fontSize: "0.9em",
                                  color: "grey",
                                }}
                              >
                                Discount
                              </p>
                              <p
                                style={{
                                  fontSize: "0.9em",
                                  color: "grey",
                                }}
                              >
                                - {currencyFormat(promocodes[0].codeValue)}
                              </p>
                            </div>
                          )
                        : promocodes &&
                          promocodes.length > 0 &&
                          promocodes[0].codeType !== "price" && (
                            <div className="mt-1 justify-content-between d-flex">
                              <p
                                style={{
                                  fontSize: "0.9em",
                                  color: "grey",
                                }}
                              >
                                Discount
                              </p>
                              <p
                                style={{
                                  fontSize: "0.9em",
                                  color: "grey",
                                }}
                              >
                                {promocodes[0].desc}
                              </p>
                            </div>
                          )}
                    </div>
                    <div
                      className="position-relative  "
                      style={{ height: "20px" }}
                    >
                      <div
                        className=" w-100 position-absolute"
                        style={{
                          borderBottom: "2px  dotted #BCB5B5",
                          top: "50%",
                        }}
                      ></div>
                      <div
                        className=" position-absolute "
                        style={{
                          background: "#fff",
                          height: "20px",
                          borderRadius: "50%",
                          width: "20px",
                          left: "-2%",
                        }}
                      ></div>
                      <div
                        className=" position-absolute "
                        style={{
                          background: "#fff",
                          borderRadius: "50%",
                          height: "20px",
                          width: "20px",
                          right: "-2%",
                        }}
                      ></div>
                    </div>
                    <div className=" p-3 pb-1 justify-content-between d-flex p-lg-4 pb-lg-2 pt-lg-2">
                      <p
                        className=""
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        Total
                      </p>
                      <p
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {currencyFormat(
                          Number(
                            cartItems.reduce(
                              (acc, item) => acc + item.qty * item.price,
                              0
                            ) + 49
                          )
                        )}
                      </p>
                    </div>
                  </div>
                  <div className=" mt-4">
                    <button
                      type="submit"
                      style={{
                        background: "#212020",
                        color: "#fff",
                      }}
                      className="btn p-3 w-100"
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="empty-cart">
          <div>
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/cart-removebg-preview.png"
              alt=""
              className="img-fluid"
            />
            <h1>Your Cart is Empty</h1>
            <p>
              Looks like you have not added anything to your cart. Go ahead a
              explore top categories of premium products
            </p>

            <Link to={"/"} className="btn  d-block rounded-0 btn-dark">
              Explore
            </Link>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default CartScreen;
