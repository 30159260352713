import {
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CONFIRM_CART_ITEMS,
	CART_RESET,
	CHECK_PROMOCODE_REQUEST,
	CHECK_PROMOCODE_SUCCESS,
	CHECK_PROMOCODE_FAIL,
} from '../Constants/cartConstants'

export const cartReducer = (
	state = { cartItems: [], shippingAddress: {} },
	action
) => {
	switch (action.type) {
		case CART_ADD_ITEM:
			const item = action.payload

			const existItem = state.cartItems.find(
				(x) => x.product === item.product && x.color === item.color
			)
			if (existItem) {
				return {
					...state,
					cartItems: state.cartItems.map((x) =>
						x.product === existItem.product && x.color === existItem.color
							? item
							: x
					),
				}
			} else {
				return {
					...state,
					cartItems: [...state.cartItems, item],
				}
			}

		case CART_REMOVE_ITEM:
			return {
				...state,
				cartItems: state.cartItems.filter((x) => x.product !== action.payload),
			}

		case CART_RESET:
			return {
				...state,
				cartItems: [],
			}

		default:
			return state
	}
}

export const confirmCartItemsReducer = (state = {}, action) => {
	switch (action.type) {
		case CONFIRM_CART_ITEMS:
			return {
				loading: false,
				confirmedItems: action.payload,
			}

		default:
			return state
	}
}

export const checkPromocodeReducer = (state = {}, action) => {
	switch (action.type) {
		case CHECK_PROMOCODE_REQUEST:
			return { loading: true }
		case CHECK_PROMOCODE_SUCCESS:
			return {
				loading: false,
				promocodes: action.payload,
			}
		case CHECK_PROMOCODE_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}
