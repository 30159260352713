import React, { useEffect, useState } from "react"
import axios from "axios"
import "../CSS/prebook.css"

const PrelaunchScreen = () => {
	const [email, setEmail] = useState("")
	const [result, setResult] = useState("")

	useEffect(() => {
		if (result) {
			alert(
				"THANK YOU for visiting EMotorad, we hope that our journey together is limitless!",
			)
		}
	}, [result])

	const collectEmail = async (e) => {
		try {
			e.preventDefault()
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			const { data } = await axios.post(
				"/api/form/prebook",
				{
					email,
				},
				config,
			)

			setResult(data)
			setEmail("")
		} catch (error) {}
	}
	return (
		<div className=''>
			<div className='container-fluid pb-0   section-one-bg bg-dark '>
				<div className='container p-0'>
					<div className='d-flex align-items-center justify-content-between'>
						<h2 className='font-x text-light'>
							X-FACTOR
						</h2>
						<div className='col-6 col-md-2'>
							<img
								src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prelaunch/Logos/white+2.png'
								className='img-fluid '
								alt=''
							/>
						</div>
					</div>
					<div className='section-one-text mb-0'>
						<div className='text-light '>
							<h1 className='font-ows'>
								JOIN THE WAIT LIST
							</h1>
							<p className='h3'>
								The X-Factor range starts from{" "}
								<span className='h1 fw-bold'>
									₹24,999/-
								</span>
							</p>
						</div>
						<div className=' mt-4'>
							<p className='secondary-text text-light'>
								Type in your email id to join the
								waitlist of the lndia's
								most-awaited e-bike range,
								<p
									className=' h2 mt-2 mb-2 mb-0 fw-bold '
									style={{ color: "#12AF67" }}>
									X-FACTOR
								</p>
								Get exclusive discounts, offers,
								gifts & more
							</p>
						</div>
						<div className='mt-lg-5 d-flex'>
							<form
								onSubmit={collectEmail}
								className='d-flex w-100'>
								<input
									type='email'
									value={email}
									onChange={(e) => {
										setEmail(e.target.value)
									}}
									placeholder='Email Address'
									className='email-input col-8 '
								/>
								<button className='col-4'>
									Notify me
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			<picture>
				<source
					media='(min-width:650px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+2.png'
				/>
				<source
					media='(max-width:651px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Mobile/BANNER+2.png'
				/>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+2.png'
					alt='Doodle'
					className='img-fluid'
				/>
			</picture>
			<picture>
				<source
					media='(min-width:650px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+3.png'
				/>
				<source
					media='(max-width:651px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Mobile/BANNER+3.png'
				/>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+3.png'
					alt='Doodle'
					className='img-fluid'
				/>
			</picture>
			<picture>
				<source
					media='(min-width:650px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+4.png'
				/>
				<source
					media='(max-width:651px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Mobile/BANNER+4.png'
				/>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+4.png'
					alt='Doodle'
					className='img-fluid'
				/>
			</picture>
			<picture>
				<source
					media='(min-width:650px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+5.png'
				/>
				<source
					media='(max-width:651px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Mobile/BANNER+5.jpg'
				/>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+5.png'
					alt='Doodle'
					className='img-fluid'
				/>
			</picture>

			<picture>
				<source
					media='(min-width:650px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+8.png'
				/>
				<source
					media='(max-width:651px)'
					srcset='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Mobile/BANNER+8.png'
				/>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Desktop/BANNER+8.png'
					alt='Doodle'
					className='img-fluid'
				/>
			</picture>
			<div class='ratio ratio-16x9'>
				<iframe
					src='https://www.youtube.com/embed/6wBaCidxPZA'
					title='YouTube video'
					allowfullscreen></iframe>
			</div>
		</div>
	)
}

export default PrelaunchScreen
