import React, { useEffect, useState } from "react"
import Navbar from "../Components/Navbar"
import "../CSS/testride.css"
import { useNavigate } from "react-router-dom"
import { getAllDealers } from "../Actions/dealerAction"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { createTestRide } from "../Actions/testrideAction"
import Meta from "../Components/Meta"
import Footer from "../Components/Footer"
const TestRideScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [address, setAddress] = useState("")

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] =
		useState("")

	const allDealers = useSelector(
		(state) => state.allDealers,
	)
	const { dealers: dealersFromDB } = allDealers

	const bookTestride = useSelector(
		(state) => state.bookTestride,
	)
	const { testride } = bookTestride

	useEffect(() => {
		// dispatch(getAllDealers())
	}, [])

	const book = (e) => {
		e.preventDefault()
		dispatch(
			createTestRide(
				name,
				email,
				phoneNumber,
				address,
			),
		)

		navigate("/testride-scheduled")
	}

	const showCallender = () => {
		const mydate =
			document.getElementById("mydate")
		mydate.showPicker()
	}

	return (
		<>
			<Navbar />
			<div className='container '>
				<Meta
					title={
						"Book Your Test Ride Now | EMotorad"
					}
					description={
						"EMotorad brings a range of the best electric bikes that are affordable &amp; futuristic. Crafted with the state of art components, at the best price. Book your test ride now!"
					}
				/>

				<div className='row ps-3'></div>
				<h4 className='testride-heading mb-3 font-mon mt-lg-5 mt-3'>
					Book a Test Ride{" "}
				</h4>
				<div className='row align-items-center mt-lg-4 '>
					<div className='col-lg-6'>
						<img
							src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/Community+png+(1).png'
							className='img-fluid d-lg-none '
							alt=''
						/>
						<p className='mt-0'>
							Step 1: Fill In Your Personal
							Details
						</p>
						<p>
							Step 2: Fill In Your Address Along
							With Your Pincode and Preferred Time
							of Contacting{" "}
						</p>{" "}
						<div className='mb-4 mt-5 testride-form '>
							<form onSubmit={book}>
								<div class='form-floating'>
									<input
										type='text'
										required
										value={name}
										autoComplete='off'
										onChange={(e) =>
											setName(e.target.value)
										}
										id='floatingPassword'
										placeholder='FULL NAME'
									/>
								</div>
								<div class='form-floating'>
									<input
										type='number'
										required
										value={phoneNumber}
										onChange={(e) => {
											setPhoneNumber(
												e.target.value.slice(
													0,
													10,
												),
											)
										}}
										id='floatingPassword'
										minLength={"10"}
										maxLength='10'
										autoComplete='off'
										placeholder='PHONE NUMBER'
									/>
								</div>
								<div class='form-floating mb-3  '>
									<input
										type='email'
										id='floatingInput'
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										required
										autoComplete='off'
										placeholder='EMAIL'
									/>
								</div>
								<div class='form-floating'>
									<input
										type='text'
										id='floatingInput'
										value={address}
										onChange={(e) =>
											setAddress(e.target.value)
										}
										required
										autoComplete='off'
										placeholder='ADDRESS'
									/>
								</div>

								<button
									type='submit'
									className='submit d-block'>
									Confirm Appointment
								</button>
							</form>
						</div>
					</div>
					<div className='col-lg-6 d-none d-lg-block'>
						<img
							src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/Community+png+(1).png'
							className='img-fluid '
							alt=''
						/>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default TestRideScreen
