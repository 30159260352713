import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta name='keyword' content={keywords} />
		</Helmet>
	)
}

Meta.defaultProps = {
	title: 'EMX | Best and Specialized Electric Mountain Bike - Emotorad',
	description: 'EMX by Emotorad provides you the complete experience of riding an electric bike with its amazing features. Book your Test Ride Now!',
	keywords: '',
}

export default Meta