import React, { useEffect } from 'react'
import ordersuccess from '../images/order-confirm.png'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CART_RESET } from '../Constants/cartConstants'
import { ORDER_CONFIRM_RESET } from '../Constants/orderConstants'
const OrderConfirmScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const orderConfirm = useSelector((state) => state.orderConfirm)
	const { orderStatus } = orderConfirm

	useEffect(() => {
		localStorage.removeItem('cartItems')
		dispatch({
			type: CART_RESET,
		})
		dispatch({
			type: ORDER_CONFIRM_RESET,
		})
	}, [dispatch, navigate])

	return (
		<div
			style={{ height: '90vh' }}
			className='container'
		>
			<div className='row  h-100 align-items-center position-relative'>
				<div className='col-12 col-lg-6 offset-lg-3'>
					
					<h6
						className='text-center'
						style={{
							lineHeight: '1.5em',
							fontSize: '1.1em',
							fontWeight: '700',
						}}
					>
						{' '}
						We’re happy to let you know that we’ve received your order.{' '}
					</h6>

					<p
						className='mt-lg-4 mt-4 text-center'
						style={{
							lineHeight: '1.5em',
							fontSize: '0.9em',
							fontWeight: '200',
						}}
					>
						You will be receving a confirmation email with the order details
					</p>
				</div>

				<Link
					className='d-block mx-auto text-center'
					to='/'
				>
					Go back
				</Link>
			</div>
		</div>
	)
}

export default OrderConfirmScreen
