import "@google/model-viewer"

import HomeScreen from "./Screens/HomeScreen"
import ProductScreen from "./Screens/ProductScreen"
import CartScreen from "./Screens/CartScreen"
import ShippingScreen from "./Screens/ShippingScreen"
import LoginScreen from "./Screens/LoginScreen"
import OrderSummaryScreen from "./Screens/OrderSummaryScreen"
import OrderConfirmScreen from "./Screens/OrderConfirmScreen"
import TestRideScreen from "./Screens/TestRideScreen"
import TestRideConfirmScreen from "./Screens/TestRideConfirmScreen"
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom"
import AboutScreen from "./Screens/AboutScreen"
import ContactUsScreen from "./Screens/ContactUsScreen"
import EMIScreen from "./Screens/EMIScreen"
import PartnerScreen from "./Screens/PartnerScreen"
import WarrantyScreen from "./Screens/WarrantyScreen"
import InsuranceScreen from "./Screens/InsuranceScreen"
import ProfileScreen from "./Screens/ProfileScreen"
import MyOrdersScreen from "./Screens/MyOrdersScreen"
import MyOrderDetails from "./Screens/MyOrderDetails"
import ThankYouPage from "./Screens/ThankYouPage"
import CommunityScreen from "./Screens/CommunityScreen"
import TermsAndCondition from "./Screens/TermsAndCondition"
import CookiePolicy from "./Screens/CookiePolicy"
import PrivacyPolicyScreen from "./Screens/PrivacyPolicyScreen"
import BlogsScreen from "./Screens/BlogsScreen"
import BlogScreen from "./Screens/BlogScreen"

import TrackingScreen from "./Screens/TrackingScreen"
import AccessoriesScreen from "./Screens/AccessoriesScreen"
import AccessoriesCategoryScreen from "./Screens/AccessoriesCategoryScreen"
import AllAccessoriesScreen from "./Screens/AllAccessoriesScreen"
import ReturnPolicyScreen from "./Screens/ReturnPolicyScreen"
import NewsScreen from "./Screens/NewsScreen"
import DigitalMediaScreen from "./Screens/DigitalMediaScreen"
import PrelaunchScreen from "./Screens/PrelaunchScreen"
import EventEliteLandingScreen from "./Screens/EventEliteLandingScreen"
import EventXpLandingScreen from "./Screens/EventXpLandingScreen"
import Event3DModelScreen from "./Screens/Event3DModelScreen"
import PreBookScreen from "./Screens/PreBookScreen"
import PreOrderThankyouScreen from "./Screens/PreOrderThankyouScreen"
import XFactorPrebookScreen from "./Screens/XFactorPrebookScreen"
import AllBikesScreen from "./Screens/AllBikesScreen"

function App() {
	return (
		<Router>
			<Routes>
				<Route
					path='/allbikes'
					index
					element={<AllBikesScreen />}
				/>
				<Route
					path='/prebook'
					index
					element={<PrelaunchScreen />}
				/>
				<Route
					path='/preorder-success'
					index
					element={<PreOrderThankyouScreen />}
				/>
				<Route
					path='/'
					index
					element={<HomeScreen />}
				/>
				<Route
					path='/ourstory'
					element={<AboutScreen />}
				/>
				<Route
					path='/bikes/:name'
					element={<ProductScreen />}
				/>
				<Route
					path='/accessories/category/:name'
					element={<AccessoriesCategoryScreen />}
				/>

				<Route
					path='/accessories/:name'
					element={<AccessoriesScreen />}
				/>
				<Route
					path='/blogs'
					element={<BlogsScreen />}
				/>
				<Route
					path='/media-kit'
					element={<DigitalMediaScreen />}
				/>
				<Route
					path='/blog/:id'
					element={<BlogScreen />}
				/>
				<Route
					path='/news'
					element={<NewsScreen />}
				/>
				<Route
					path='/contactus'
					element={<ContactUsScreen />}
				/>
				<Route
					path='/warranty'
					element={<WarrantyScreen />}
				/>
				<Route
					path='/emi'
					element={<EMIScreen />}
				/>
				<Route
					path='/insurance'
					element={<InsuranceScreen />}
				/>
				<Route
					path='/partner-with-us'
					element={<PartnerScreen />}
				/>
				<Route
					path='/shipping'
					element={<ShippingScreen />}
				/>
				<Route
					path='/cart'
					element={<CartScreen />}
				/>
				<Route
					path='/login'
					element={<LoginScreen />}
				/>
				<Route
					path='/testride'
					element={<TestRideScreen />}
				/>
				<Route
					path='/testride-scheduled'
					element={<TestRideConfirmScreen />}
				/>
				<Route
					path='/ordersummary'
					element={<OrderSummaryScreen />}
				/>
				<Route
					path='/thank-you'
					element={<OrderConfirmScreen />}
				/>
				<Route
					path='/profile'
					element={<ProfileScreen />}
				/>
				<Route
					path='/profile/orders'
					element={<MyOrdersScreen />}
				/>
				<Route
					path='/profile/orders/:id'
					element={<MyOrderDetails />}
				/>
				<Route
					path='/submitted'
					element={<ThankYouPage />}
				/>
				<Route
					path='/community'
					element={<CommunityScreen />}
				/>
				<Route
					path='/terms&condition'
					element={<TermsAndCondition />}
				/>
				<Route
					path='/cookie-policy'
					element={<CookiePolicy />}
				/>
				<Route
					path='/privacy-policy'
					element={<PrivacyPolicyScreen />}
				/>

				<Route
					path='/track'
					element={<TrackingScreen />}
				/>
				<Route
					path='/all-accessories'
					element={<AllAccessoriesScreen />}
				/>
				<Route
					path='/refund-and-cancellation-policy'
					element={<ReturnPolicyScreen />}
				/>
				<Route
					path='/event-elite'
					element={<EventEliteLandingScreen />}
				/>
				<Route
					path='/event-xp'
					element={<EventXpLandingScreen />}
				/>
				<Route
					path='/launchbikes/:bike'
					element={<Event3DModelScreen />}
				/>

				<Route
					path='/preorder'
					element={<PreBookScreen />}
				/>

				<Route
					path='/x-factor-preorder/:id'
					element={<XFactorPrebookScreen />}
				/>

				{/*  */}

				{/*  */}

				{/* URL Redirects */}

				<Route
					path='/Warranty.aspx'
					element={<Navigate to='/warranty' />}
				/>

				<Route
					path='/pages/emx'
					element={<Navigate to='/bikes/emx' />}
				/>

				<Route
					path='/products/lil-e'
					element={<Navigate to='/bikes/lile' />}
				/>

				<Route
					path='/pages/lil-e-landing-page'
					element={<Navigate to='/bikes/lile' />}
				/>

				<Route
					path='/products/t-rexplus'
					element={
						<Navigate to='/bikes/t-rex+' />
					}
				/>

				<Route
					path='/pages/t-rex-plus-prebook'
					element={
						<Navigate to='/bikes/t-rex+' />
					}
				/>

				<Route
					path='/products/t-rex'
					element={
						<Navigate to='/bikes/t-rex+' />
					}
				/>

				<Route
					path='/pages/contact-us-original'
					element={<Navigate to='/contactus' />}
				/>

				<Route
					path='/products/privacy.html'
					element={
						<Navigate to='/privacy-policy' />
					}
				/>

				<Route
					path='/products/cookie.html'
					element={
						<Navigate to='/cookie-policy' />
					}
				/>

				<Route
					path='/partner-with-us'
					element={<Navigate to='/partner' />}
				/>

				<Route
					path='/Community.aspx'
					element={<Navigate to='/community' />}
				/>

				<Route
					path='/contactus@emtorad.com'
					element={<Navigate to='/contactus' />}
				/>

				<Route
					path='/Products.aspx'
					element={<Navigate to='/' />}
				/>

				<Route
					path='/products/moovway-x?variant=32606162944045'
					element={<Navigate to='/' />}
				/>

				<Route
					path='/FAQs.aspx'
					element={<Navigate to='/' />}
				/>

				<Route
					path='/contact-us'
					element={<Navigate to='/contactus' />}
				/>

				<Route
					path='/TermsConditions.aspx'
					element={
						<Navigate to='/terms&condition' />
					}
				/>

				<Route
					path='/pages/doodle-final'
					element={
						<Navigate to='/bikes/doodle' />
					}
				/>

				<Route
					path='/pages/trex-final'
					element={
						<Navigate to='/bikes/t-rex+' />
					}
				/>

				<Route
					path='/Insurance.aspx'
					element={<Navigate to='/insurance' />}
				/>

				<Route
					path='/Media/Brochures/T-REX.pdf'
					element={<Navigate to='/warranty' />}
				/>

				<Route
					path='/pages/emx-final-1'
					element={<Navigate to='/bikes/emx' />}
				/>

				<Route
					path='/products/emx-indias-first-dual-suspension-e-bike'
					element={<Navigate to='/bikes/emx' />}
				/>

				<Route
					path='/pages/emi-1'
					element={<Navigate to='/emi' />}
				/>

				<Route
					path='/pages/test-ride-form'
					element={<Navigate to='/' />}
				/>

				<Route
					path='/privacy.html'
					element={
						<Navigate to='/privacy-policy' />
					}
				/>

				<Route
					path='/cookie.html'
					element={
						<Navigate to='/cookie-policy' />
					}
				/>

				<Route
					path='/pages/privacy.html'
					element={
						<Navigate to='/privacy-policy' />
					}
				/>

				<Route
					path='/pages/cookie.html'
					element={
						<Navigate to='/cookie-policy' />
					}
				/>

				<Route
					path='/product/Emx'
					element={<Navigate to='/bikes/emx' />}
				/>

				<Route
					path='/insurance/emx'
					element={<Navigate to='/insurance' />}
				/>

				<Route
					path='/insurance/t-rex'
					element={<Navigate to='/insurance' />}
				/>

				<Route
					path='/pages/emi-1'
					element={<Navigate to='/emi' />}
				/>

				<Route
					path='/partner-with-us/mailtocontactus@emotorad.com:'
					element={<Navigate to='/partner' />}
				/>

				<Route
					path='/Cart.aspx'
					element={<Navigate to='/cart' />}
				/>

				<Route
					path='/product/emx'
					element={<Navigate to='/bikes/emx' />}
				/>

				<Route
					path='/collections/frontpage'
					element={<Navigate to='' />}
				/>

				<Route
					path='/book-test-ride'
					element={<Navigate to='/testride' />}
				/>

				<Route
					path='/AboutUs.aspx'
					element={<Navigate to='/ourstory' />}
				/>

				<Route
					path='/product/emx'
					element={<Navigate to='/bikes/emx' />}
				/>

				<Route
					path='/allproduct/emx'
					element={<Navigate to='/' />}
				/>

				<Route
					path='/trex'
					element={
						<Navigate to='/bikes/t-rex+' />
					}
				/>

				<Route
					path='/doodle'
					element={
						<Navigate to='/bikes/doodle' />
					}
				/>

				<Route
					path='/product'
					element={<Navigate to='/' />}
				/>

				<Route
					path='/product/t-rex'
					element={
						<Navigate to='/bikes/t-rex+' />
					}
				/>

				<Route
					path='/product/trex'
					element={
						<Navigate to='/bikes/t-rex+' />
					}
				/>

				<Route
					path='/product/emx'
					element={<Navigate to='/bikes/emx' />}
				/>

				<Route
					path='/bikes'
					element={<Navigate to='/' />}
				/>

				<Route
					path='/emi'
					element={<Navigate to='/emi' />}
				/>

				<Route
					path='/contact'
					element={<Navigate to='/contact' />}
				/>
				<Route
					path='/products/trexplus'
					element={
						<Navigate to='/bikes/t-rex+' />
					}
				/>
			</Routes>
		</Router>
	)
}

export default App
