import React from "react"

const PreOrderThankyouScreen = () => {
	return (
		<div className='p-2'>
			<img
				src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prelaunch/landingpage/X-Factor+Banner+1.png'
				className='img-fluid d-block mx-auto'
				alt=''
			/>
		</div>
	)
}

export default PreOrderThankyouScreen
