import React from "react"
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"
import "@splidejs/react-splide/css/sea-green"
import {
	Splide,
	SplideSlide,
} from "@splidejs/react-splide"
const DigitalMediaScreen = () => {
	return (
		<>
			<Navbar />
			<div className='container mt-lg-5 mt-4'>
				<p className='pb-3 font-mon warranty-border-heading  mt-2 pt-lg-2'>
					BRAND ASSETS
				</p>
				<hr />
				<div
					className='container card p-0'
					style={{ border: "1px solid white" }}>
					{/* first */}
					<h5
						className='font-mon p-2  justify-content-between d-flex align-items-center'
						data-bs-toggle='collapse'
						style={{ cursor: "pointer" }}
						href='#collapseExample1'>
						<span>Product Portfolio</span>{" "}
						<i class='fa-solid fw-bold fa-plus'></i>
					</h5>
					<div
						class='collapse mt-0 '
						id='collapseExample1'>
						<div class=' container'>
							<div className='row'>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/emx/old/emx1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											EMX |{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/emx.rar'
												download>
												Images
											</a>{" "}
											|{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/pdfs/EMX+Brochure+(1).jpg'
												download>
												Brochure
											</a>
										</h6>
									</div>
								</div>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/doodle/D1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											Doodle |{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/doodle.zip'
												download>
												Images
											</a>{" "}
											|{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/pdfs/Doodle+(2).jpg'
												download>
												Brochure
											</a>
										</h6>
									</div>
								</div>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/t-rex%2B/red/trex+r1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											T-rex+ |{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/t-rex.zip'
												download>
												Images
											</a>{" "}
											|{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/pdfs/T-REX+(1).jpg'
												download>
												Brochure
											</a>
										</h6>
									</div>
								</div>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/lile/red/lile+r1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											Lil E |{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/lile.rar'
												download>
												Images
											</a>{" "}
										</h6>
									</div>
								</div>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/B1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											X1 |{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/X1.zip'
												download>
												Images
											</a>
											{" | "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/pdfs/Artboard+1+(1).jpg'
												download>
												Brochure
											</a>
										</h6>
									</div>
								</div>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											X2 |{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/X2.zip'
												download>
												Images
											</a>{" "}
											{" | "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/pdfs/Artboard+2+(1).jpg'
												download>
												Brochure
											</a>
										</h6>
									</div>
								</div>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/G1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											X3 |{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/X3.zip'
												download>
												Images
											</a>{" "}
											{" | "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/pdfs/Artboard+3+(2).jpg'
												download>
												Brochure
											</a>
										</h6>
									</div>
								</div>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/M057/1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											Desert Eagle |{" "}
											<a href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/desert+eagle.zip'>
												Images
											</a>{" "}
											|{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/pdfs/Desert+Eagle+2.jpg'
												download>
												Brochure
											</a>
										</h6>
									</div>
								</div>
								<div className='col-md-3 mt-1 p-2 col-12'>
									<div
										className='  p-2'
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/m058/B1.png'
											alt=''
											className='img-fluid'
										/>
										<h6>
											Night Hawk |{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/product+images/night+hawk.zip'
												download>
												Images
											</a>{" "}
											|{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/pdfs/Nighthawk+(1).jpg'
												download>
												Brochure
											</a>
										</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr className='m-0' />
					<h5
						className='font-mon p-2  justify-content-between d-flex align-items-center'
						data-bs-toggle='collapse'
						style={{ cursor: "pointer" }}
						href='#collapseExample2'>
						<span>EMotorad Leadership & Bio</span>{" "}
						<i class='fa-solid fw-bold fa-plus'></i>
					</h5>
					<div
						class='collapse mt-0 '
						id='collapseExample2'>
						<div class=' container mb-3'>
							<div className='row '>
								<div className='col-md-3  h-100 col-12 mt-3'>
									<div
										className='  justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/Rajib+Gangopadhyay-EM+Founder.jpg'
											alt='rajib'
											className='img-fluid'
										/>
										<h6 className='mt-2 mb-0 d-block text-dark fw-bold'>
											Rajib Gangopadhyay (MD &
											Founder)
										</h6>
										<p className='mt-2'>
											Download{" "}
											<a href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/EMotorad+Leadership+%26+Bio/Rajib.zip'>
												Photos
											</a>{" "}
											|{" "}
											<a href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/EMotorad+Leadership+%26+Bio/Rajib+Gangopadhyay.pdf'>
												Bio
											</a>{" "}
										</p>
									</div>
								</div>
								<div className='col-md-3  h-100 col-12 mt-3'>
									<div
										className='  justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/Kunal+Gupta+-+EM+CEO.jpg'
											alt='kunal'
											className='img-fluid'
										/>
										<h6 className='mt-2 mb-0 d-block text-dark fw-bold'>
											Kunal Gupta (CEO &
											Co-founder)
										</h6>
										<p className='mt-2'>
											Download{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/EMotorad+Leadership+%26+Bio/Kunal.zip'
												download>
												Photos
											</a>{" "}
											|{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/EMotorad+Leadership+%26+Bio/Kunal.zip'
												download>
												Bio
											</a>
										</p>
									</div>
								</div>
								<div className='col-md-3  h-100 col-12 mt-3'>
									<div
										className='  justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/Aditya+Oza-EM+CMO.jpg'
											alt='adi'
											className='img-fluid'
										/>
										<h6 className='mt-2 mb-0 d-block text-dark fw-bold'>
											Aditya Oza (CMO &
											Co-founder)
										</h6>
										<p className='mt-2'>
											Download{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/EMotorad+Leadership+%26+Bio/Aditya.zip'
												download>
												Photos
											</a>{" "}
											|{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/EMotorad+Leadership+%26+Bio/Aditya+Oza.pdf'
												download>
												Bio
											</a>
										</p>
									</div>
								</div>
								<div className='col-md-3  h-100 col-12 mt-3'>
									<div
										className='  justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/Sumedh+Battewar-EM+CBO.jpg'
											alt='sumedh'
											className='img-fluid'
										/>
										<h6 className='mt-2 mb-0 d-block text-dark fw-bold'>
											Sumedh Battewar (CBO &
											Co-founder)
										</h6>
										<p className='mt-2'>
											Download{" "}
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/EMotorad+Leadership+%26+Bio/Sumedh.zip'
												download>
												Photos
											</a>{" "}
											|
											<a
												href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/EMotorad+Leadership+%26+Bio/Sumedh+Battewar.pdf'
												download>
												Bio
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr className='m-0' />
					<h5
						className='font-mon p-2  justify-content-between d-flex align-items-center'
						data-bs-toggle='collapse'
						style={{ cursor: "pointer" }}
						href='#collapseExample3'>
						<span>
							Logos, Trademarks & Wordmarks
						</span>
						<i class='fa-solid fw-bold fa-plus'></i>
					</h5>
					<div
						class='collapse mt-0 '
						id='collapseExample3'>
						<div class=' container mb-3'>
							<div className='row align-items-center'>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/em+logo+ai+black-01.png'
											alt='media-kit-img'
											className='img-fluid w-75'
										/>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/em+logo+ai+black-01.png'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/em+logo+ai+green01.png'
											alt='media-kit-img'
											className='img-fluid w-75'
										/>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/em+logo+ai+green01.png'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/em+logo+ai+white-01.png'
											alt='media-kit-img'
											className='img-fluid w-75'
										/>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/em+logo+ai+white-01.png'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/Black-01.png'
											alt='media-kit-img'
											className='img-fluid w-75'
										/>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/Black-01.png'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/Green+logo.png'
											alt='media-kit-img'
											className='img-fluid w-75'
										/>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/Green+logo.png'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center  '
										style={{
											minHeight: "250px",
											background: "#f5f5f5",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<img
											src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/white+2.png'
											alt='media-kit-img'
											className='img-fluid w-75'
										/>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Logos/white+2.png'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
							</div>
						</div>
					</div>
					<hr className='m-0' />
					<h5
						className='font-mon p-2  justify-content-between d-flex align-items-center'
						data-bs-toggle='collapse'
						style={{ cursor: "pointer" }}
						href='#collapseExample4'>
						<span>Press Releases</span>
						<i class='fa-solid fw-bold fa-plus'></i>
					</h5>
					<div
						class='collapse mt-0 '
						id='collapseExample4'>
						<div class=' container'>
							<div className='row align-items-center'>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center p-3  '
										style={{
											minHeight: "250px",
											background: "#1a1a1a",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<h6 className='text-light'>
											EMotorad unveils an
											exclusive range of e-bikes
											and technology at EM Summit
											2023
										</h6>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Press+Releases/Pune+Press+Release+EMotorad+unveils+an+exclusive+range+of+ebikes+in+Pune.pdf'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center p-3  '
										style={{
											minHeight: "250px",
											background: "#1a1a1a",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<h6 className='text-light'>
											2022 Was A Year Of EV
											Investment For Green
											Frontier Capital; Aims To
											Invest In More Greentech
											Startups In 2023
										</h6>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Press+Releases/Green+Frontier+Capital+Release.pdf'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center p-3  '
										style={{
											minHeight: "250px",
											background: "#1a1a1a",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<h6 className='text-light'>
											EV brand EMotorad raises Rs
											24Cr in Pre-Series A funding
										</h6>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Press+Releases/Funding+Release.pdf'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center p-3  '
										style={{
											minHeight: "250px",
											background: "#1a1a1a",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<h6 className='text-light'>
											EMotorad targets expansion
											in Europe, plans series of
											product launch in 6 months
										</h6>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Press+Releases/Europe+Expansion+Release.pdf'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center p-3  '
										style={{
											minHeight: "250px",
											background: "#1a1a1a",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<h6 className='text-light'>
											EMotorad launches foldable
											e-bike at ₹49,999.
										</h6>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Press+Releases/DoodleLaunch+Release.pdf'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center p-3  '
										style={{
											minHeight: "250px",
											background: "#1a1a1a",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<h6 className='text-light'>
											EMotorad To Invest $3.5Mn In
											Capacity Expansion
										</h6>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Press+Releases/Capacity+Expansion+Release.pdf'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
								<div className='col-md-4 col-12 mt-3'>
									<div
										className=' d-flex justify-content-center align-items-center p-3  '
										style={{
											minHeight: "250px",
											background: "#1a1a1a",
											borderRadius: "7px",
											boxShadow:
												"-1px 1px 4px #c2c2c2",
										}}>
										<h6 className='text-light'>
											From B2B to B2C, how
											EMotorad founders found the
											right match for e-bikes in
											India
										</h6>
									</div>
									<a
										href='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/media-kit/Press+Releases/B2B+B2C+Release.pdf'
										download
										className='mt-1 d-block text-dark fw-bold'>
										Download
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default DigitalMediaScreen
