import React, { useEffect, useState } from "react"
import { GraphQLClient } from "graphql-request"
import { gql } from "graphql-request"
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"
import { useParams } from "react-router-dom"
import parse from "html-react-parser"
const BlogScreen = () => {
	const { id } = useParams()
	const weekday = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	]
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]

	const [blogPost, setBlogPost] = useState([])
	const hygraph = new GraphQLClient(
		"https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master",
	)

	const QUERY = gql`
		{
			post(
				where: { id: "${id}" }
			) {
                content {
                    text
                    html
                    markdown
                    raw
                  }
				title
                date
				tags
				coverImage {
					url
				}
			}
		}
	`
	useEffect(() => {
		if (id) {
			const getBlogs = async () => {
				const { post } = await hygraph.request(
					QUERY,
				)
				setBlogPost(post)
				// console.log(post)
			}
			getBlogs()
		}
	}, [id])

	return (
		<>
			<>
				<Navbar />
				<div className='container mt-lg-5 mt-4'>
					<hr />
					{blogPost.content && (
						<div>
							<h1 className='text-lg-center text-justify font-mon'>
								{blogPost.title}
							</h1>
							<div className='row mt-3'>
								<div className='col-md-3 d-none d-lg-block'>
									<h6 className='mb-2'>
										Published On
									</h6>
									<p className='fw-bold  font-mon'>
										<span className='pe-2 text-muted'>
											{
												weekday[
													new Date(
														blogPost.date,
													).getDay()
												]
											}
											,{" "}
										</span>

										<span className='pe-2 text-muted'>
											{new Date(
												blogPost.date,
											).getDate()}{" "}
											{""}
											{
												months[
													new Date(
														blogPost.date,
													).getMonth()
												]
											}
										</span>
										<span className='pe-2 text-muted'>
											{new Date(
												blogPost.date,
											).getFullYear()}
										</span>
									</p>
									<hr />
								</div>
								<div className='col-md-9 p-lg-3 '>
									<img
										style={{
											borderRadius: "10px",
										}}
										src={
											blogPost.coverImage &&
											blogPost.coverImage.url
										}
										className='img-fluid  d-block mx-auto'
										alt=''
									/>
									<div
										id='content'
										className='blog-content mt-4'>
										{parse(blogPost.content.html)}
									</div>
								</div>
							</div>

							<div></div>
						</div>
					)}
				</div>
				<Footer />
			</>
		</>
	)
}

export default BlogScreen
