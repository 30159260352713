import {
	CONTACT_US_FAIL,
	CONTACT_US_REQUEST,
	CONTACT_US_SUCCESS,
	EMI_FAIL,
	EMI_REQUEST,
	EMI_SUCCESS,
	INSURANCE_FAIL,
	INSURANCE_REQUEST,
	INSURANCE_SUCCESS,
	PARTNER_WITH_US_FAIL,
	PARTNER_WITH_US_REQUEST,
	PARTNER_WITH_US_SUCCESS,
	WARRANTY_FAIL,
	WARRANTY_REQUEST,
	WARRANTY_SUCCESS,
} from "../Constants/formConstants"
import axios from "axios"

export const contactUsCreate =
	(name, email, phoneNumber, message) =>
	async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			dispatch({ type: CONTACT_US_REQUEST })
			const { data } = await axios.post(
				"/api/form/contact",
				{
					name,
					email,
					phoneNumber,
					message,
				},
				config,
			)

			dispatch({
				type: CONTACT_US_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: CONTACT_US_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const emiCreate =
	(name, email, phoneNumber, bike, navigate) =>
	async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			dispatch({ type: EMI_REQUEST })
			const { data } = await axios.post(
				"/api/form/emi",
				{
					name,
					email,
					phoneNumber,
					bike,
				},
				config,
			)

			dispatch({
				type: EMI_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: EMI_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const partnerWithUsCreate =
	(
		name,
		email,
		phoneNumber,
		businessName,
		message,
	) =>
	async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			dispatch({ type: PARTNER_WITH_US_REQUEST })
			const { data } = await axios.post(
				"/api/form/partner",
				{
					name,
					email,
					phoneNumber,
					businessName,
					message,
				},
				config,
			)

			dispatch({
				type: PARTNER_WITH_US_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: PARTNER_WITH_US_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const warrantyCreate =
	(
		name,
		email,
		phoneNumber,
		bike,
		frameNumber,
		invoiceNumber,
		invoice,
	) =>
	async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			dispatch({ type: WARRANTY_REQUEST })
			const { data } = await axios.post(
				"/api/form/warranty",
				{
					name,
					email,
					phoneNumber,
					bike,
					frameNumber,
					invoiceNumber,
					invoice,
				},
				config,
			)

			dispatch({
				type: WARRANTY_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: WARRANTY_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const insuranceCreate =
	(
		name,
		email,
		phoneNumber,
		bike,
		frameNumber,
		invoiceNumber,
		DOB,
		nomineeName,
		nomineeDOB,
		nomineeRelation,
		address,
		city,
		state,
		pincode,
		invoice,
	) =>
	async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			dispatch({ type: INSURANCE_REQUEST })
			const { data } = await axios.post(
				"/api/form/insurance",
				{
					name,
					email,
					phoneNumber,
					bike,
					frameNumber,
					invoiceNumber,
					DOB,
					nomineeName,
					nomineeDOB,
					nomineeRelation,
					address,
					city,
					state,
					pincode,
					invoice,
				},
				config,
			)

			dispatch({
				type: INSURANCE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: INSURANCE_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
