import React, { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductBySubCategory } from "../Actions/bikeActions.js"
import { useParams } from "react-router-dom"
import logo from "../images/logo1.png"
import { addToCart } from "../Actions/cartActions"
import Navbar from "../Components/Navbar.js"
import { useNavigate, Link } from "react-router-dom"
import { getloginUserInfo } from "../Actions/userAction"
import Footer from "../Components/Footer.js"
import "../CSS/product.css"
import "../CSS/accessories.css"
import WhatsAppBot from "../Components/WhatsAppBot.js"
import Fade from "react-reveal/Fade"
import Meta from "../Components/Meta.js"

const AccessoriesCategoryScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { name } = useParams()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const productBySubCategory = useSelector(
		(state) => state.productBySubCategory,
	)
	const { products } = productBySubCategory

	const [model, setModel] = useState(0)

	useEffect(() => {
		if (name) {
			dispatch(getProductBySubCategory(name))
		}
	}, [dispatch, name, userInfo])

	useEffect(() => {
		if (userInfo) {
			dispatch(getloginUserInfo(userInfo._id))
		}
	}, [])

	const currencyFormat = (num) => {
		return "Rs" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}

	// const addCart = () => {
	// 	dispatch(addToCart(productInfo._id, model, 1))
	// 	navigate("/cart")
	// }
	return (
		<>
			{products && (
				<>
					{" "}
					<Meta
				title={
					` EMotorad | ${name.toUpperCase()}`
				}
				description={
					'EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price.'
				}
			/>
					<WhatsAppBot bottom={"15%"} />
					<Fade right>
						<div className='big-text-box  '>
							<p style={{ fontSize: "12em" }} className='big-text  font-mon'>
								{name}
							</p>
						</div>
					</Fade>
					<Navbar />
					<div className="container accessories">
            <h4 style={{textTransform:'uppercase'}} className='ps-3 fw-bold mt-4 d-lg-none'>{name}S</h4>
            <div className="row">
             
              {products.map((product) => (

							 <div className="col-12  col-lg-4  ">
                <Link to={ `/accessories/${product.urlName}`} className='text-decoration-none'>
               
                <div className='accessories-item pb-3   mx-auto mt-2 mt-lg-5'>
							
									<img
										className='img-fluid'
										src={product.productImages[0][0]}
										alt=''
									/>

									<h6 className='text-center'>{product.name}</h6>
									<p  className=' mt-3 txt-regular d-block text-center' style={{color:'grey'}}>
									{ currencyFormat(product.price)}
									</p>

								</div>
                </Link>
                </div>
						))}
              

            </div>
						
					</div>
					<Footer />{" "}
				</>
			)}
		</>
	)
}

export default AccessoriesCategoryScreen
