import React, {
	useState,
	useCallback,
	useEffect,
	useRef,
} from "react"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { getAllAccessories } from "../Actions/bikeActions"
import { getBikeByName } from "../Actions/bikeActions.js"
import { useParams } from "react-router-dom"
import logo from "../images/logo1.png"
import { addToCart } from "../Actions/cartActions"
import Navbar from "../Components/Navbar.js"
import {
	useNavigate,
	Link,
} from "react-router-dom"
import Meta from "../Components/Meta.js"
import ImageGallery from "react-image-gallery"
import { getloginUserInfo } from "../Actions/userAction"
import Footer from "../Components/Footer.js"
import "../CSS/product.css"
import WhatsAppBot from "../Components/WhatsAppBot.js"

const AccessoriesScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { name } = useParams()
	const [fullScreenClose, setfullScreenClose] =
		useState(false)
	const userLogin = useSelector(
		(state) => state.userLogin,
	)
	const { userInfo } = userLogin

	const bikeByName = useSelector(
		(state) => state.bikeByName,
	)
	const { bike: productInfo } = bikeByName

	const getUserInfo = useSelector(
		(state) => state.getUserInfo,
	)
	const { userInfo: userDetails } = getUserInfo

	const [model, setModel] = useState(0)

	const [imageIndex, setImageIndex] = useState(0)

	const allAccessories = useSelector(
		(state) => state.allAccessories,
	)
	const { accessories } = allAccessories

	useEffect(() => {
		dispatch(getAllAccessories())
	}, [])

	useEffect(() => {
		if (name) {
			dispatch(getBikeByName(name))
			setImageIndex(0)
		}
	}, [dispatch, name, userInfo])

	useEffect(() => {
		if (userInfo) {
			dispatch(getloginUserInfo(userInfo._id))
		}
	}, [])

	const currencyFormat = (num) => {
		return (
			"$" +
			" " +
			num
				.toFixed(0)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		)
	}

	const addCart = () => {
		dispatch(
			addToCart(
				productInfo._id,
				model,
				1,
				variant,
			),
		)
		navigate("/cart")
	}

	const ind = useRef(0)
	const [variant, setVariant] = useState(0)

	return (
		<>
			{productInfo && (
				<>
					{" "}
					{productInfo && (
						<Meta
							title={` EMotorad | ${productInfo.name} `}
							description={
								productInfo.description
							}
						/>
					)}
					<WhatsAppBot bottom={"15%"} />
					<Navbar />
					<div className='container mt-lg-5 mt-1 mb-5'>
						<div className='row'>
							<div className='col-lg-8 p-0  '>
								<div className='position-relative p-lg-0 p-2'>
									<img
										src={
											productInfo.productImages[
												model
											][imageIndex]
										}
										className='w-100 bike-image  '
										style={{
											background: "#F2F5F5",
										}}
										alt=''
									/>
									<div className='product-images ps-0 pe-0  mt-lg-2 d-flex  '>
										{productInfo &&
											productInfo.productImages[
												model
											].map((image, i) => (
												<div
													onClick={() =>
														setImageIndex(i)
													}
													style={{
														width: "20%",
													}}
													className='p-2 ps-0 pe-0'>
													<img
														src={image}
														style={{
															cursor: "pointer",
															background:
																"#F2F5F5",
														}}
														className={`img-fluid ${
															i === imageIndex &&
															"border"
														}  w-75  p-2 bg-product-images mx-auto d-block `}
														alt=''
													/>
												</div>
											))}
									</div>
								</div>
							</div>{" "}
							<div className='col-lg-4'>
								<div className='bike-info ps-lg-5'>
									<h1 className='fw-bold mt-4'>
										{productInfo.name}
									</h1>
									<div
										className='d-flex align-items-center'
										style={{ gap: "0.3em" }}>
										<i
											class='fa-solid text-light  fa-star '
											style={{
												background: "#10b068",
												fontSize: "0.7em",
												padding: "0.5em",
												paddingLeft: "0.4em",
												paddingtop: "0.0em",
												paddingBottom: "0.5em",

												paddingRight: "0.4em",
											}}></i>
										<i
											class='fa-solid text-light  fa-star '
											style={{
												background: "#10b068",
												fontSize: "0.7em",
												padding: "0.5em",
												paddingLeft: "0.4em",
												paddingtop: "0.0em",
												paddingBottom: "0.5em",

												paddingRight: "0.4em",
											}}></i>
										<i
											class='fa-solid text-light  fa-star '
											style={{
												background: "#10b068",
												fontSize: "0.7em",
												padding: "0.5em",
												paddingLeft: "0.4em",
												paddingtop: "0.0em",
												paddingBottom: "0.5em",

												paddingRight: "0.4em",
											}}></i>
										<i
											class='fa-solid text-light  fa-star '
											style={{
												background: "#10b068",
												fontSize: "0.7em",
												padding: "0.5em",
												paddingLeft: "0.4em",
												paddingtop: "0.0em",
												paddingBottom: "0.5em",

												paddingRight: "0.4em",
											}}></i>
										<i
											class='fa-solid text-light  fa-star '
											style={{
												background: "#10b068",
												fontSize: "0.7em",
												padding: "0.5em",
												paddingLeft: "0.4em",
												paddingtop: "0.0em",
												paddingBottom: "0.5em",

												paddingRight: "0.4em",
											}}></i>

										{/* <i
											class='text-light fa-regular fa-star-half-stroke '
											style={{
												background:
													"linear-gradient(to left, #f5f7f7 50%, #10b068 50%)",
												fontSize: "0.7em",
												padding: "0.5em",
												paddingLeft: "0.4em",
												paddingtop: "0.0em",
												paddingBottom: "0.5em",

												paddingRight: "0.4em",
											}}></i> */}
									</div>
									<p className='mt-3 bike-desc text-muted'>
										{productInfo.description}
									</p>

									<div
										className='d-flex mt-lg-4'
										style={{ gap: "2em" }}>
										<p className='bike-price'>
											{productInfo.variant
												? currencyFormat(
														productInfo.variant[
															variant
														].price,
												  )
												: currencyFormat(
														productInfo.price,
												  )}
										</p>
										{productInfo.slashPrice >
											0 && (
											<p className='slash-bike-price'>
												{currencyFormat(
													productInfo.slashPrice,
												)}
											</p>
										)}
									</div>

									<small className='including-gst'>
										Including GST
									</small>

									{productInfo.variant &&
										productInfo.variant.length >
											0 && (
											<>
												<div className='bike-colors mt-4 '>
													<p className='mb-2 mb-lg-3'>
														{
															productInfo
																.variant[0]
																.variant_Title
														}{" "}
														-{" "}
														{
															productInfo.variant[
																variant
															].variant_name
														}
													</p>
												</div>
												<div
													className='d-flex align-items-center'
													style={{
														gap: "1.2em",
													}}>
													{productInfo.variant.map(
														(item, i) => (
															<div
																className={`w-100 p-1 ${
																	i === variant &&
																	"variant-border"
																}`}>
																<div
																	style={{
																		background:
																			"#F2F5F5",
																	}}
																	className=' w-100 text-center text-dark p-2'
																	onClick={() =>
																		setVariant(i)
																	}>
																	{
																		item.variant_name
																	}
																</div>
															</div>
														),
													)}
												</div>
											</>
										)}

									<div className='mt-3 d-none d-md-block'>
										{productInfo.inStock > 0
											? productInfo.variant && (
													<div>
														{productInfo.variant[
															variant
														].inStock ? (
															<>
																<button
																	className='p-3 btn btn-dark mt-lg-4 w-100 rounded-0'
																	onClick={() =>
																		addCart(
																			productInfo._id,
																			model,
																			1,
																		)
																	}>
																	BUY NOW
																</button>
															</>
														) : (
															<button className='p-3 btn  btn-dark disabled w-100 mt-lg-4  rounded-0'>
																OUT OF STOCK
															</button>
														)}
													</div>
											  )
											: ""}

										{!productInfo.variant && (
											<div>
												{productInfo.inStock >
												0 ? (
													<>
														<button
															className='p-3 btn btn-dark mt-lg-4 w-100 rounded-0'
															onClick={() =>
																addCart(
																	productInfo._id,
																	model,
																	1,
																)
															}>
															BUY NOW
														</button>
													</>
												) : (
													<button className='p-3 btn  btn-dark disabled w-100 mt-lg-4  rounded-0'>
														OUT OF STOCK
													</button>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className=' pt-2  pb-2 p-2 d-lg-none position-sticky   '
						style={{
							bottom: "0%",
							border: "0px",
							width: "100%",
							zIndex: "2",
							background: "#fff",
						}}>
						{productInfo.inStock > 0
							? productInfo.variant && (
									<div>
										{productInfo.variant[variant]
											.inStock ? (
											<>
												<button
													onClick={() =>
														addCart(
															productInfo._id,
															model,
															1,
														)
													}
													className='text-light pt-3 pb-3 rounded-0 btn w-100 '
													style={{
														border: "none",
														background:
															"rgba(0,0,0,1)",
													}}>
													BUY NOW
												</button>
											</>
										) : (
											<button
												className='text-light disabled pt-3 pb-3 rounded-0 btn w-100 '
												style={{
													border: "none",
													background:
														"rgba(0,0,0,1)",
												}}>
												OUT OF STOCK
											</button>
										)}
									</div>
							  )
							: ""}
						{!productInfo.variant && (
							<div>
								{" "}
								{productInfo.inStock > 0 ? (
									<button
										onClick={() =>
											addCart(
												productInfo._id,
												model,
												1,
											)
										}
										className='text-light pt-3 pb-3 rounded-0 btn w-100 '
										style={{
											border: "none",
											background: "rgba(0,0,0,1)",
										}}>
										BUY NOW
									</button>
								) : (
									<button
										className='text-light disabled pt-3 pb-3 rounded-0 btn w-100 '
										style={{
											border: "none",
											background: "rgba(0,0,0,1)",
										}}>
										OUT OF STOCK
									</button>
								)}
							</div>
						)}
					</div>
					<Footer />{" "}
				</>
			)}
		</>
	)
}

export default AccessoriesScreen
