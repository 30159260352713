import React, { useEffect } from "react"
import "../CSS/about.css"
import Navbar from "../Components/Navbar"
import "@splidejs/react-splide/css"
import { Link } from "react-router-dom"
import {
	Splide,
	SplideSlide,
} from "@splidejs/react-splide"
import Footer from "../Components/Footer"
import Meta from "../Components/Meta"

const AboutScreen = () => {
	return (
		<>
			<Meta
				title={" EMotorad | About Us"}
				description={
					"EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
				}
			/>
			<Navbar />
			<div className='about-banner'>
				<div className='d-none d-md-block'>
					<h1>Passion Meets Disruption</h1>
				</div>
			</div>
			<div className='container p-lg-5 mt-3 mt-lg-0 about-content'>
				<div className='row'>
					<div className='col-lg-6 pe-lg-5'>
						<h2 className='fw-bold'>
							Our Mission
						</h2>
						<p className='mt-3'>
							We believe in the power of clean,
							efficient, and sustainable
							transportation. We are an e-bike
							brand that operates in Europe,
							India, Japan, Australia and UAE,
							dedicated to providing our customers
							with the best e-bikes on the market,
							and a world-class customer
							experience.
						</p>

						<p className='mt-3 fw-bold'>
							We are your backyard startup
							conquering the world, delivering
							performance and ushering in a
							sustainable tomorrow.
						</p>
						<p className='mt-3'>
							At our core, we are innovators and
							disruptors, constantly pushing the
							boundaries of what's possible in the
							e-bike industry. We believe that the
							future is electric, and we are
							committed to doing our part to make
							the world a better place, one bike
							at a time.
						</p>
						<img
							src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/Mobile+main+banner+02.jpg'
							alt=''
							className='img-fluid mt-lg-5'
						/>
					</div>
					<div className='col-lg-6 mt-4 mt-lg-0'>
						<img
							src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/Mobile+main+banner+01.jpg'
							className='img-fluid'
							alt=''
						/>
						<h2 className='fw-bold mt-3 mt-lg-4'>
							Our Story
						</h2>
						<p className='mt-3 '>
							Our e-bikes are designed for every
							type of rider, from the urban
							commuter to the adventure seeker. We
							offer a wide range of models that
							cater to different needs and
							preferences, ensuring that everyone
							can find the perfect e-bike for
							their lifestyle.
						</p>

						<p className='mt-3'>
							We understand that buying an e-bike
							is an investment, which is why we go
							above and beyond to provide our
							customers with exceptional service
							and support. From pre-sale
							consultations to post-purchase
							maintenance and repairs, we are
							committed to ensuring that our
							customers have a seamless and
							hassle-free experience.
						</p>
						<p className='mt-3'>
							we are more than just an e-bike
							company; we are a community of
							like-minded individuals who share a
							passion for e-bikes and a commitment
							to creating a better world. Join us
							on our journey, and together, let's
							ride towards a brighter, cleaner,
							and more sustainable future.
						</p>
					</div>
				</div>
			</div>
			<div className='founders'>
				<h1>Australian Leadership</h1>
				<div className='container'>
					<Splide
						className='p-lg-0 ps-3 pe-3 founder-cards'
						options={{
							arrows: false,
							drag: false,
							type: "loop",
							pagination: true,
							autoplay: false,
							perMove: 1,
							gap: "1em",
							pauseOnHover: false,
							resetProgress: false,
							interval: 5000,
							perPage: 4,
							speed: 1000,
							breakpoints: {
								640: {
									perPage: 1,
									arrows: true,
									drag: true,
								},
							},
						}}>
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/au-website-2.0/about/Janak-2.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Janaka Nawarathne
								</p>
								<p className='founder-title'>
									Director & CEO, Australia
								</p>
								<p className='founder-desc'>
									Janaka Nawarathne leads Ausstech
									as the CEO, fueling his passion
									for making sustainable, green,
									electric mobility accessible and
									available for everyone. With his
									5+ years of experience in the
									electric mobility industry, he
									brings a nuanced market
									understanding, business
									intelligence and consumer
									insight as an International
									partner to EMotorad.
								</p>
							</div>
						</SplideSlide>{" "}
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/about/Founder-2.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Ady
								</p>
								<p className='founder-title'>
									Operations
								</p>
								<p className='founder-desc'>
									Fondly known as Ady, Aditya has
									deep experience in understanding
									complex technical concepts and
									communication. Making him a
									skilled negotiator and the goto
									guy for all problem solving.
								</p>
							</div>
						</SplideSlide>
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/about/Founder-3.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Sebastian
								</p>
								<p className='founder-title'>
									After Sales & QC
								</p>
								<p className='founder-desc'>
									He loves customers and is
									happiest when his customers are
									happy. You can often see him
									helping his team, take customer
									calls all to ensure the customer
									experience is unmatched.
								</p>
							</div>
						</SplideSlide>
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/about/Founder-1.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Mikhail
								</p>
								<p className='founder-title'>
									Logistics & Warehouse
								</p>
								<p className='founder-desc'>
									He is as straight as an arrow,
									sees the world in black and
									white. He is a key element in
									ensuring the operations at
									Emotorad flow seamlessly.
								</p>
							</div>
						</SplideSlide>{" "}
					</Splide>
				</div>
			</div>{" "}
			<div className='our-team position-relative'>
				<h1>OUR CRAZY TEAM</h1>
			</div>
			<div className='founders'>
				<h1> Our Chief Love Officers</h1>
				<div className='container'>
					<Splide
						className='p-lg-0 ps-3 pe-3 founder-cards'
						options={{
							arrows: false,
							drag: false,
							type: "loop",
							pagination: true,
							autoplay: false,
							perMove: 1,
							gap: "1em",
							pauseOnHover: false,
							resetProgress: false,
							interval: 5000,
							perPage: 3,
							speed: 1000,
							breakpoints: {
								640: {
									type: "loop",
									perPage: 1,
									arrows: true,
									drag: true,
								},
							},
						}}>
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/04.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Jenny
								</p>

								<p className='founder-desc'>
									Everyone loves Jenny and Jenny
									loves everyone. She is always
									gets the mood set for everyone
									in the office
								</p>
							</div>
						</SplideSlide>
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/03.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Sky
								</p>

								<p className='founder-desc'>
									She is never impressed by
									anything, unless its a treat.
									Treats keep her happy!
								</p>
							</div>
						</SplideSlide>{" "}
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/02.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Bluff
								</p>

								<p className='founder-desc'>
									Bluff is the dog who chills with
									you and loves to play fetch with
									almost anything He learned it
									hard way that Stapler is never a
									good toy.
								</p>
							</div>
						</SplideSlide>
					</Splide>
				</div>
			</div>
			<div className='milestones container'>
				<h1>Milestones</h1>
				<div className='row '>
					<div className='col-lg-4 milestone col-6'>
						<h4 className='milestone-value'>
							72,000
						</h4>
						<p className='milestone-heading'>
							Community Members
						</p>
					</div>
					<div className='col-lg-4 milestone col-6'>
						<h4 className='milestone-value'>
							18+
						</h4>
						<p className='milestone-heading'>
							Countries
						</p>
					</div>
					<div className='col-lg-4 milestone col-6'>
						<h4 className='milestone-value'>
							36+
						</h4>
						<p className='milestone-heading'>
							Global Partners
						</p>
					</div>
					<div className='col-lg-4 milestone col-6'>
						<h4 className='milestone-value'>
							No. 1
						</h4>
						<p className='milestone-heading'>
							E-bike Brand Across 2 Continents
						</p>
					</div>
					<div className='col-lg-4 milestone col-6'>
						<h4 className='milestone-value'>
							22
						</h4>
						<p className='milestone-heading'>
							Registered Patents
						</p>
					</div>
					<div className='col-lg-4 milestone col-6'>
						<h4 className='milestone-value'>
							1000+
						</h4>
						<p className='milestone-heading'>
							Dealerships
						</p>
					</div>
				</div>
				<div className='d-flex mt-lg-5 mt-4 align-items-center'>
					<Link
						to={"/partner-with-us"}
						className='btn btn-dark  ps-lg-5 pt-lg-3 pb-lg-3 pe-lg-5 float-center mx-auto rounded-0'>
						Partner With Us
					</Link>
				</div>
			</div>{" "}
			<div className='founders'>
				<h1>Global Leadership</h1>
				<div className='container'>
					<Splide
						className='p-lg-0 ps-3 pe-3 founder-cards'
						options={{
							arrows: false,
							drag: false,
							type: "loop",
							pagination: true,
							autoplay: false,
							perMove: 1,
							gap: "1em",
							pauseOnHover: false,
							resetProgress: false,
							interval: 5000,
							perPage: 4,
							speed: 1000,
							breakpoints: {
								640: {
									type: "loop",
									perPage: 1,
									arrows: true,
									drag: true,
								},
							},
						}}>
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/ind-founder/1.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Rajib Gangopadhyay
								</p>
								<p className='founder-title'>
									Founder & MD
								</p>
								<p className='founder-desc'>
									Rajib is a prominent leader in
									the e-bike space, with rich
									international experience of over
									10 yrs, spanning across EU,
									Australia, Japan and the US. He
									believes in building a business
									rooted in community. With his
									revolutionary thinking and the
									team he has built, Emotorad is
									bound to ride to unparalleled
									heights of growth and success.
								</p>
							</div>
						</SplideSlide>{" "}
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/ind-founder/2.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Kunal Gupta
								</p>
								<p className='founder-title'>
									CEO & Co-founder
								</p>
								<p className='founder-desc'>
									EM is led by Kunal Gupta, who
									has been in the automobile
									industry for nine years.
									Previously, having led as the
									CEO of a leading start-up in the
									mobility industry, he wants to
									work on EV education in India
									and enlighten younger
									generations about EVs.
								</p>
							</div>
						</SplideSlide>
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/ind-founder/3.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Aditya Oza
								</p>
								<p className='founder-title'>
									CMO & Co-founder
								</p>
								<p className='founder-desc'>
									Having excelled in execution for
									top industry players in Dubai
									and Abu Dhabi, Aditya holds the
									post of CMO in EM. His clear
									vision is to make EMotorad a
									globally renowned brand in the
									EV segment and his ability to
									inculcate passion in his
									teammates is one of the biggest
									drivers of the organisation.
								</p>
							</div>
						</SplideSlide>{" "}
						<SplideSlide>
							<div className='position-relative founder-card  '>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/about/ind-founder/4.jpg'
									className='img-fluid  '
									alt=''
								/>
								<p className='founder-name'>
									Sumedh Battewar
								</p>
								<p className='founder-title'>
									CBO & Co-founder
								</p>
								<p className='founder-desc'>
									Sumedh heads the business
									development wing at EM. An
									engineering mind with marketing
									acumen and experience in EV
									industry dealings for the past
									5+ years, Sumedh is a hub of
									knowledge. His professional
									expertise amalgamated with his
									personal interest help lay the
									foundation of EM.
								</p>
							</div>
						</SplideSlide>{" "}
					</Splide>
				</div>
			</div>{" "}
			<div className='our-team-clan position-relative'>
				<h1>COME JOIN THE CLAN</h1>
			</div>
			<Footer />
		</>
	)
}

export default AboutScreen
