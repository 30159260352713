import React from "react"
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"
const ReturnPolicyScreen = () => {
	return (
		<div className='container-fluid p-0'>
			<Navbar />

			<div className='container mb-lg-5 pb-lg-5'>
				<div className='mt-4'>
					<h1 className='  fw-bold'>
						Refund & Cancellation Policy
					</h1>

					<p className='mt-4 text-justify'>
						Our focus is complete customer
						satisfaction. If you are displeased
						with the services provided, we will
						refund back the money (according to
						our refund policy), provided the
						reasons are genuine and well-proven
						after a thorough investigation by our
						concerned teams.
					</p>
					<p>
						Our policy for the cancellation and
						refund will be as follows:
					</p>
					<ol className='mt-5'>
						<li className='h5 fw-bold '>
							<p>Cancellation Policy</p>
						</li>
						<p>
							Your order can be cancelled within
							24 hours after placing your order.
							If you opt for cancellation after
							the product status is ‘shipped’ or
							‘out for delivery', the company can
							exercise a right to levy a
							‘cancellation fee’ before processing
							the refund for your order.
						</p>

						<li className='h5 mt-4 fw-bold '>
							Return Policy{" "}
						</li>
						<p>
							Once the product/s is delivered, it
							can not be returned or cancelled and
							no refund will be provided for the
							same. Product/s is shipped on
							client/customer risk, we are not
							liable for any damage in transport.
						</p>
						<li className='h5 mt-4 fw-bold'>
							Refund Policy
						</li>
						<p>
							In case of cancellation, the
							cancellation fee is to be borne by
							the clients/customers.
						</p>
						<p>
							After deducting the cancellation
							fee, Refunds will be issued to the
							original source account provided at
							the time of purchase. After the
							refund has been initiated from our
							end, the refund amount is expected
							to reflect in the customer’s source
							account within 7 to 15 business days
							according to the norms and rules
							issued by the local laws.
						</p>
						<p className='fw-bold'>
							The cancellation fee is as follows:-
						</p>
						<ul>
							<li>
								Within 24 hours of placing your
								order,
							</li>
							<li>
								No cancellation fee if the order
								is not processed for dispatch.
							</li>
							<li>
								If the status of your order is
								shipped: 10% of the total order
								value
							</li>
							<li>
								If the status of your order is out
								for delivery : 30% of the total
								order value
							</li>
						</ul>
						<p className='mt-4'>
							Please note that our cancellation
							and refund policies are subject to
							change without prior notice.
						</p>

						<li className='h5 mt-4 fw-bold'>
							Delivery & Shipping Policy
						</li>
						<p>
							You confirm that the address at
							which delivery of the product(s)
							ordered by You is to be made, is
							correct and proper in all aspects.
							In the event that a non-delivery
							occurs on account of a mistake by
							You (i.e. wrong name or address or
							any other wrong information or
							non-availability of recipient), any
							extra cost incurred by the Company
							for re-delivery shall be claimed
							from You and You shall bear such
							expenses. Further, the Company shall
							not be responsible for any damages
							or losses incurred due to any
							mishandling of any third-party
							delivery services provider in
							transit.
						</p>
						<p>
							If the Company is unable to deliver
							such ordered products due to any
							reason, the Company will refund such
							amount through the same online mode
							of payment made by You or will give
							credit in the form of deposit in
							Your account for the amount received
							from the sale of such product, that
							could not be delivered to You,
							within 90 (ninety) business days.
						</p>
						<p>
							You will be required to enter a
							valid phone number, email id while
							placing an order on the Website. By
							registering Your phone number with
							us, You consent to be contacted by
							the Company via phone calls and / or
							SMS notifications, in case of any
							order or shipment or delivery
							related updates. The Company may use
							Your personal information to
							initiate any promotional phone calls
							or SMS’ unless expressly denied by
							the User.
						</p>
					</ol>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default ReturnPolicyScreen
