import React from "react"

import "../CSS/footer.css"
import { Link } from "react-router-dom"
import logo from "../images/mainlogo.png"

const Footer = () => {
	return (
		<footer className=' p-lg-3   '>
			<div className='container '>
				<div className='row'>
					<div className='col-lg-5 p-4 pe-lg-5'>
						<img
							src={logo}
							style={{ width: "230px" }}
							alt=''
						/>
						<p className=' mt-lg-3   footer-desc'>
							EMotorad (EM) is an electric
							vehicles company that strives to
							bring futuristic e-bikes at an
							affordable price for adventure
							seekers, daily commuters, or casual
							riders.
						</p>
						<p className=' mt-lg-5 footer-desc'>
							<p>Phone:+61 1300640097 </p>
							<p className='w-75'>
								Address: Ausstech Holdings Pty Ltd
								Suite 246/4,16-18
								Beenleigh-Redland Bay Road,
								Loganholme QLD 4129, Australia
							</p>{" "}
							<p>
								Email: contactus.au@emotorad.com
							</p>
						</p>
					</div>
					<div className='col-lg-7 footer-links p-4'>
						<div className='row'>
							<div className='col-4'>
								<h5 className='color-dark mb-3 mt-3  mt-lg-0'>
									About
								</h5>

								<Link to='/ourstory' className=''>
									About Us
								</Link>

								<Link to='/blogs' className=''>
									Blogs
								</Link>

								<Link
									to='/partner-with-us'
									className=''>
									Partner with us
								</Link>
							</div>
							<div className='col-4'>
								<h5 className='color-dark mb-3 mt-3  mt-lg-0'>
									Products
								</h5>{" "}
								<Link
									to='/allbikes'
									className='txt-regular'>
									All Bikes
								</Link>
								<Link
									to='/bikes/doodle'
									className='txt-regular'>
									Doodle
								</Link>
								<Link
									to='/bikes/monster'
									className='txt-regular'>
									Monster
								</Link>
								<Link
									to='/bikes/thunder'
									className='txt-regular'>
									Thunder
								</Link>{" "}
								<Link
									to='/bikes/beach-rider'
									className='txt-regular'>
									Beach Rider
								</Link>
								<Link
									to='/bikes/emx'
									className='txt-regular'>
									EMX
								</Link>{" "}
								<Link
									to='/bikes/t-rex+'
									className='txt-regular'>
									T-Rex+
								</Link>
								<Link
									to='/bikes/x1'
									className='txt-regular'>
									X1
								</Link>{" "}
								<Link
									to='/bikes/x2'
									className='txt-regular'>
									X2
								</Link>
							</div>

							<div className='col-4'>
								<h5 className='color-dark mb-3 mt-3  mt-lg-0'>
									Assurance
								</h5>{" "}
								<Link
									to='/contactus'
									className=''>
									Contact Us
								</Link>
								<Link
									to='/warranty'
									className='txt-regular'>
									Warranty
								</Link>
							</div>
						</div>
					</div>
					<div className='col-lg-12 ps-lg-4'>
						<div className=' '>
							<div
								className='d-flex justify-content-around justify-content-md-start  mt-3  '
								style={{ gap: "3em" }}>
								<div className=''>
									<a
										href='https://www.facebook.com/EMotorad.Aus'
										target={"_blank"}
										rel='noreferrer'>
										{" "}
										<i class='fa-brands  social fa-facebook-f'></i>
									</a>
								</div>
								<div className=''>
									<a
										href='https://twitter.com/e_motorad'
										target={"_blank"}
										rel='noreferrer'>
										{" "}
										<i class='fa-brands social fa-twitter'></i>
									</a>
								</div>
								<div className=''>
									<a
										href='https://www.instagram.com/emotorad_aus/'
										target={"_blank"}
										rel='noreferrer'>
										<i class='fa-brands social fa-instagram'></i>
									</a>
								</div>
								<div className=''>
									<a
										href='https://www.linkedin.com/company/emotorad/'
										target={"_blank"}
										rel='noreferrer'>
										{" "}
										<i class='fa-brands social fa-linkedin-in'></i>
									</a>
								</div>
								<div className=''>
									<a
										href='https://www.youtube.com/channel/UCBaDv3uoPiBPSVDZJHGCCWg'
										target={"_blank"}
										rel='noreferrer'>
										{" "}
										<i class='fa-brands social fa-youtube'></i>
									</a>
								</div>
							</div>
							<hr className='text-light  bg-light' />
							<div className='d-flex justify-content-between footer-bottom'>
								<p className='text-md-start text-center'>
									Copyright © Ausstech Holdings
									Pty Ltd 2023 All rights
									reserved.
								</p>
								<div
									className='d-md-flex d-none justify-content-around '
									style={{ gap: "2em" }}>
									<Link
										to='/privacy-policy'
										className='txt-regular'>
										Privacy Policy
									</Link>{" "}
									<Link
										to='/terms&condition'
										className='txt-regular'>
										Terms & Conditions
									</Link>{" "}
									<Link
										to='/cookie-policy'
										className='txt-regular'>
										Cookie Policy
									</Link>{" "}
									<Link
										to='/refund-and-cancellation-policy'
										className='txt-regular'>
										Refund & Cancellation Policy
									</Link>{" "}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
