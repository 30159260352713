import React, { useState, useEffect } from "react"
import Meta from "../Components/Meta"
import Navbar from "../Components/Navbar"
import storage from "../firebaseConfig"
import {
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from "firebase/storage"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { warrantyCreate } from "../Actions/formActions"
import { useNavigate } from "react-router-dom"
import Footer from "../Components/Footer"
import "../CSS/warranty.css"
const WarrantyScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const warranty = useSelector(
		(state) => state.warranty,
	)
	const { warrantyStatus } = warranty

	useEffect(() => {
		if (warrantyStatus) {
			navigate("/submitted")
		}
	}, [warrantyStatus])

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] =
		useState("")
	const [bike, setBike] = useState("")
	const [frameNumber, setFrameNumber] =
		useState("")
	const [invoiceNumber, setInvoiceNumber] =
		useState("")

	const [file, setFile] = useState()

	const submitForm = (e) => {
		e.preventDefault()
		fileUpload()
	}

	const fileUpload = () => {
		if (!file) {
			alert("Please Upload Invoice")
		}

		// console.log(storage)

		const storageRef = ref(
			storage,
			`/files/${file.name}`,
		)
		const uploadTask = uploadBytesResumable(
			storageRef,
			file,
		)
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const percent = Math.round(
					(snapshot.bytesTransferred /
						snapshot.totalBytes) *
						100,
				)

				// update progress
			},
			(err) => console.log(err),
			() => {
				// download url
				getDownloadURL(
					uploadTask.snapshot.ref,
				).then((url) => {
					dispatch(
						warrantyCreate(
							name,
							email,
							phoneNumber,
							bike,
							frameNumber,
							invoiceNumber,
							url,
						),
					)
				})
			},
		)
	}

	return (
		<>
			<Navbar />

			<div
				className='container-fluid  m-0 pb-0 '
				style={{
					background: "black",
				}}>
				<div className='row p-0 '>
					<div className='col-lg-5 p-0'>
						<img
							src='https://ar-euro.s3.ap-south-1.amazonaws.com/eu.website.2.0/EU_Warranty.jpg'
							className='img-fluid'
							alt=''
						/>
					</div>
					<div className='col-lg-7 mt-lg-5 pt-lg-4 contactus-main'>
						<div className=' mx-auto'>
							<h1>WARRANTY</h1>
							{/* <p className='subline'>
								We’d love to hear from you
							</p> */}
							<form
								action=''
								onSubmit={submitForm}>
								<div className='mt-4'>
									<div class='mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Name
										</label>
										<input
											type='text'
											class='form-control '
											value={name}
											required
											onChange={(e) =>
												setName(e.target.value)
											}
											style={{
												textTransform:
													" capitalize",
												padding: "0.7em",
											}}
											id='exampleFormControlInput1'
											placeholder=''
										/>
									</div>
									<div class='mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Email
										</label>
										<input
											type='email'
											class='form-control'
											style={{ padding: "0.7em" }}
											id='exampleFormControlInput1'
											placeholder=''
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
										/>
									</div>
									<div class=' mb-3'>
										<label
											for='exampleFormControlInput1'
											class='form-label text-light '>
											Phone Number
										</label>
										<input
											type='number'
											class='form-control'
											style={{ padding: "0.7em" }}
											id='exampleFormControlInput1'
											placeholder=''
											value={phoneNumber}
											required
											onChange={(e) =>
												setPhoneNumber(
													e.target.value.slice(
														0,
														11,
													),
												)
											}
										/>
									</div>
									<div className='row'>
										<div class='mb-lg-5 col-lg-4 col-12 mb-3'>
											<label
												for='exampleFormControlInput1'
												class='form-label text-light '>
												Select Bike
											</label>
											<select
												onChange={(e) =>
													setBike(e.target.value)
												}
												class='form-select '
												style={{
													padding: "0.78em",
												}}
												aria-label='Default select example'>
												<option
													selected
													disabled></option>
												<option value='Doodle'>
													Doodle
												</option>
												<option value='Toledo'>
													Toledo
												</option>
												<option value='Plymouth'>
													Plymouth
												</option>
											</select>
										</div>
										<div class='mb-lg-5 col-lg-4 col-12 mb-3'>
											<label
												for='exampleFormControlInput1'
												class='form-label text-light '>
												Frame Number
											</label>
											<input
												type='number'
												class='form-control'
												style={{
													padding: "0.7em",
												}}
												id='exampleFormControlInput1'
												placeholder=''
												value={frameNumber}
												required
												onChange={(e) =>
													setFrameNumber(
														e.target.value,
													)
												}
											/>
										</div>{" "}
										<div class='mb-lg-5 col-lg-4 col-12 mb-3'>
											<label
												for='exampleFormControlInput1'
												class='form-label text-light '>
												Invoice Number
											</label>
											<input
												type='number'
												class='form-control'
												style={{
													padding: "0.7em",
												}}
												id='exampleFormControlInput1'
												placeholder=''
												value={invoiceNumber}
												required
												onChange={(e) =>
													setInvoiceNumber(
														e.target.value,
													)
												}
											/>
										</div>{" "}
										<div class='mb-lg-5 col-lg-12 col-12 mb-3'>
											<label
												htmlFor='file'
												class='form-label text-light text-center  w-100 '
												style={{
													padding: "2em",
													border:
														"1px dashed white",
												}}>
												{file
													? file.name
													: "UPLOAD INVOICE FILE"}
											</label>
											<input
												type='file'
												hidden
												onChange={(e) =>
													setFile(
														e.target.files[0],
													)
												}
												id='file'
												placeholder=''
												required
											/>
										</div>{" "}
									</div>

									<button
										type='submit'
										className='btn btn-primary ms-auto d-block fw-bold mt-lg-4 '>
										Send Message
									</button>
								</div>{" "}
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className='container'>
				<p>
					<br />
				</p>
				<p>
					At EMotorad, our first guiding principle
					is "The Rider is the Boss." We stand
					behind the products that we sell. For
					this reason, we're pleased to offer one
					of the industry's most generous and
					rider-friendly warranty policies.
				</p>
				<p>
					<br />
				</p>
				<p>Repair or replacement of components</p>

				<ul>
					<li>
						<p>
							We stand behind our frames. This
							means we offer a lifetime warranty
							to the original owner against
							structural defects in material or
							workmanship on ALL Aluminium models
							of EMotorad frames and a five year
							warranty to the original owner
							against structural defects in
							material or workmanship on ALL Steel
							models of EMotorad frames.
						</p>
					</li>
					<li>
						<p>
							Other EMotorad branded products (as
							well as frame paint and graphics)
							are covered by a two-year warranty.
						</p>
					</li>
					<li>
						<p>
							This warranty is limited to the
							original purchaser only. Proof of
							purchase is required to validate
							protection under warranty. Transport
							costs are not included.
						</p>
					</li>
					<li>
						<p>
							We offer 12 Months warranty on the
							motor from the date of original
							Purchase, 12 Months warranty on the
							controller and 24 months warranty on
							the battery .
						</p>
					</li>
					<li>
						<p>
							The battery warranty does not
							include damage from power surges,
							use of non-original chargers,
							improper maintenance, or water
							immersion.
						</p>
					</li>
					<li>
						<p>
							Accessories (for example: helmet,
							wire lock, pump.) are not covered by
							this warranty policy.
						</p>
					</li>
				</ul>
				<p>
					<br />
				</p>
				<p>
					Only use this product in accordance with
					this user manual. EMotorad offers a
					warranty on the following items.
				</p>
				<p>
					<br />
				</p>
				<div align='left'>
					<table className='w-100'>
						<tbody>
							<tr>
								<td>
									<p>Main frame (Aluminium)</p>
								</td>
								<td>
									<p>Lifetime</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Main frame (Steel)</p>
								</td>
								<td>
									<p>Five years</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										Motor shell, Hub motor,
										Controller
									</p>
								</td>
								<td>
									<p>One year</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Battery</p>
								</td>
								<td>
									<p>Two years</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										Electronic handlebar controls
										and electrical connections,
										Battery Charger
									</p>
								</td>
								<td>
									<p>Six Months</p>
								</td>
							</tr>{" "}
							<tr>
								<td>
									<p>
										Paintwork (excluding
										accidental or deliberate
										damage)
									</p>
								</td>
								<td>
									<p>One year</p>
								</td>
							</tr>{" "}
							<tr>
								<td>
									<p>
										Lights and lighting system
									</p>
								</td>
								<td>
									<p>Six Months</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>&nbsp;</p>
				<p>
					<br />
				</p>
				<ul>
					<li>
						<p>
							All Mechanical parts are only
							covered for Manufacturing Defects.
							There is no warranty period on
							handlebars, stem, bearings,
							drivetrain parts, brakes, wires,
							wire housings, tyres, tubes, rim or
							wheels, hubs, seat or saddle, seat
							post, clamps, grips, headset parts,
							pedals, brake callipers, bottom
							bracket, disk, brake pads and
							mudguards.
						</p>
					</li>
					<li>
						<p>
							Display, Throttle, LED Light, Pedal
							Sensor, Charger - 6 Months warranty.
						</p>
					</li>
				</ul>
				<p>
					If any manufacturing defect(s) is found
					in our product, the Company will repair
					or replace the defective part(s) with
					new part(s) or equivalent at no cost,
					provided the Product is within warranty
					period of 1 year and the malfunction is
					caused due to faulty material or
					workmanship during manufacturing. The
					decision to replace or repair the
					defective part will rest solely with the
					Company. We also reserve the right to
					replace the defective part with parts,
					similar or different to the defective
					part, as per the availability of such
					parts.
				</p>
				<p>Terms &amp; Conditions</p>
				<div align='left'>
					<table>
						<tbody>
							<tr>
								<td>
									<p>1.</p>
								</td>
								<td>
									<p>
										If the product has a quality
										fault within 15 days of
										delivery, the part will be
										repaired or replaced
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>2.</p>
								</td>
								<td>
									<p>
										The period of assurance shall
										commence from the day delivery
										was made to the retail
										customer, or from the day the
										retail customer collected the
										bike from the retailer.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>3.</p>
								</td>
								<td>
									<p>
										To validate this warranty, the
										retail customer must register
										their bike within 15 days of
										purchase.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
				<p>
					All parts that have been replaced under
					warranty must be returned to the Company
					and will be company’s property. Parts
					replacement will be subjected to below
					terms & conditions:
				</p>
				<ul>
					<li>
						<p>
							The Company or our authorized
							dealers will repair or replace only
							the parts that are failing due to
							faulty material.
						</p>
					</li>
					<li>
						<p>
							Only the Company or its authorized
							dealers can repair, service or
							assemble/ reassemble Product and it
							shall be the responsibility of the
							customer or purchaser to bring the
							faulty Product to our dealer
							premises.
						</p>
					</li>
					<li>
						<p>
							This warranty does not cover
							corrosive parts, plastic, PVC and
							rubber components or the accessories
							adding to the decorative value of
							the Product.
						</p>
					</li>
					<li>
						<p>
							Photocopy of sales invoice and
							serial number of the Product has to
							be shared with Company
						</p>
					</li>
					<li>
						<p>
							Battery used in Product is a special
							application battery meant only for
							electric cycles. Do not use these
							for any other application or
							product.
						</p>
					</li>
					<li>
						<p>
							The Company reserves the right to
							change or withdraw any or all terms
							of the warranty policy without any
							prior intimation.
						</p>
					</li>
					<li>
						<p>
							All Products should be periodically
							checked as per the recommended
							service schedule by our authorized
							dealer for indications of potential
							failures including cracks, wire
							damage, corrosion, dents,
							deformation, paint peeling and any
							other indications of potential
							problems, inappropriate use or
							abuse. These are important safety
							checks and very important to help
							prevent accidents, bodily injury to
							the rider and shortened useful
							product life cycle of a Product.
						</p>
					</li>
					<li>
						<p>
							Since Lithium ion batteries have the
							tendency to self-degrade, mileage
							per charge will reduce with ageing.
						</p>
					</li>
				</ul>
				<p>&nbsp;</p>
				<p>
					This warranty is not applicable in any
					of the following conditions:
				</p>
				<ul>
					<li>
						<p>
							If any repair work is carried out
							privately or by any Unauthorized
							dealer or personnel or irregular
							maintenance
						</p>
					</li>
					<li>
						<p>
							Damage resulting from misuse, not
							maintaining the vehicle or not
							following the guidelines within our
							user guide or using the vehicle for
							any kind of competitive sport
						</p>
					</li>
					<li>
						<p>
							Failure to register bike within 15
							days of purchase
						</p>
					</li>
					<li>
						<p>
							Spare parts and components worn in
							normal use
						</p>
					</li>
					<li>
						<p>
							Any special equipment or accessories
							added by the customer with an
							intention to enhance the
							performance, speed or comfort of the
							Product, is not included in this
							warranty. In the event of any such
							alteration or addition, the warranty
							will stand null and void
						</p>
					</li>
					<li>
						<p>
							If photocopy of sales invoice is not
							received with defective Product,
							warranty will be counted from date
							of manufacturing till define time
							period as above
						</p>
					</li>
					<li>
						<p>
							Fading of parts like paint, stickers
							and plastic parts due to direct
							exposure of sunlight is not covered
							under the warranty
						</p>
					</li>
					<li>
						<p>
							This warranty is void in its
							entirety by any modification of the
							frame, fork or components. This
							warranty is expressly limited to the
							repair or replacement of a defective
							item and is the sole remedy of
							warranty. This warranty extends from
							the date of purchase, only to the
							owner and is not transferable.
							Transportation cost and labor
							charges incurred in the replacement
							of parts are not covered under this
							warranty.
						</p>
					</li>
					<li>
						<p>
							The Company is not responsible for
							loss or damage whatsoever (including
							incidental or consequential damages)
							arising from use of the Product. Any
							claims under this warranty must be
							made through an authorized dealer
						</p>
					</li>
				</ul>
				<p>&nbsp;</p>
				<ul>
					<li>
						<p>
							The warranty stands null and void if
							:
						</p>
						<ul>
							<li>
								<p>
									The serial number
									/identification code is deleted,
									defaced, altered, effaced or
									removed
								</p>
							</li>
							<li>
								<p>
									Used for more than permissible
									load of 120kg
								</p>
							</li>
							<li>
								<p>
									Used for stunts, competition or
									jumping, acrobatics, bicycle
									moto–cross, dirt biking or
									similar activities as all units
									are not designed or intended for
									such purpose or usage
								</p>
							</li>
							<li>
								<p>
									Any kind of tampering observed
									with the parts/Product
								</p>
							</li>
							<li>
								<p>
									Modified/altered components for
									any specific use other than a
									personal transport
								</p>
							</li>
							<li>
								<p>
									Transportation, delivery, labour
									and handling charges incurred in
									the replacement of parts are not
									covered under this warranty, and
									shall be borne by the customer.
								</p>
							</li>
							<li>
								<p>
									This warranty is void in its
									entirety by any modification of
									the frame, fork or components
								</p>
							</li>
							<li>
								<p>
									Resold to any other
									person/company
								</p>
							</li>
							<li>
								<p>
									Damage caused by an accident or
									malfunction or misuse caused to
									the Product by the acts of
									persons, intentional or
									otherwise, including but not
									limited to misuse or
									mishandling, fire or any act of
									God
								</p>
							</li>
							<li>
								<p>
									Nonfunctional parts due to
									natural wear and tear, ageing,
									defect resulting from misuse/
									improper handling or negligence
									by the rider or Product is
									altered by component parts
									substitution is not covered
									under the warranty.
								</p>
							</li>
						</ul>
					</li>
				</ul>
				<p>
					This warranty policy is governed by the
					laws of Queensland, Australia, without
					regard to its conflict of laws
					principles. The application of the
					United Nations Convention on Contracts
					for the International Sale of Goods
					(CISG) is expressly excluded. Any
					dispute arising out of or in connection
					with this warranty policy shall be
					submitted to the exclusive jurisdiction
					of the courts of Brisbane, Australia,
					unless otherwise required by mandatory
					law.
				</p>
			</div>
			<Footer />
		</>
	)
}

export default WarrantyScreen
